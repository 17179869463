import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { G } from '@mobily/ts-belt';

import { requestGQL } from '../../../common/graphQlClient';
import { isTestEnv, oneHourInMs } from '../../../config';
import { PremiseOnSinglePremise } from '../../premises/Premise';
import { GET_OUTLETS, GetOutletsResponse, OutletPhotoUpload, UPDATE_OUTLETS_PHOTOS } from '../api';
import { parseGetOutletsResponse } from '../Outlet';

export const useOutlets = (premise: null | PremiseOnSinglePremise) => {
  const queryClient = useQueryClient();

  const propertyId = useMemo(() => {
    return premise?.propertyId ?? null;
  }, [premise]);

  const {
    data,
    isLoading: isLoadingOutlets,
    isError: isErrorOutlets,
    // TODO: figure out how to make it work without this ugly refetching variable
    isRefetching,
  } = useQuery<GetOutletsResponse>(
    ['outletsByPropertyId', propertyId],
    () => requestGQL(GET_OUTLETS, { id: propertyId }),
    {
      enabled: G.isNotNullable(premise),
      refetchOnWindowFocus: false,
      retry: isTestEnv ? 0 : 3,
      staleTime: oneHourInMs,
    },
  );

  const connectionId = useMemo(() => {
    return data?.propertyById.connectionByConnectionId?.id ?? null;
  }, [data]);

  const { mutate } = useMutation<unknown, unknown, Partial<OutletPhotoUpload>>(
    ({ photoUrl, outletId }) => requestGQL(UPDATE_OUTLETS_PHOTOS, { connectionId, photoUrl, outletId }),
    {
      onSettled: () => {
        queryClient.invalidateQueries(['outletsByPropertyId', propertyId]);
      },
    },
  );

  const outlets = useMemo(() => {
    return G.isNullable(data) || G.isNullable(premise) ? [] : parseGetOutletsResponse(premise)(data);
  }, [data, isRefetching]);

  const updateOutletPhoto = (outletId: string, photoUrl: string) => {
    mutate({
      outletId,
      photoUrl,
    });
  };

  return { isLoadingOutlets, isErrorOutlets, outlets, updateOutletPhoto };
};
