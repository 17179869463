import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_TECHNICAL_EQUIPMENT = gql`
  mutation createTechnicalEquipment($input: TechnicalEquipmentInput!) {
    createTechnicalEquipment(input: $input)
  }
`;

export const createTechnicalEquipment = (propertyId: number) => (technicalEquipmentDetails: any) => {
  return requestGQL(CREATE_TECHNICAL_EQUIPMENT, { input: { propertyId, technicalEquipmentDetails } });
};
