import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const DELETE_TECHNICAL_SWITCH = gql`
  mutation deleteTechnicalSwitch($input: DeleteNodeInput!) {
    deleteTechnicalSwitch(input: $input)
  }
`;

export const deleteTechnicalSwitch = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_TECHNICAL_SWITCH, { input: { propertyId, nodeId } });
};
