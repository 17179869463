import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_ODF = gql`
  mutation patchODF($input: ODFInput!) {
    patchODF(input: $input)
  }
`;

export const patchODF = (propertyId: number) => (odfDetails: any) => {
  return requestGQL(PATCH_ODF, { input: { propertyId, odfDetails } });
};
