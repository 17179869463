import { useTranslation } from 'common/hooks/useTranslation';
import { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import { CircularProgress, TextField, Grid, Button, Stack, Typography } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment-timezone';
import Event from '@mui/icons-material/Event';
import { InstallationBookingSchema, installationBookingSchema, PremiseOnSinglePremise } from '../../../../../Premise';
import { usePremise } from '../../../../../hooks/usePremise';

export const InstallationBookings: FC<{ premise: PremiseOnSinglePremise }> = ({ premise }) => {
  const { createPremiseInstallationBooking, isLoadingPremiseInstallationBooking, isErrorPremiseInstallationBooking } =
    usePremise(premise.id);

  const { t } = useTranslation();

  useEffect(() => {
    if (isErrorPremiseInstallationBooking) {
      toast.error(t('error.generalMessage'));
    }
  }, [isErrorPremiseInstallationBooking, t]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm<InstallationBookingSchema>({
    defaultValues: {
      bookingDate: undefined,
      notes: '',
    },
    mode: 'onChange',
    resolver: zodResolver(installationBookingSchema),
  });

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: InstallationBookingSchema) => {
    createPremiseInstallationBooking({ ...values, propertyId: premise.propertyId });
    reset();
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(handleSave)} sx={{ mt: 2, mb: 2, width: '100%' }}>
      <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('premise.installationBooking.header')}</Typography>
      {premise.installationBookings?.length > 0 && (
        <Stack gap={2} sx={{ mb: 3 }}>
          {premise.installationBookings.map((booking) => (
            <Stack gap={0.5} key={booking.id}>
              <TextField fullWidth disabled value={moment(booking.bookingDate).format('YYYY-MM-DD HH:mm')} />
              <TextField multiline maxRows={5} fullWidth disabled value={booking.notes} />
            </Stack>
          ))}
        </Stack>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack gap={2}>
          <Controller
            control={control}
            name="bookingDate"
            render={({ field }) => (
              <DateTimePicker
                label={t('premise.installationBooking.label')}
                ampm={false}
                minutesStep={15}
                minDate={moment(new Date())}
                minTime={moment(new Date(new Date().setHours(6, 0, 0, 0)))}
                maxTime={moment(new Date(new Date().setHours(22, 0, 0, 0)))}
                timezone="UTC"
                skipDisabled
                onChange={(newDate) => field.onChange(newDate?.toISOString())}
                slots={{ openPickerIcon: () => <Event sx={{ zIndex: 1 }} /> }}
              />
            )}
          />
          <TextField
            multiline
            minRows="2"
            fullWidth
            label={t('premise.installationBooking.notes')}
            placeholder={t('premise.installationBooking.notes')}
            {...register('notes')}
            helperText={getErrorMessage('notes')}
          />
        </Stack>
      </LocalizationProvider>

      <Grid container justifyContent="space-between" mt={2}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || isLoadingPremiseInstallationBooking}
        >
          {isLoadingPremiseInstallationBooking ? <CircularProgress size={16} /> : t('general.save')}
        </Button>
      </Grid>
    </Stack>
  );
};
