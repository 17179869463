import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { FC } from 'react';
import { Fiber } from '../../../Outlet';
import * as styles from './styles';

export const FiberDetails: FC<{
  fiber: Fiber;
}> = ({ fiber }) => {
  const { t } = useTranslation();

  // const canEditFiber = fiber.personId === userId || roles.includes('cf_admin') || roles.includes('cf_contractor_pm');

  return (
    <Grid container sx={styles.fiberDetailsContainer}>
      <TextField fullWidth value={fiber.odf || ''} label={t('fiber.odf')} disabled />
      <TextField fullWidth value={fiber.fiber || ''} label={t('fiber.fiber')} disabled />
      <TextField fullWidth value={fiber.duct || ''} label={t('fiber.duct')} disabled />
      <TextField fullWidth value={fiber.length || '0'} label={t('fiber.length')} disabled />
      <TextField fullWidth value={fiber.db1310 || '0'} label={t('fiber.db1310')} disabled />
      <TextField fullWidth value={fiber.db1550 || '0'} label={t('fiber.db1550')} disabled />
    </Grid>
  );
};
