import { LanguagePicker } from 'app/internationalization/components/LanguagePicker';
import { useTranslation } from 'common/hooks/useTranslation';
import { publicUrl } from 'config';
import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { G } from '@mobily/ts-belt';
import { Box, Grid, Typography } from '@mui/material';

import { LoginPageContextProvider, useLoginPageContext } from '../contexts/LoginPageContext';
import { LoginByBankID } from './LoginByBankID';
import { LoginByEmail } from './LoginByEmail';
import { styles } from './LoginPage.styles';

const LoginPageComponent: FC = () => {
  const { t } = useTranslation();
  const { typeOfLogin } = useLoginPageContext();
  const [searchParams] = useSearchParams();

  const isInitialLoad = useMemo(() => {
    return G.isNotNullable(searchParams.get('invite'));
  }, [searchParams.get('invite')]);

  return (
    <>
      <Box sx={styles.pageBackground} />
      <Grid container>
        <Grid container sx={styles.languagePickerContainer}>
          <LanguagePicker />
        </Grid>
        <Grid container sx={styles.logoContainer}>
          <img
            data-testid="corporate-fiber-logo"
            width={53}
            height={57}
            src={`${publicUrl}/logo512.png`}
            alt="Allicon logo"
          />
        </Grid>
        <Grid container sx={styles.welcomeTextContainer}>
          <Typography data-testid="welcome-text" sx={styles.welcomeText}>
            {isInitialLoad ? t('login.initialWelcomeText') : t('login.welcomeText')}
          </Typography>
        </Grid>
        <Grid sx={styles.loginMethodContainer}>{typeOfLogin === 'bankid' ? <LoginByBankID /> : <LoginByEmail />}</Grid>
      </Grid>
    </>
  );
};

export const LoginPage: FC = () => (
  <LoginPageContextProvider>
    <LoginPageComponent />
  </LoginPageContextProvider>
);
