import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_TECHNICAL_EQUIPMENT = gql`
  mutation patchTechnicalEquipment($input: TechnicalEquipmentInput!) {
    patchTechnicalEquipment(input: $input)
  }
`;

export const patchTechnicalEquipment = (propertyId: number) => (technicalEquipmentDetails: any) => {
  return requestGQL(PATCH_TECHNICAL_EQUIPMENT, { input: { propertyId, technicalEquipmentDetails } });
};
