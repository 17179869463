import { DetailsInformation } from 'common/components/DetailsInformation/DetailsInformation';
import {
  getFileNameFromUrl,
  optionParseInt,
  redirectToEmail,
  redirectToMapsLocation,
  redirectToPhone,
} from 'common/helpers';
import { useTranslation } from 'common/hooks/useTranslation';
import { publicUrl } from 'config';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { G, pipe } from '@mobily/ts-belt';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';

import { RoutesParamsMap } from '../../routes/Route';
import { PropertyFiberChecklist, createSignedLinkToDownloadPropertyFile } from '../api';
import { useProperty } from '../hooks/useProperty';
import { getFullAddress } from '../Property';
import * as styles from './PropertyInformations.styles';
import { useFiberChecklist } from '../hooks/useFiberChecklist';

export const PropertyInformations: FC = () => {
  const params = useParams<RoutesParamsMap['property/:id']>();
  const parsedId = useMemo(() => optionParseInt(params.id), [params]);

  const { t } = useTranslation();

  const { data, isLoading, isError } = useProperty(parsedId);

  const {
    data: fiberChecklistByPropertyIdResponse,
    isLoading: isLoadingChecklist,
    isError: isErrorChecklist,
    updatePropertyFiberChecklist,
    isLoadingUpdatePropertyFiberChecklist,
    isSuccessCreatePropertyFiberChecklist,
    isErrorUpdatePropertyFiberChecklist,
    createPropertyFiberChecklist,
    isLoadingCreatePropertyFiberChecklist,
    isSuccessUpdatePropertyFiberChecklist,
    isErrorCreatePropertyFiberChecklist,
  } = useFiberChecklist(parsedId);

  const [showDialog, setShowDialog] = useState(false);
  const [checkboxes, setCheckboxes] = useState<PropertyFiberChecklist>({
    wire: false,
    fire: false,
    duct: false,
    clean: false,
    node: false,
  });

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    if (fiberChecklistByPropertyIdResponse && fiberChecklistByPropertyIdResponse.fiberChecklistByPropertyId) {
      setCheckboxes(fiberChecklistByPropertyIdResponse.fiberChecklistByPropertyId.checklist);
    }
  }, [fiberChecklistByPropertyIdResponse]);

  useEffect(() => {
    if (isSuccessCreatePropertyFiberChecklist || isSuccessUpdatePropertyFiberChecklist) {
      setShowDialog(false);
    }
  }, [isSuccessCreatePropertyFiberChecklist, isSuccessUpdatePropertyFiberChecklist]);

  const handleDialogConfirm = () => {
    if (data?.fiberChecklistByPropertyId) {
      updatePropertyFiberChecklist(checkboxes);
    } else {
      createPropertyFiberChecklist(checkboxes);
    }
  };

  const handleCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({ ...checkboxes, [name]: event.target.checked });
  };

  const textNA = useMemo(() => t('notAvailable.general'), [t]);

  if (isLoading) return <CircularProgress sx={styles.loader} />;

  if (
    isError ||
    isErrorChecklist ||
    isErrorUpdatePropertyFiberChecklist ||
    isErrorCreatePropertyFiberChecklist ||
    G.isNullable(data)
  )
    return (
      <Grid container sx={styles.warningContainer}>
        <Box component="img" src={`${publicUrl}/img/icons/warning.svg`} alt="warning" />
        <Typography sx={styles.warningInfo}>{t('error.generalMessage')}</Typography>
      </Grid>
    );

  return (
    <>
      <Dialog open={showDialog} onClose={handleDialogClose} PaperProps={{ sx: { margin: 0.5, p: 2 } }}>
        <DialogTitle>{t('checklist.title')}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checkboxes.wire} onChange={handleCheckboxChange('wire')} />}
                label={t('checklist.property.wire')}
              />
              <FormControlLabel
                control={<Checkbox checked={checkboxes.fire} onChange={handleCheckboxChange('fire')} />}
                label={t('checklist.property.fire')}
              />
              <FormControlLabel
                control={<Checkbox checked={checkboxes.duct} onChange={handleCheckboxChange('duct')} />}
                label={t('checklist.property.duct')}
              />
              <FormControlLabel
                control={<Checkbox checked={checkboxes.clean} onChange={handleCheckboxChange('clean')} />}
                label={t('checklist.property.clean')}
              />
              <FormControlLabel
                control={<Checkbox checked={checkboxes.node} onChange={handleCheckboxChange('node')} />}
                label={t('checklist.property.node')}
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t('general.cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDialogConfirm}
            disabled={isLoadingCreatePropertyFiberChecklist || isLoadingUpdatePropertyFiberChecklist}
          >
            {isLoadingCreatePropertyFiberChecklist || isLoadingUpdatePropertyFiberChecklist ? (
              <CircularProgress size={16} />
            ) : (
              t('general.save')
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container sx={styles.container}>
        <Grid container sx={styles.infoContainer}>
          {data.contacts.property
            .filter((person) => person.hideInInstaller !== true)
            .map((person) => (
              <Grid
                key={`${person.name}-${person.email}`}
                data-cy="property-person-contact"
                container
                sx={styles.infoSection}
              >
                <DetailsInformation label={t('property.contactPersonProperty')} value={person.name ?? textNA} />
                <DetailsInformation
                  label={t('property.propertyEmail')}
                  value={person.email ?? textNA}
                  url={person.email ? redirectToEmail(person.email) : undefined}
                />
                <DetailsInformation
                  label={t('property.propertyMobile')}
                  value={person.phone ?? textNA}
                  url={person.phone ? redirectToPhone(person.phone) : undefined}
                />
                <DetailsInformation label={t('property.propertyRole')} value={person.role ?? textNA} />
              </Grid>
            ))}
          <Grid data-cy="property-CF-contact" container sx={styles.infoSection}>
            <DetailsInformation label={t('property.contactPersonCF')} value={data.contacts.CF.name ?? textNA} />
            <DetailsInformation
              label={t('property.CFEmail')}
              value={data.contacts.CF.email ?? textNA}
              url={data.contacts.CF.email ? redirectToEmail(data.contacts.CF.email) : undefined}
            />
            <DetailsInformation
              label={t('property.CFMobile')}
              value={data.contacts.CF.phone ?? textNA}
              url={data.contacts.CF.phone ? redirectToPhone(data.contacts.CF.phone) : undefined}
            />
            <DetailsInformation label={t('property.CFRole')} value={data.contacts.CF.role ?? textNA} />
          </Grid>
          <Grid data-cy="property-address" container sx={styles.infoSection}>
            <Typography sx={styles.infoLabel}>{t('properties.address.label')}</Typography>
            <Button
              sx={styles.infoLinkValue}
              variant="text"
              onClick={() => pipe(data, getFullAddress, redirectToMapsLocation)}
            >
              {data.address}
              <Box component="img" src={`${publicUrl}/img/icons/location.svg`} alt="locationIcon" />
            </Button>
          </Grid>
          {data.attachments.length > 0 && (
            <Grid container sx={styles.infoSection}>
              <Typography sx={styles.infoLabel}>{t('property.documents')}</Typography>
              {data.attachments.map((attachment) => (
                <Button
                  key={attachment}
                  sx={styles.infoLinkValue}
                  variant="text"
                  href={attachment}
                  onClick={async (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const { query } = await createSignedLinkToDownloadPropertyFile(
                      pipe(attachment, getFileNameFromUrl, encodeURIComponent),
                    );

                    window.location.href = `${attachment}?${query}`;
                  }}
                  download={getFileNameFromUrl(attachment)}
                >
                  {getFileNameFromUrl(attachment)}
                </Button>
              ))}
            </Grid>
          )}
          <Grid container sx={styles.infoSection}>
            <Button
              variant="contained"
              disabled={isLoadingChecklist}
              color="primary"
              onClick={() => setShowDialog(true)}
            >
              {t('checklist.title')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
