import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { ConfirmationPrompt } from 'common/components/ConfirmationPrompt/ConfirmationPrompt';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CircularProgress, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';

import { O } from '@mobily/ts-belt';
import { AvailableOutletTypes } from 'app/outlets/AvailiableOutletTypes';
import { DropAntennaWithFibers } from 'app/outlets/DropAntenna';
import { useDeleteOutlet } from '../../hooks/useDeleteOutlet';
import { Outlet } from '../../Outlet';
import { styles } from './OutletMenu.styles';
import { useDeleteTPOutlet } from '../../hooks/useDeleteTPOutlet';
import { TPOutlet } from '../../TPOutlet';

export type OutletTypes = O.Option<AvailableOutletTypes>;

export const OutletMenu: FC<{
  premise: PremiseOnSinglePremise;
  outlet: Outlet | TPOutlet | DropAntennaWithFibers;
  onDelete?: Lambda<void, void>;
  outletType: OutletTypes;
}> = ({ premise, outlet, onDelete, outletType }) => {
  const { t } = useTranslation();
  const outletDetailsMenuAnchor = useRef<HTMLButtonElement>(null);
  const [isOutletMenuOpen, setIsOutletMenuOpen] = useState(false);
  const {
    mutate: deleteOutlet,
    isSuccess: deleteOutletSuccess,
    isError: deleteOutletError,
    isLoading: deleteOutletLoading,
  } = useDeleteOutlet(premise.propertyId, outlet.id);

  const {
    mutate: deleteTPOutlet,
    isSuccess: deleteTPOutletSuccess,
    isError: deleteTPOutletError,
    isLoading: deleteTPOutletLoading,
  } = useDeleteTPOutlet(premise.propertyId, outlet.id);

  useEffect(() => {
    if (deleteOutletSuccess || deleteTPOutletSuccess) {
      setIsOutletMenuOpen(false);
      toast.success(t('general.deletedSuccessfully'));
      if (onDelete) onDelete();
    }
  }, [deleteOutletSuccess, deleteTPOutletSuccess]);

  useEffect(() => {
    if (deleteOutletError || deleteTPOutletError) {
      setIsOutletMenuOpen(false);
      toast.error(t('error.generalMessage'));
    }
  }, [deleteOutletError, deleteTPOutletError]);

  const noContractsAssigned = useMemo(() => {
    // @ts-ignore
    return outlet.fibers.reduce((acc, nextFiber) => {
      if (acc === false) return acc;
      if ('contract' in nextFiber && nextFiber.contract) return false;
      return acc;
    }, true);
  }, [outlet.fibers]);

  return (
    <>
      <IconButton ref={outletDetailsMenuAnchor} onClick={() => setIsOutletMenuOpen(true)}>
        <MoreVertIcon titleAccess="detail-menu" />
      </IconButton>
      <Menu
        anchorEl={outletDetailsMenuAnchor.current}
        open={isOutletMenuOpen}
        onClose={() => setIsOutletMenuOpen(false)}
      >
        <ConfirmationPrompt
          actionEl={({ onClick }) => (
            <MenuItem onClick={onClick} disabled={deleteOutletLoading || deleteTPOutletLoading}>
              <ListItemIcon>
                {deleteOutletLoading || deleteTPOutletLoading ? (
                  <CircularProgress size={24} color="error" />
                ) : (
                  <DeleteIcon color="error" />
                )}
              </ListItemIcon>
              <ListItemText>
                <Typography color="error">{t('premise.outlet.delete')}</Typography>
              </ListItemText>
            </MenuItem>
          )}
          additionalEl={
            <>
              {noContractsAssigned ? (
                <Typography sx={styles.centeredText}>{t('premise.outlet.delete.confirmation')}</Typography>
              ) : null}
              {!noContractsAssigned ? (
                <Typography color="error" sx={styles.centeredText}>
                  {t('premise.outlet.delete.disabled.contract')}
                </Typography>
              ) : null}
            </>
          }
          disabled={!noContractsAssigned}
          onConfirm={() => {
            if (outletType === AvailableOutletTypes.OUTLET) {
              deleteOutlet();
            } else if (outletType === AvailableOutletTypes.TPOUTLET) {
              deleteTPOutlet();
            }
          }}
        />
      </Menu>
    </>
  );
};
