import { createStylesMap } from 'app/theme/materialTheme';

export const styles = createStylesMap({
  locationContainer: {
    mt: 2,
  },
  loadingAdornment: {
    zIndex: 2,
    mr: 1,
  },
});
