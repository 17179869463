import {
  createTwistedPair,
  getDataText,
  getNodeId,
  getTPOutletById,
  isCrossConnectionPanelPortAvailable,
  isTPOutletPortAvailable,
} from 'app/connection/Connection';
import { useConnection } from 'app/connection/hooks/useConnection';
import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import * as R from 'rambda';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { toast } from 'react-toastify';
import { A, D, G, O, pipe } from '@mobily/ts-belt';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { TPCategory } from 'app/fibers/TPCategory';
import { TPShielding } from 'app/fibers/TPShielding';
import { AddFiberTPOutletSchema } from '../../addFiberTPOutletSchema';
import { useCreateTwistedPair } from '../../useCreateTwistedPair';
import { styles } from '../AddFiber.styles';
import { TpOutletDetails } from '../../../areas/types/ObjectDetails';

export const AddFiberTPOutletForm: FC<{
  premise: PremiseOnSinglePremise;
  outlet: TpOutletDetails;
  onSuccess?: Lambda<void, void>;
}> = ({ premise, outlet, onSuccess }) => {
  const { crossConnectionPanelNodes, serialized2, isLoading, isError } = useConnection(premise.propertyId);

  const {
    mutate,
    isLoading: isLoadingUpdateConnection,
    isError: isErrorUpdateConnection,
    isSuccess: isSuccessUpdateConnection,
  } = useCreateTwistedPair(premise.propertyId);

  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  const [formValues, setFormValues] = useState({} as O.Option<AddFiberTPOutletSchema>);
  const [allChecked, setAllChecked] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  useEffect(() => {
    if (isError || isErrorUpdateConnection) toast.error(t('error.generalMessage'));
  }, [isError, isErrorUpdateConnection]);

  useEffect(() => {
    setAllChecked(checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3);
  }, [checkboxes]);

  useEffect(() => {
    if (isSuccessUpdateConnection) toast.success(t('general.updatedSuccessfully'));
    if (isSuccessUpdateConnection && G.isNotNullable(onSuccess)) onSuccess();
  }, [isSuccessUpdateConnection]);

  const crossConnectionPanelSelectOptions = useMemo(
    () =>
      pipe(
        O.fromNullable(crossConnectionPanelNodes),
        O.map(
          R.pipe(
            A.map((node) => ({
              name: getDataText(node),
              nodeId: node.id,
            })),
            A.filter((obj) => G.isNotNullable(obj.name) && G.isNotNullable(obj.nodeId)),
          ),
        ),
        O.toNullable,
      ),
    [crossConnectionPanelNodes],
  );

  const { control, register, handleSubmit, watch, setValue, trigger } = useForm<AddFiberTPOutletSchema>();

  const { errors } = useFormState({ control });

  const getErrorMessage = useErrorMessage(errors);

  const formCrossConnectionPanelValue = watch('crossPanel');

  const pickedCrossConnectionPanelNode = useMemo(
    () =>
      pipe(
        O.fromNullable(crossConnectionPanelNodes),
        O.flatMap(A.find((crossConnectionPanel) => getNodeId(crossConnectionPanel) === formCrossConnectionPanelValue)),
        O.toNullable,
      ),
    [crossConnectionPanelNodes, formCrossConnectionPanelValue],
  );

  useEffect(() => {
    pipe(
      O.fromNullable(crossConnectionPanelNodes),
      O.flatMap(A.head),
      O.flatMap((node) => O.fromNullable(getNodeId(node))),
      O.tap((defaultCrossConnectionPanelValue) => setValue('crossPanel', defaultCrossConnectionPanelValue)),
    );
  }, [crossConnectionPanelNodes]);

  useEffect(() => {
    trigger();
  }, [formCrossConnectionPanelValue]);

  const submitFiber = useCallback(
    (formData: AddFiberTPOutletSchema) => {
      if (G.isNotNullable(serialized2) && G.isNotNullable(crossConnectionPanelNodes) && D.isEmpty(errors)) {
        const pickedOutlet = getTPOutletById(serialized2, outlet.id);
        if (G.isNotNullable(pickedCrossConnectionPanelNode) && G.isNotNullable(pickedOutlet)) {
          const parsedCrossPanelPort = `${formData.crossPanelPort}`.padStart(2, '0');
          const parsedTPOutletPort = `${formData.tpOutletPort}`.padStart(2, '0');
          mutate(
            createTwistedPair(
              pickedOutlet,
              pickedCrossConnectionPanelNode,
              parsedCrossPanelPort,
              parsedTPOutletPort,
              formData.length,
              formData.category,
              formData.shielding,
            ),
          );
        }
      }
    },
    [serialized2, pickedCrossConnectionPanelNode],
  );

  const tpOutlet = getTPOutletById(serialized2!, outlet.id);

  const handleSave = (values: AddFiberTPOutletSchema) => {
    setFormValues(values);
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleDialogConfirm = () => {
    submitFiber(formValues as AddFiberTPOutletSchema);
    setShowDialog(false);
  };

  const handleCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({ ...checkboxes, [name]: event.target.checked });
  };

  return (
    <>
      <Dialog open={showDialog} onClose={handleDialogClose} PaperProps={{ sx: { margin: 0.5, p: 2 } }}>
        <DialogTitle>{t('checklist.title')}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checkboxes.checkbox1} onChange={handleCheckboxChange('checkbox1')} />}
                label={t('checklist.tpOutlett.1')}
              />
              <FormControlLabel
                control={<Checkbox checked={checkboxes.checkbox2} onChange={handleCheckboxChange('checkbox2')} />}
                label={t('checklist.tpOutlett.2')}
              />
              <FormControlLabel
                control={<Checkbox checked={checkboxes.checkbox3} onChange={handleCheckboxChange('checkbox3')} />}
                label={t('checklist.cleaned')}
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t('general.cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleDialogConfirm} disabled={!allChecked}>
            {t('general.create')}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container sx={styles.centerContainer}>
        {isLoading && <CircularProgress />}
        {!isLoading && !isError && serialized2 && (
          <Grid container component="form" onSubmit={handleSubmit(handleSave)}>
            <Grid container columnSpacing={1.5} rowSpacing={4}>
              <Grid item xs={12}>
                {crossConnectionPanelSelectOptions && (
                  <FormControl fullWidth>
                    <InputLabel htmlFor="crossConnectionPanel">{t('fiber.crossConnectionPanel')}</InputLabel>
                    <NativeSelect
                      {...register('crossPanel', { required: true })}
                      input={
                        <OutlinedInput fullWidth inputProps={{ 'data-testid': 'add-fiber-crossconnectionpanel' }} />
                      }
                      id="crossConnectionPanel"
                    >
                      {crossConnectionPanelSelectOptions?.map((crossPanelToPick) => (
                        <option key={crossPanelToPick.nodeId} value={crossPanelToPick.nodeId}>
                          {crossPanelToPick.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('crossPanelPort', {
                    valueAsNumber: true,
                    required: true,
                    validate: (crossPanelPort) => {
                      if (
                        G.isNotNullable(pickedCrossConnectionPanelNode) &&
                        !isCrossConnectionPanelPortAvailable(
                          serialized2,
                          pickedCrossConnectionPanelNode,
                          crossPanelPort,
                        )
                      ) {
                        return t('fiber.add.error.invalidCrossConnectionPanelPort');
                      }
                      return true;
                    },
                  })}
                  inputProps={{
                    'data-testid': 'add-fiber-fiber',
                  }}
                  type="number"
                  fullWidth
                  required
                  label={t('fiber.crossPanelPort')}
                  variant="outlined"
                  helperText={getErrorMessage('crossPanelPort')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('tpOutletPort', {
                    valueAsNumber: true,
                    required: true,
                    validate: (tpOutletPort) => {
                      if (G.isNotNullable(tpOutlet) && !isTPOutletPortAvailable(serialized2, tpOutlet, tpOutletPort)) {
                        return t('fiber.add.error.tpOutletPort');
                      }
                      return true;
                    },
                  })}
                  inputProps={{
                    'data-testid': 'add-fiber-fiber',
                  }}
                  type="number"
                  fullWidth
                  required
                  label={t('fiber.tpOutletPort')}
                  variant="outlined"
                  helperText={getErrorMessage('tpOutletPort')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('category', { required: true })}
                  select
                  fullWidth
                  required
                  label={t('fiber.category')}
                  variant="outlined"
                  helperText={getErrorMessage('category')}
                  value={watch('category')}
                >
                  {Object.values(TPCategory).map((category) => (
                    <MenuItem key={category} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('shielding', { required: true })}
                  select
                  fullWidth
                  required
                  label={t('fiber.shielding')}
                  variant="outlined"
                  helperText={getErrorMessage('shielding')}
                  value={watch('shielding')}
                >
                  {Object.values(TPShielding).map((shielding) => (
                    <MenuItem key={shielding} value={shielding}>
                      {shielding}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register('length', { valueAsNumber: true })}
                  inputProps={{ type: 'number', step: '0.01', 'data-testid': 'add-fiber-length' }}
                  fullWidth
                  required
                  label={t('tp.length')}
                  helperText={getErrorMessage('length')}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container sx={styles.addFiberSubmitContainer}>
              {isLoadingUpdateConnection && <CircularProgress />}
              {!isLoadingUpdateConnection && !isSuccessUpdateConnection && (
                <Button type="submit" variant="contained" data-testid="add-fiber-form-save">
                  {t('general.save')}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
