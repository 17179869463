import { G } from '@mobily/ts-belt';

import { SerializedData } from './api';

export const getPremisesFromSerializedData = (serializedData: SerializedData) => {
  return serializedData.nodeDataArray.filter((node) => node.model === 'premises');
};

export const getOutletsFromSerializedData = (serializedData: SerializedData) => {
  return serializedData.nodeDataArray.filter((node) => node.model === 'outlet');
};

export const getRadioNodesFromSerializedData = (serializedData: SerializedData) => {
  return serializedData.nodeDataArray.filter(
    (node) => node.model === 'coupler' || node.model === 'dropAntenna' || node.model === 'splitter',
  );
};

export const getConnectionsFromSerializedData = (serializedData: SerializedData) => {
  return getPremisesFromSerializedData(serializedData).map((premiseNode) => {
    const outletNodeIds = getOutletsFromSerializedData(serializedData).reduce((acc, outlet) => {
      if (G.isNotNullable(outlet.id) && outlet.group === premiseNode.id) {
        return [...acc, outlet.id];
      }

      return acc;
    }, [] as ReadonlyArray<string>);

    const connections = serializedData.linkDataArray.reduce((acc, link) => {
      const { to, toPort } = link;

      if (G.isNotNullable(to) && G.isNotNullable(toPort) && outletNodeIds.includes(to)) {
        return [...acc, toPort];
      }

      return acc;
    }, [] as ReadonlyArray<string>);

    return {
      premisesId: premiseNode?.cfObject?.premisesId,
      inputs: connections,
    };
  });
};

export const getRadioConnectionsFromSerializedData = (serializedData: SerializedData) => {
  return getPremisesFromSerializedData(serializedData).map((premiseNode) => {
    const radioNodesIds = getRadioNodesFromSerializedData(serializedData).reduce((acc, radioNode) => {
      if (G.isNotNullable(radioNode.id) && radioNode.group === premiseNode.id) {
        return [...acc, radioNode.id];
      }

      return acc;
    }, [] as ReadonlyArray<string>);

    const connections = serializedData.linkDataArray.reduce((acc, link) => {
      const { to, toPort } = link;

      if (G.isNotNullable(to) && G.isNotNullable(toPort) && radioNodesIds.includes(to)) {
        return [...acc, toPort];
      }

      return acc;
    }, [] as ReadonlyArray<string>);

    return {
      premisesId: premiseNode?.cfObject?.premisesId,
      inputs: connections,
    };
  });
};
