import { Styles } from '../app/theme/materialTheme';

export const card: Styles = (theme) => ({
  width: '100%',
  px: 2,
  pt: 2,
  pb: 1,
  borderRadius: 0.5,
  border: '1px solid',
  borderColor: theme.palette.line.main,
  boxShadow: '0px 2px 2px rgba(36, 38, 38, 0.2)',
  overflow: 'hidden',
});
