import { Styles } from '../../theme/materialTheme';

export const icon: Styles = {
  zIndex: 1,
  position: 'absolute',
  right: 0,
};

export const select: Styles = {
  display: 'flex',
  alignItems: 'center',
  width: 60,
  '.MuiSelect-select': {
    zIndex: 2,
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    '&.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input': {
      p: 0,
      pr: 4,
    },
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const menu: Styles = (theme) => ({
  '.MuiList-root': {
    padding: 0,
    borderRadius: 0.5,
    border: '1px solid',
    borderColor: theme.palette.line.main,
  },
  '.MuiMenuItem-root': {
    fontSize: 15,
  },
});

export const flag: Styles = { mr: 0.5 };
