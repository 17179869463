import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_RADIO_UNIT = gql`
  mutation patchRadioUnit($input: RadioUnitInput!) {
    patchRadioUnit(input: $input)
  }
`;

export const patchRadioUnit = (propertyId: number) => (radioUnitDetails: any) => {
  return requestGQL(PATCH_RADIO_UNIT, { input: { propertyId, radioUnitDetails } });
};
