import create from 'zustand';

import { getUserId, getUserRoles, getExpirationTimeInSeconds, Token } from './Token';

type AuthenticationState = {
  userId: null | string;
  token: Token;
  roles: ReadonlyArray<string>;
  expirationInSeconds: number | null;
  changeToken: (token: Token) => void;
};

export const useAuthenticationState = create<AuthenticationState>()((set) => ({
  userId: null,
  token: null,
  roles: [],
  expirationInSeconds: null,
  changeToken: (token) =>
    set({
      token,
      userId: getUserId(token),
      roles: getUserRoles(token) || [],
      expirationInSeconds: getExpirationTimeInSeconds(token),
    }),
}));

export const authenticationState = () => useAuthenticationState.getState();
