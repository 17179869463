import { GraphQLClient, RequestDocument, Variables } from 'graphql-request';

import { G } from '@mobily/ts-belt';

import { authenticationState } from '../app/authentication/state';
import { graphQLUri } from '../config';

export const graphQLClient = new GraphQLClient(graphQLUri, {
  requestMiddleware: (request) => {
    const { token } = authenticationState();

    if (G.isNull(token)) {
      return request;
    }

    return {
      ...request,
      headers: { ...request.headers, authorization: `Bearer ${token}` },
    };
  },
});

export const requestGQL = <A>(doc: RequestDocument, variables?: Variables) => graphQLClient.request<A>(doc, variables);
