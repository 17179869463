import { A, D, O, pipe } from '@mobily/ts-belt';

import { Premise } from '../premises/Premise';
import { GetOutletsResponse } from './api';
import {
  getDataCFObject,
  getFibersNodesByOutlet,
  getNodeContectType,
  getNodeLocation,
  getNodePhotos,
  getNodesData,
  getODFNodeByFiber,
  getOutletsNodesByPremiseId,
} from './connectionSerializedData';

export type Fiber = {
  id: string;
  toPort: null | string;
  odf: null | string;
  odfId: null | string;
  fiber: null | string;
  duct: null | string;
  length: null | number;
  db1310: null | number;
  db1550: null | number;
  contract: null | string;
  personId: null | string;
};

export type Outlet = {
  id: string;
  imagesUrls: ReadonlyArray<string>;
  location: null | string;
  contactType?: string;
  fibers: ReadonlyArray<Fiber>;
};

export type Outlets = ReadonlyArray<Outlet>;

export const parseGetOutletsResponse =
  (premise: Premise) =>
  (response: GetOutletsResponse): Outlets => {
    // TODO: extract to some 'connection' hook with updating the connections also
    return pipe(
      response,
      O.flatMap(D.get('propertyById')),
      O.flatMap(D.get('connectionByConnectionId')),
      O.flatMap(O.fromNullable),
      O.flatMap(D.get('serialized2')),
      O.mapWithDefault([], (serializedData) => {
        return pipe(
          serializedData,
          getNodesData,
          getOutletsNodesByPremiseId(premise.id),
          A.map((outletNode) => ({
            id: outletNode.id,
            imagesUrls: getNodePhotos(outletNode),
            location: getNodeLocation(outletNode),
            contactType: getNodeContectType(outletNode),
            fibers: getFibersNodesByOutlet(serializedData, outletNode).map((fiberNode) => {
              return {
                id: fiberNode.id,
                toPort: fiberNode.toPort ?? null,
                odf: getODFNodeByFiber(serializedData, fiberNode)?.text ?? null,
                odfId: getODFNodeByFiber(serializedData, fiberNode)?.id ?? null,
                fiber: getDataCFObject(fiberNode)?.fiber ?? fiberNode.fromPort ?? null,
                duct: getDataCFObject(fiberNode)?.duct ?? null,
                length: getDataCFObject(fiberNode)?.length ?? null,
                db1310: getDataCFObject(fiberNode)?.db1310 ?? null,
                db1550: getDataCFObject(fiberNode)?.db1550 ?? null,
                contract: getDataCFObject(fiberNode)?.designator ?? null,
                personId: getDataCFObject(fiberNode)?.personId ?? null,
              };
            }) as Fiber[],
          })),
        );
      }),
    );
  };
