import { zodResolver } from '@hookform/resolvers/zod';
import { G, O } from '@mobily/ts-belt';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useCallback, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { toast } from 'react-toastify';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { useBoolean } from 'usehooks-ts';
import { useDeleteTpOutlet } from '../../hooks/useDelete';
import { usePatchTpOutlet } from '../../hooks/usePatch';
import { TpOutletDetails } from '../../types/ObjectDetails';
import { tpOutletSchema, TpOutletSchema } from '../../types/TpOutletSchema';
import { PhotosField } from '../../ui/PhotosField';
import { EnlargedPhotoDialog } from '../EnlargedPhotoDialog';
import * as styles from '../../../outlets/components/PremiseTPOutlets/components/styles';
import { AddTPOutletTwistedPair } from '../../../fibers/components/AddTPOutletTwistedPair';
import { AddFiberTPOutletSchema } from '../../../fibers/addFiberTPOutletSchema';
import { getTPOutletById, getTPOutletFibers } from '../../../connection/Connection';
import { usePropertyContext } from '../../../properties/components/SingleProperty';
import { useConnection } from '../../../connection/hooks/useConnection';
import { FiberTPOutletDetails } from '../../../outlets/components/PremiseTPOutlets/components/FiberTPOutletDetails';
import { mapLinkDataToTwistedPair, TwistedPair } from '../../../outlets/TPOutlet';
import { useAuthenticationState } from '../../../authentication/state';
import { DeleteFiberDialog } from '../../../outlets/components/PremiseTPOutlets/components/DeleteFiberDialog';
import { DeleteTPOutletFiber } from '../../../outlets/components/PremiseTPOutlets/components/DeleteTpOutletFiber';

type Props = {
  details: TpOutletDetails;
  onSave: Lambda<void, void>;
  onDelete?: Lambda<void, void>;
};

export const TpOutlet: FC<Props> = ({ details, onSave, onDelete }) => {
  const { t } = useTranslation();
  const { id: propertyId } = usePropertyContext();
  const { serialized2 } = useConnection(propertyId);
  const { portCount, text, location, id } = details;
  const [pickedImage, setPickedImage] = useState<O.Option<string>>(O.None);
  const { mutate: patchTPOutlet, isLoading: isLoadingPatchTPOutlet } = usePatchTpOutlet({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });
  const { mutate: deleteWifiDot, isLoading: isLoadingDeleteWifiDot } = useDeleteTpOutlet({
    onSuccess: () => {
      if (onDelete) onDelete();
      toast.success(t('general.deletedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<TpOutletSchema>({
    defaultValues: {
      text: O.getWithDefault(text, ''),
      portCount: O.getWithDefault(portCount, 0),
      location: O.getWithDefault(location, ''),
    },
    mode: 'onChange',
    resolver: zodResolver(tpOutletSchema),
  });
  const { control } = useForm<AddFiberTPOutletSchema>();

  const { isValid: isTwisterPairFormValid } = useFormState({ control });

  const [photosURLs, setPhotosURLs] = useState(details.photosURLs ?? []);

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: TpOutletSchema) => {
    patchTPOutlet({ ...values, id, photosURLs });
  };

  const handleDelete = () => {
    deleteWifiDot(details.id);
  };
  const { value: isDeleteFiberOpen, setValue: setIsDeleteFiberOpen } = useBoolean(false);
  const [fiberToDelete, setFiberToDelete] = useState<null | TwistedPair>(null);
  const { userId, roles } = useAuthenticationState();
  const canUserDeleteFiber = useCallback(
    (fiber: TwistedPair) => {
      if (roles.includes('cf_admin') || roles.includes('cf_contractor_pm')) return true;

      const fiberCreatorId = fiber.personId;

      if (fiberCreatorId !== null && userId === fiberCreatorId) return true;

      return false;
    },
    [userId, roles],
  );

  const outlet = getTPOutletById(serialized2!, details.id);
  const fibers = getTPOutletFibers(serialized2, details.id);
  return (
    <>
      <DeleteFiberDialog
        isOpen={G.isNotNullable(fiberToDelete) && isDeleteFiberOpen}
        onClose={() => {
          setFiberToDelete(null);
          setIsDeleteFiberOpen(false);
        }}
      >
        {G.isNotNullable(fiberToDelete) && G.isNotNullable(outlet) && (
          <DeleteTPOutletFiber
            outletId={details.id}
            fiber={fiberToDelete}
            propertyId={propertyId!}
            onCancel={() => {
              setFiberToDelete(null);
              setIsDeleteFiberOpen(false);
            }}
            onSuccess={() => {
              setFiberToDelete(null);
              setIsDeleteFiberOpen(false);
            }}
          />
        )}
      </DeleteFiberDialog>
      <EnlargedPhotoDialog
        photoUrl={pickedImage}
        onClose={() => {
          setPickedImage(O.None);
        }}
      />
      <Stack component="form" onSubmit={handleSubmit(handleSave)}>
        <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.tpOutlet.label')}</Typography>
        <Stack gap={2} width="100%">
          <PhotosField
            value={photosURLs}
            onChange={setPhotosURLs}
            onItemClick={(photoUrl) => {
              setPickedImage(O.Some(photoUrl));
            }}
          />
          <TextField label={t('areas.fields.name')} {...register('text')} />
          <TextField label={t('areas.fields.location')} {...register('location')} />
          <TextField
            label={t('areas.fields.connectionCount')}
            type="number"
            {...register('portCount', {
              valueAsNumber: true,
            })}
            helperText={getErrorMessage('portCount')}
          />
        </Stack>
        <Grid container justifyContent="space-between" mt={2}>
          <Grid container sx={styles.centerContainer}>
            <AddTPOutletTwistedPair outletDetails={details} />
            <Grid container sx={styles.fibersContainer}>
              {fibers.map((fiberLink) => {
                const twistedPair = mapLinkDataToTwistedPair(fiberLink, serialized2!);
                return (
                  <Accordion
                    disableGutters
                    square
                    key={twistedPair.id}
                    data-cy={`fiber-accordion-${twistedPair.to.portId}`}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon titleAccess="accordion-expand" />}
                      data-cy="fiber-accordion-header"
                    >
                      {twistedPair.to.portId}
                    </AccordionSummary>
                    <AccordionDetails data-cy="fiber-accordion-details">
                      {serialized2 && propertyId && <FiberTPOutletDetails fiber={twistedPair} />}
                      {twistedPair && canUserDeleteFiber(twistedPair) && (
                        <Grid sx={styles.actionFiberContainer}>
                          <Button
                            variant="text"
                            color="error"
                            onClick={() => {
                              setFiberToDelete(twistedPair);
                              setIsDeleteFiberOpen(true);
                            }}
                          >
                            <DeleteIcon /> {t('tp.delete.button')}
                          </Button>
                        </Grid>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          </Grid>
          <Button variant="text" color="error" onClick={handleDelete} disabled={isLoadingDeleteWifiDot}>
            {isLoadingDeleteWifiDot ? <CircularProgress size={16} /> : t('general.delete')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!isValid || isLoadingPatchTPOutlet || !isTwisterPairFormValid}
          >
            {isLoadingPatchTPOutlet ? <CircularProgress size={16} /> : t('general.save')}
          </Button>
        </Grid>
      </Stack>
    </>
  );
};
