import { useTranslation } from 'common/hooks/useTranslation';
import { publicUrl } from 'config';
import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { G } from '@mobily/ts-belt';
import PendingIcon from '@mui/icons-material/Pending';
import { Box, CircularProgress, Grid, InputAdornment, List, ListItem, TextField, Typography } from '@mui/material';

import { usePropertiesList } from '../hooks/usePropertiesList';
import * as propertiesStyles from './properties.styles';
import * as styles from './PropertiesList.styles';
import { PropertyCard } from './PropertyCard/PropertyCard';

export const PropertiesList: FC = () => {
  const { data, isLoading, isError, isAvailable, search, isSearching, isEmpty } = usePropertiesList();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isError) {
      toast.error(t('error.generalMessage'));
    }
  }, [isError, t]);

  useEffect(() => {
    const propertyId = searchParams.get('propertyId');
    if (G.isNotNullable(propertyId) && !isLoading) {
      document.querySelector(`#property-${propertyId}`)?.scrollIntoView();
      window.scrollBy({ top: -50 });
    }
  }, [searchParams, isLoading]);

  return (
    <Grid container sx={styles.container}>
      <Typography sx={propertiesStyles.header}>{t('properties.header')}</Typography>
      <TextField
        data-cy="properties-search"
        sx={styles.searchInput}
        fullWidth
        placeholder={t('properties.searchInput.placeholder')}
        variant="outlined"
        onChange={(e) => search(e.target.value)}
        disabled={!isAvailable}
        InputProps={{
          endAdornment: isSearching && (
            <InputAdornment sx={styles.pendingAdornment} position="end">
              <PendingIcon />
            </InputAdornment>
          ),
        }}
      />
      {isLoading && <CircularProgress sx={styles.loader} />}
      {!isLoading && !isAvailable && (
        // TODO: extract with the one below as it is the same
        <Grid container sx={styles.warningContainer}>
          <Box component="img" src={`${publicUrl}/img/icons/warning.svg`} alt="warning" />
          <Typography sx={styles.warningInfo}>{t('properties.noPropertiesAvailable')}</Typography>
        </Grid>
      )}
      {!isLoading && isAvailable && isEmpty && (
        <Grid container sx={styles.warningContainer}>
          <Box component="img" src={`${publicUrl}/img/icons/warning.svg`} alt="warning" />
          <Typography sx={styles.warningInfo}>{t('properties.noResultsFound')}</Typography>
        </Grid>
      )}
      {!isLoading && isAvailable && !isEmpty && (
        <List disablePadding sx={styles.list}>
          {data.map((property) => (
            <ListItem
              id={`property-${property.id}`}
              data-cy={`${property.description}`}
              key={property.id}
              disablePadding
            >
              <PropertyCard {...property} />
            </ListItem>
          ))}
        </List>
      )}
    </Grid>
  );
};
