import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_TECHNICAL_SWITCH = gql`
  mutation patchTechnicalSwitch($input: TechnicalSwitchInput!) {
    patchTechnicalSwitch(input: $input)
  }
`;

export const patchTechnicalSwitch = (propertyId: number) => (switchDetails: any) => {
  return requestGQL(PATCH_TECHNICAL_SWITCH, { input: { propertyId, switchDetails } });
};
