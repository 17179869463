import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const DELETE_TECHNICAL_EQUIPMENT = gql`
  mutation deleteTechnicalEquipment($input: DeleteNodeInput!) {
    deleteTechnicalEquipment(input: $input)
  }
`;

export const deleteTechnicalEquipment = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_TECHNICAL_EQUIPMENT, { input: { propertyId, nodeId } });
};
