import { z } from 'zod';

export const technicalOdfSchema = z.object({
  text: z.string().min(1),
  connectionsCount: z.number().min(0).int(),
  location: z.string(),
  contactType: z.string(),
});

export type TechnicalODFSchema = z.infer<typeof technicalOdfSchema>;
