import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { useTranslation } from 'common/hooks/useTranslation';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { G } from '@mobily/ts-belt';
import { Box, Grid } from '@mui/material';

import { AvailableOutletTypes } from 'app/outlets/AvailiableOutletTypes';
import { useAzureOutletPhotoUpload } from '../../hooks/useAzureOutletPhotoUpload';
import { useDeletePhoto } from '../../hooks/useDeletePhoto';
import { useOutlets } from '../../hooks/useOutlets';
import { Outlet } from '../../Outlet';
import { EnlargedPhotoDialog } from '../PremiseOutlets/components/EnlargedPhotoDialog';
import { ImagePicker } from './components/ImagePicker';
import { styles } from './OutletPhotos.styles';
import { TPOutlet } from '../../TPOutlet';
import { useTPOutlets } from '../../hooks/useTPOutlets';
import { OutletTypes } from '../OutletMenu/OutletMenu';

export const OutletPhotos: FC<{
  outlet: Outlet | TPOutlet;
  premise: PremiseOnSinglePremise;
  outletType: OutletTypes;
}> = ({ outlet, premise, outletType }) => {
  const { t } = useTranslation();
  const { updateOutletPhoto } = useOutlets(premise);
  const { updateTPOutletPhoto } = useTPOutlets(premise);
  const [enlargedPhotoUrl, setEnlargedPhotoUrl] = useState<null | string>(null);
  const { deletePhoto, isSuccess: isSuccessDeletePhoto } = useDeletePhoto(
    enlargedPhotoUrl ? premise.propertyId : null,
    outlet?.id ?? null,
  );

  const {
    mutate,
    data: uploadedPhotoUrl,
    isLoading: isLoadingPhotoUpload,
    isError: isErrorPhotoUpload,
    isSuccess,
  } = useAzureOutletPhotoUpload();

  useEffect(() => {
    if (isSuccess && G.isNotNullable(uploadedPhotoUrl)) {
      toast.success(t('general.updatedSuccessfully'));
      if (outletType === AvailableOutletTypes.OUTLET) {
        updateOutletPhoto(outlet.id, uploadedPhotoUrl);
      } else if (outletType === AvailableOutletTypes.TPOUTLET) {
        updateTPOutletPhoto(outlet.id, uploadedPhotoUrl);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isErrorPhotoUpload) {
      toast.error(t('error.generalMessage'));
    }
  }, [isErrorPhotoUpload]);

  useEffect(() => {
    if (isSuccessDeletePhoto) {
      setEnlargedPhotoUrl(null);

      toast.success(t('general.deletedSuccessfully'));
    }
  }, [isSuccessDeletePhoto]);

  return (
    <>
      <EnlargedPhotoDialog
        isOpen={G.isNotNullable(enlargedPhotoUrl)}
        photoUrl={enlargedPhotoUrl}
        outlet={outlet}
        onClose={() => setEnlargedPhotoUrl(null)}
        onDelete={() => {
          if (G.isNotNullable(enlargedPhotoUrl)) deletePhoto(enlargedPhotoUrl);
        }}
      />
      <Grid container sx={styles.photosContainer}>
        {outlet.imagesUrls.map((image, i) => (
          <Box
            data-cy={`image-${i}`}
            key={image}
            sx={styles.photo}
            component="img"
            src={image}
            alt={`outlet-image-${i + 1}`}
            onClick={() => setEnlargedPhotoUrl(image)}
          />
        ))}
        <ImagePicker
          isLoading={isLoadingPhotoUpload}
          onChange={(file) => {
            mutate(file);
          }}
        />
      </Grid>
    </>
  );
};
