import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { FC, memo } from 'react';
import { TwistedPair } from '../../../TPOutlet';
import * as styles from './styles';

export const FiberTPOutletDetails: FC<{ fiber: TwistedPair }> = memo(({ fiber }) => {
  const { t } = useTranslation();

  return (
    <Grid container sx={styles.fiberDetailsContainer}>
      <TextField fullWidth value={fiber.crossConnectionPanelName} label={t('fiber.crossConnectionPanel')} disabled />
      <TextField fullWidth value={fiber.from.portId} label={t('fiber.crossPanelPort')} disabled />
      <TextField fullWidth value={fiber.length} label={t('fiber.length')} disabled />
      <TextField fullWidth value={fiber.category} label={t('fiber.category')} disabled />
      <TextField fullWidth value={fiber.shielding} label={t('fiber.shielding')} disabled />
    </Grid>
  );
});
