import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_RADIO_UNIT = gql`
  mutation createRadioUnit($input: RadioUnitInput!) {
    createRadioUnit(input: $input)
  }
`;

export const createRadioUnit = (propertyId: number) => (radioUnitDetails: any) => {
  return requestGQL(CREATE_RADIO_UNIT, { input: { propertyId, radioUnitDetails } });
};
