import { Alert, CircularProgress, Stack } from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC } from 'react';
import { getAreas } from '../getAreas';
import { useGetPropertyConnections } from '../hooks/useGetPropertyConnections';
import { PreviewItem } from '../types/PreviewItem';
import { ObjectAccordion } from '../ui/ObjectAccordion';

type Props = {
  onItemClick: Lambda<PreviewItem, void>;
};

export const AreasPreview: FC<Props> = ({ onItemClick }) => {
  const { t } = useTranslation();
  const { status, data } = useGetPropertyConnections();

  if (status === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'error') {
    return <Alert severity="error">{t('areas.properties.error')}</Alert>;
  }

  const {
    incomingFibers,
    trunks,
    switches,
    technicalSwitches,
    ODFs,
    technicalODFs,
    tpOutlets,
    wifiDots,
    technicalEquipments,
    crossPanels,
    fireBreaches,
    racks,
    cableRoutes,
  } = getAreas(data);

  const handleOnItemClick = (item: PreviewItem) => {
    onItemClick(item);
  };

  return (
    <Stack width="100%" mt={2} gap={2}>
      <ObjectAccordion label="Incoming Fibers" items={incomingFibers} onItemClick={handleOnItemClick} />
      <ObjectAccordion label="Trunks" items={trunks} onItemClick={handleOnItemClick} />
      <ObjectAccordion label="Switches" items={switches} onItemClick={handleOnItemClick} />
      <ObjectAccordion label="ODFs" items={ODFs} onItemClick={handleOnItemClick} />
      <ObjectAccordion label="Technical Networks" items={technicalSwitches} onItemClick={handleOnItemClick} />
      <ObjectAccordion label="Technical ODFs" items={technicalODFs} onItemClick={handleOnItemClick} />
      <ObjectAccordion label="TP Outlets" items={tpOutlets} onItemClick={handleOnItemClick} />
      <ObjectAccordion label="Wifi Spots" items={wifiDots} onItemClick={handleOnItemClick} />
      <ObjectAccordion label="Technical Equipments" items={technicalEquipments} onItemClick={handleOnItemClick} />
      <ObjectAccordion label="Cross Connestion Panels" items={crossPanels} onItemClick={handleOnItemClick} />
      <ObjectAccordion label="Fire Breaches" items={fireBreaches} onItemClick={handleOnItemClick} />
      <ObjectAccordion label="Racks" items={racks} onItemClick={handleOnItemClick} />
      <ObjectAccordion label="Cable Routes" items={cableRoutes} onItemClick={handleOnItemClick} />
    </Stack>
  );
};
