import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { useTranslation } from 'common/hooks/useTranslation';
import { publicUrl } from 'config';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useCreateTPOutlet } from '../../hooks/useCreateTPOutlet';
import { TPOutlets } from '../../TPOutlet';
import { SinglePremiseTPOutlet } from './components/SinglePremiseTPOutlet';
import * as styles from './PremiseTPOutlets.styles';

export const PremiseTPOutlets: FC<{ tpOutlets: TPOutlets; premise: PremiseOnSinglePremise }> = ({
  tpOutlets,
  premise,
}) => {
  const { t } = useTranslation();
  const [currentOutletIndex, setCurrentOutletIndex] = useState<null | number>(tpOutlets.length === 0 ? null : 0);

  const { mutate, isSuccess, error, isLoading } = useCreateTPOutlet(premise.propertyId, premise.id);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('general.updatedSuccessfully'));
      if (currentOutletIndex === null) {
        setCurrentOutletIndex(0);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      toast.error(t('premise.outlet.add.error'));
    }
  }, [error]);

  return (
    <Grid container>
      <Grid container sx={styles.outletsList}>
        {tpOutlets.length > 0 && (
          <Grid container sx={styles.outlets}>
            {tpOutlets.map((outlet, i) => (
              <Button
                key={outlet.id}
                sx={styles.outletButton}
                variant={currentOutletIndex === i ? 'contained' : 'outlined'}
                onClick={() => {
                  setCurrentOutletIndex(i);
                }}
                data-testid={`outlet-button-${i}`}
              >
                {`${t('premise.outlet')}${i + 1}`}
              </Button>
            ))}
            <IconButton sx={styles.addOutletButton} onClick={() => mutate()}>
              <AddIcon titleAccess="add-outlet" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {tpOutlets.length === 0 && (
        <Grid container sx={styles.warningContainer}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Box component="img" src={`${publicUrl}/img/icons/warning.svg`} alt="warning" />
              <Typography sx={styles.warningInfo}>{t('premise.noOutletsAvailable')}</Typography>
              <Button sx={styles.addFirstOutlet} variant="contained" onClick={() => mutate()}>
                {t('premise.outlet.addFirstOutlet')}
              </Button>
            </>
          )}
        </Grid>
      )}
      <Grid>
        {tpOutlets.length > 0 &&
          tpOutlets.map((outlet, index) =>
            currentOutletIndex === index ? (
              <SinglePremiseTPOutlet
                key={outlet.id}
                outlet={outlet}
                premise={premise}
                onDelete={() => setCurrentOutletIndex((value) => (value !== null && value >= 1 ? value - 1 : null))}
              />
            ) : null,
          )}
      </Grid>
    </Grid>
  );
};
