import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_INPUT_ANTENNA = gql`
  mutation createInputAntenna($input: InputAntennaInput!) {
    createInputAntenna(input: $input)
  }
`;

export const createInputAntenna = (propertyId: number) => (inputAntennaDetails: any) => {
  return requestGQL(CREATE_INPUT_ANTENNA, { input: { propertyId, inputAntennaDetails } });
};
