export const oneSecondInMs = 1000;
export const oneHourInMs = 3600 * oneSecondInMs;
export const oneDayInMs = 24 * oneHourInMs;

export const graphQLUri = process.env.REACT_APP_GRAPHQL_URI!;
export const apiUri = process.env.REACT_APP_API_URI!;
export const publicUrl = process.env.PUBLIC_URL!;
export const IAMUri = process.env.REACT_APP_IAM_URI!;
export const clientID = process.env.REACT_APP_CLIENT_ID!;
export const isTestEnv = process.env.NODE_ENV === 'test';
export const loginUrl = process.env.REACT_APP_LOGIN_URL!;
export const logoutUrl = process.env.REACT_APP_LOGOUT_URL!;
export const loginRedirectUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL!;
export const logoutRedirectUrl = process.env.REACT_APP_LOGOUT_REDIRECT_URL!;
