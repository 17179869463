import { Styles } from '../../theme/materialTheme';

export const topbar: Styles = (theme) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  height: 50,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  px: 2,
});

export const topbarRadioNetwork: Styles = () => ({
  borderBottom: '1px solid #b8ebeb',
});

export const topbarNetwork: Styles = () => ({
  borderBottom: '1px solid #ebd3b8',
});

export const topbarLogo: Styles = () => ({
  height: '30px',
});

export const topbarRight: Styles = () => ({
  display: 'flex',
});

export const menuItemError: Styles = (theme) => ({
  color: theme.palette.error.main,
});
