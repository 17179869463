import { A, O, pipe } from '@mobily/ts-belt';
import { GetPropertyConnectionResponse } from 'app/connection/api';
import { ObjectDetails } from './types/ObjectDetails';

export const getObject =
  (objectId: string) =>
  (response: GetPropertyConnectionResponse): O.Option<ObjectDetails> => {
    const nodes = response.propertyById.connectionByConnectionId.serialized2.nodeDataArray;

    const node = A.find(nodes, (connectionNode) => connectionNode.id === objectId);

    if (O.isNone(node)) {
      return O.None;
    }

    const { cfObject } = node;

    switch (node.model) {
      case 'bundle':
        return O.Some({
          id: node.id,
          type: 'IncomingFiber',
          text: O.fromNullable(node.text),
          location: O.fromNullable(cfObject?.location),
          room: O.fromNullable(cfObject?.room),
          connectionsCount: O.fromNullable(node.portCount),
          netOwner: O.fromNullable(cfObject?.default_netowner),
          photosURLs: cfObject?.images ?? [],
          length: pipe(
            O.fromNullable(cfObject?.patchLength),
            O.map((v) => parseInt(v, 10)),
          ),
        });
      case 'trunk':
        return O.Some({
          id: node.id,
          type: 'Trunk',
          text: O.fromNullable(node.text),
          connectionsCount: O.fromNullable(node.portCount),
          location: O.fromNullable(cfObject?.location),
          length: O.fromNullable(cfObject?.length),
          photosURLs: cfObject?.images ?? [],
        });
      case 'switch':
        return O.Some({
          id: node.id,
          type: 'Switch',
          text: O.fromNullable(node.text),
          brand: O.fromNullable(cfObject?.brand),
          connectionsCount: O.fromNullable(node.portCount),
          location: O.fromNullable(cfObject?.location),
          incomingIP: O.fromNullable(cfObject?.ip),
          installedAt: O.fromNullable(cfObject?.installedAt),
          contactType: O.fromNullable(cfObject?.contactType),
          model: O.fromNullable(cfObject?.model),
          switchType: O.fromNullable(cfObject?.switchType),
          propertyOf: O.fromNullable(cfObject?.default_netowner),
          photosURLs: cfObject?.images ?? [],
        });
      case 'technicalSwitch':
        return O.Some({
          id: node.id,
          type: 'TechnicalSwitch',
          text: O.fromNullable(node.text),
          brand: O.fromNullable(cfObject?.brand),
          connectionsCount: O.fromNullable(node.portCount),
          location: O.fromNullable(cfObject?.location),
          installedAt: O.fromNullable(cfObject?.installedAt),
          switchType: O.fromNullable(cfObject?.switchType),
          contactType: O.fromNullable(cfObject?.contactType),
          deviceType: O.fromNullable(cfObject?.deviceType),
          model: O.fromNullable(cfObject?.model),
          propertyOf: O.fromNullable(cfObject?.default_netowner),
          photosURLs: cfObject?.images ?? [],
        });
      case 'odf':
        return O.Some({
          id: node.id,
          type: 'ODF',
          text: O.fromNullable(node.text),
          connectionsCount: O.fromNullable(node.portCount),
          location: O.fromNullable(cfObject?.location),
          photosURLs: cfObject?.images ?? [],
          contactType: O.fromNullable(cfObject?.contactType),
        });
      case 'technicalOdf':
        return O.Some({
          id: node.id,
          type: 'TechnicalODF',
          text: O.fromNullable(node.text),
          connectionsCount: O.fromNullable(node.portCount),
          location: O.fromNullable(cfObject?.location),
          photosURLs: cfObject?.images ?? [],
          contactType: O.fromNullable(cfObject?.contactType),
        });
      case 'wifiDot':
        return O.Some({
          id: node.id,
          type: 'WifiDot',
          text: O.fromNullable(node.text),
          group: O.fromNullable(node.group),
          portCount: O.fromNullable(node.portCount),
          location: O.fromNullable(cfObject?.location),
          ip: O.fromNullable(cfObject?.ip),
          mac: O.fromNullable(cfObject?.mac),
          serialNumber: O.fromNullable(cfObject?.serialNumber),
          photosURLs: cfObject?.images ?? [],
        });
      case 'tpOutlet':
        return O.Some({
          id: node.id,
          type: 'TpOutlet',
          text: O.fromNullable(node.text),
          group: O.fromNullable(node.group),
          portCount: O.fromNullable(node.portCount),
          location: O.fromNullable(cfObject?.location),
          photosURLs: cfObject?.images ?? [],
        });
      case 'crossPanel':
        return O.Some({
          id: node.id,
          type: 'CrossConnectionPanel',
          text: O.fromNullable(node.text),
          portCount: O.fromNullable(node.portCount),
          location: O.fromNullable(cfObject?.location),
          photosURLs: cfObject?.images ?? [],
        });
      case 'technicalEquipment':
        return O.Some({
          id: node.id,
          type: 'TechnicalEquipment',
          text: O.fromNullable(node.text),
          portCount: O.fromNullable(node.portCount),
          location: O.fromNullable(cfObject?.location),
          ip: O.fromNullable(cfObject?.ip),
          photosURLs: cfObject?.images ?? [],
        });
      case 'fireBreach':
        return O.Some({
          id: node.id,
          type: 'FireBreach',
          text: O.fromNullable(node.text),
          location: O.fromNullable(cfObject?.location),
          photosURLs: cfObject?.images ?? [],
        });
      case 'rack':
        return O.Some({
          id: node.id,
          type: 'Rack',
          text: O.fromNullable(node.text),
          location: O.fromNullable(cfObject?.location),
          photosURLs: cfObject?.images ?? [],
        });
      case 'cableRoute':
        return O.Some({
          id: node.id,
          type: 'CableRoute',
          text: O.fromNullable(node.text),
          location: O.fromNullable(cfObject?.location),
          photosURLs: cfObject?.images ?? [],
        });
      default:
        return O.None;
    }
  };
