import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_TPOUTLET = gql`
  mutation patchTpOutlet($input: TpOutletInput!) {
    patchTpOutlet(input: $input)
  }
`;

export const patchTpOutlet = (propertyId: number) => (tpOutletDetails: any) => {
  return requestGQL(PATCH_TPOUTLET, { input: { propertyId, tpOutletDetails } });
};
