import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { publicUrl } from 'config';
import { ChangeEvent, FC, useEffect, useRef } from 'react';
import Resizer from 'react-image-file-resizer';

import { G } from '@mobily/ts-belt';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { useAzureObjectPhotoUpload } from '../types/useAzureObjectPhotoUpload';

type Props = {
  onChange: Lambda<string, void>;
};

export const ImagePicker: FC<Props> = ({ onChange }) => {
  const { mutate, data: uploadedPhotoUrl, isLoading, isSuccess } = useAzureObjectPhotoUpload();

  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();

  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (G.isNotNullable(file)) {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        'JPEG',
        100,
        0,
        (resizedFile) => {
          const fileWithName = new File([resizedFile as File], file.name);
          mutate(fileWithName);
        },
        'file',
      );
    }
  };

  useEffect(() => {
    if (isSuccess && G.isNotNullable(uploadedPhotoUrl)) {
      onChange(uploadedPhotoUrl);
    }
  }, [isSuccess]);

  return (
    <>
      <Box
        sx={{
          visibility: 'hidden',
          position: 'absolute',
          height: 0,
        }}
        ref={inputRef}
        component="input"
        accept="image/*"
        type="file"
        onChange={onChangeFile}
      />
      <Button
        data-cy="add-photo"
        sx={{
          cursor: 'pointer',
          borderRadius: 0.5,
          width: 92,
          height: 92,
          border: '1px solid #DBE1E2',
          backgroundColor: '#F4F6F6',
          textTransform: 'uppercase',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <>
            <Box component="img" src={`${publicUrl}/img/icons/addPhoto.svg`} alt="add" />
            <Typography sx={{ lineHeight: 1, fontSize: 12, color: '#707475' }}>{t('premise.addPhoto')}</Typography>
          </>
        )}
      </Button>
    </>
  );
};
