import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const DELETE_ODF = gql`
  mutation deleteODF($input: DeleteNodeInput!) {
    deleteODF(input: $input)
  }
`;

export const deleteODF = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_ODF, { input: { propertyId, nodeId } });
};
