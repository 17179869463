import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { azureUpload, getAzureSignedLinkOutlet } from '../api';

export const useAzureOutletPhotoUpload = () => {
  const { data, mutate, isLoading, isError, isSuccess } = useMutation<string, unknown, File>(async (file) => {
    const { url, query } = await getAzureSignedLinkOutlet();

    const fileName = `${uuidv4()}-${file!.name}`;

    await azureUpload(new File([file!], fileName!), `${url}?${query}`);

    return `${url}outlet/${fileName}`;
  });

  return {
    data,
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};
