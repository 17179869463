import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { G } from '@mobily/ts-belt';

import { requestGQL } from '../../../common/graphQlClient';
import { isTestEnv, oneHourInMs } from '../../../config';
import { PremiseOnSinglePremise } from '../../premises/Premise';
import { GET_RADIO_OUTLETS, GetRadioOutletsResponse } from '../api';
import { parseGetDropAntennasResponse } from '../DropAntenna';

export const useDropAntennas = (premise: null | PremiseOnSinglePremise) => {
  const propertyId = useMemo(() => {
    return premise?.propertyId ?? null;
  }, [premise]);

  const {
    data,
    isLoading: isLoadingDropAntennas,
    isError: isErrorDropAntennas,
    isRefetching,
  } = useQuery<GetRadioOutletsResponse>(
    ['dropAntennasByPropertyId', propertyId],
    () => requestGQL(GET_RADIO_OUTLETS, { id: propertyId }),
    {
      enabled: G.isNotNullable(premise),
      refetchOnWindowFocus: false,
      retry: isTestEnv ? 0 : 3,
      staleTime: oneHourInMs,
    },
  );

  const dropAntennas = useMemo(() => {
    return G.isNullable(data) || G.isNullable(premise) ? [] : parseGetDropAntennasResponse(premise)(data);
  }, [data, isRefetching]);

  return { isLoadingDropAntennas, isErrorDropAntennas, dropAntennas };
};
