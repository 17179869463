import { v4 as uuidv4 } from 'uuid';

import { A, D, G, O, Option, pipe, S } from '@mobily/ts-belt';

export const optionParseInt = (a: string | undefined | null) =>
  pipe(
    O.fromNullable(a),
    O.map((b) => parseInt(b, 10)),
    O.toNullable,
  );

export const optionGet =
  <A, B extends keyof A>(prop: B) =>
  (dict: Option<A>) =>
    pipe(O.fromNullable(dict), O.flatMap(D.get(prop)), O.toNullable);

// TODO: change to url instead of window.open
export const redirectToMapsLocation = (location: string) => {
  const userAgent = navigator.userAgent || navigator.vendor;
  const device = /iPad|iPhone|iPod/.test(userAgent) ? 'iOS' : 'Android';

  if (device === 'iOS') {
    return window.open(`https://maps.apple.com/?q=${encodeURIComponent(location)}`);
  }

  return window.open(`https://maps.google.com/?q=${encodeURIComponent(location)}`);
};

export const redirectToPhone = (phoneNumber: string) => {
  return `tel:${phoneNumber}`;
};

export const redirectToEmail = (email: string) => {
  return `mailto:${email}`;
};

export const prependWithSpaceIfNonEmpty = (v: null | undefined | string) => {
  if (G.isNullable(v) || S.isEmpty(v)) {
    return '';
  }

  return ` ${v}`;
};

export const getNameFromNameWithUUID = (filenameWithUUID: string) => {
  const filenameWithUUIDRegexPattern = /.{8}-.{4}-.{4}-.{4}-.{12}-.*/;
  if (filenameWithUUIDRegexPattern.test(filenameWithUUID)) {
    return filenameWithUUID.substring(37);
  }
  return filenameWithUUID;
};

export const getFileNameFromUrl = (url: string) => {
  return pipe(url, (u) => u.split('/'), A.last, O.mapWithDefault('', getNameFromNameWithUUID));
};

export const getUUID = uuidv4;

export const clearOfSpaces = (v: string) => S.replaceAll(v, ' ', '');

export const padStart = (n: string | number, limit: number, fillValue: string) =>
  pipe(
    n,
    (v) => `${v}`,
    (s) => s.padStart(limit, fillValue),
  );

export const navigate = (url: string) => location.replace(url);
