import { z } from 'zod';

export const technicalSwitchSchema = z.object({
  text: z.string().min(1),
  connectionsCount: z.number().min(0).int(),
  location: z.string(),
  model: z.string(),
  brand: z.string(),
  propertyOf: z.string(),
  installedAt: z.string(),
  switchType: z.string(),
  contactType: z.string(),
  deviceType: z.string(),
});

export type TechnicalSwitchSchema = z.infer<typeof technicalSwitchSchema>;
