import { FieldErrorsImpl } from 'react-hook-form';

import { O, pipe } from '@mobily/ts-belt';

import { ValidationError } from './ValidationError';

export const useErrorMessage = <A,>(errors: FieldErrorsImpl<A>) => {
  return (control: keyof A) =>
    pipe(
      O.fromNullable(errors[control]?.message),
      O.map((message) => <ValidationError>{`${message}`}</ValidationError>),
      O.toNullable,
    );
};
