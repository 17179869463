import { CoaxClass } from 'app/outlets/api';
import { gql } from 'graphql-request';

export type CFObject = {
  text?: string;
  premisesId?: number;
  images?: string[];
  location?: string;
  odf?: string;
  crossConnectionPanel?: string;
  fiber?: string;
  duct?: string;
  length?: number;
  db1310?: number;
  db1550?: number;
  designator?: string;
  personId?: string;
  room?: string;
  default_netowner?: string;
  brand?: string;
  ip?: string;
  other?: string;
  installedAt?: string;
  model?: string;
  default_owner?: string;
  patchLength?: string;
  category?: string;
  class?: CoaxClass;
  unitType?: string;
  operator?: string;
  voltage?: number;
  serialNumber?: string;
  leftPortCount?: number;
  rightPortCount?: number;
  contactType?: string;
  switchType?: string;
  deviceType?: string;
  mac?: string;
  shielding?: string;
};

export type LinkData = {
  id: string;
  from: string;
  fromPort: string;
  to: string;
  toPort: string;
  cfObject?: CFObject;
  text: null | string;
  highlight: boolean;
  type?: string;
};

export type LinksData = ReadonlyArray<LinkData>;

export type NodeData = {
  text: null | string;
  id: string;
  model: string;
  group: string;
  cfObject?: CFObject;
  leftArray?: ReadonlyArray<{ portId: string }>;
  rightArray?: ReadonlyArray<{ portId: string }>;
  portCount?: number;
  leftPortCount?: number;
  rightPortCount?: number;
};

export type NodesData = ReadonlyArray<NodeData>;

export type SerializedData = {
  linkDataArray: LinksData;
  nodeDataArray: NodesData;
};

export type PropertyConnection = {
  id: number;
  serialized2: SerializedData;
};

export type GetPropertyConnectionResponse = {
  propertyById: {
    connectionByConnectionId: PropertyConnection;
  };
};

export const GET_PROPERTY_CONNECTION = gql`
  query propertyConnection($id: Int!) {
    propertyById(id: $id) {
      connectionByConnectionId {
        id
        serialized2
      }
    }
  }
`;
export type GetPropertyRadioConnectionResponse = {
  propertyById: {
    radioConnectionByRadioConnectionId: PropertyConnection;
  };
};

export const GET_PROPERTY_RADIO_CONNECTION = gql`
  query propertyRadioConnection($id: Int!) {
    propertyById(id: $id) {
      radioConnectionByRadioConnectionId {
        id
        serialized2
      }
    }
  }
`;

export const UPDATE_CONNECTION = gql`
  mutation updateConnectionById($id: Int!, $connectionPatch: ConnectionPatch!) {
    updateConnectionById(input: { id: $id, connectionPatch: $connectionPatch }) {
      connection {
        serialized2
      }
    }
  }
`;

export const UPDATE_RADIO_CONNECTION = gql`
  mutation updateRadioConnectionById($id: Int!, $radioConnectionPatch: RadioConnectionPatch!) {
    updateRadioConnectionById(input: { id: $id, radioConnectionPatch: $radioConnectionPatch }) {
      radioConnection {
        serialized2
      }
    }
  }
`;
