import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const CREATE_FIRE_BREACH = gql`
  mutation createFireBreach($input: BasicObjectInput!) {
    createFireBreach(input: $input)
  }
`;

export const createFireBreach = (propertyId: number) => (basicObjectDetails: any) => {
  return requestGQL(CREATE_FIRE_BREACH, { input: { propertyId, basicObjectDetails } });
};
