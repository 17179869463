import {
  createTwistedPair,
  getAllNodesConnectedForNodeId,
  getDataText,
  getNodeText,
  getNodeById,
  isTPOutletOutgoingPortAvailable,
  isNodeIncomingPortAvailable,
} from 'app/connection/Connection';
import { useConnection } from 'app/connection/hooks/useConnection';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import * as R from 'rambda';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { toast } from 'react-toastify';
import { A, G, D, O, pipe } from '@mobily/ts-belt';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { TPCategory } from 'app/fibers/TPCategory';
import { TPShielding } from 'app/fibers/TPShielding';
import { AddFiberTPOutletToNodeSchema } from 'app/fibers/addFiberTPOutletToNodeSchema';
import { SerializedData } from 'app/connection/api';
import { useCreateTwistedPair } from '../../useCreateTwistedPair';
import { styles } from '../AddFiber.styles';
import { ObjectDetails } from '../../../areas/types/ObjectDetails';
import { usePropertyContext } from '../../../properties/components/SingleProperty';

export const AddFiberTPOutletToNode: FC<{
  nodeDetails: ObjectDetails;
  onSuccess?: Lambda<void, void>;
}> = ({ nodeDetails, onSuccess }) => {
  const { id: propertyId } = usePropertyContext();

  const { tpOutletsNodes, serialized2, isLoading, isError } = useConnection(propertyId);

  const {
    mutate,
    isLoading: isLoadingUpdateConnection,
    isError: isErrorUpdateConnection,
    isSuccess: isSuccessUpdateConnection,
  } = useCreateTwistedPair();

  const { t } = useTranslation();

  useEffect(() => {
    if (isError || isErrorUpdateConnection) toast.error(t('error.generalMessage'));
  }, [isError, isErrorUpdateConnection]);

  useEffect(() => {
    if (isSuccessUpdateConnection) toast.success(t('general.updatedSuccessfully'));
    if (isSuccessUpdateConnection && G.isNotNullable(onSuccess)) onSuccess();
  }, [isSuccessUpdateConnection]);

  const tpOutletSelectOptions = useMemo(
    () =>
      pipe(O.fromNullable(tpOutletsNodes), O.map(R.pipe(A.map(getDataText), A.filter(G.isNotNullable))), O.toNullable),
    [tpOutletsNodes],
  );

  const { control, register, handleSubmit, watch, setValue, trigger } = useForm<AddFiberTPOutletToNodeSchema>();

  const { errors } = useFormState({ control });

  const getErrorMessage = useErrorMessage(errors);

  const formTPOutletValue = watch('tpOutlet');

  const pickedNode = useMemo(
    () => getNodeById(serialized2 as SerializedData, nodeDetails.id),
    [serialized2, nodeDetails.id],
  );

  const connectedNodes = useMemo(
    () => getAllNodesConnectedForNodeId(serialized2 as SerializedData, nodeDetails.id),
    [serialized2, nodeDetails.id],
  );

  const pickedtpOutletNode = useMemo(
    () =>
      pipe(
        O.fromNullable(tpOutletsNodes),
        O.flatMap(A.find((tpOutlet) => getNodeText(tpOutlet) === formTPOutletValue)),
        O.toNullable,
      ),
    [tpOutletsNodes, formTPOutletValue],
  );

  useEffect(() => {
    pipe(
      O.fromNullable(tpOutletsNodes),
      O.flatMap(A.head),
      O.flatMap((node) => O.fromNullable(getNodeText(node))),
      O.tap((defaulttpOutletValue) => setValue('tpOutlet', defaulttpOutletValue)),
    );
  }, [tpOutletsNodes]);

  useEffect(() => {
    trigger();
  }, [formTPOutletValue, pickedtpOutletNode]);

  const submitFiber = useCallback(
    (formData: AddFiberTPOutletToNodeSchema) => {
      if (G.isNotNullable(serialized2) && G.isNotNullable(tpOutletsNodes) && D.isEmpty(errors)) {
        if (G.isNotNullable(pickedtpOutletNode) && G.isNotNullable(pickedNode)) {
          const parsedNodePort = `${formData.nodePort}`.padStart(2, '0');
          const parsedTPOutletPort = `${formData.tpOutletPort}`.padStart(2, '0');
          mutate(
            createTwistedPair(
              pickedNode,
              pickedtpOutletNode,
              parsedTPOutletPort,
              parsedNodePort,
              formData.length,
              formData.category,
              formData.shielding,
            ),
          );
        }
      }
    },
    [serialized2, pickedtpOutletNode],
  );
  const formRef = useRef(null);
  const connectButton = () => {
    handleSubmit(submitFiber)();
  };
  const node = getNodeById(serialized2!, nodeDetails.id);
  return (
    <Grid container sx={styles.centerContainer}>
      {isLoading && <CircularProgress />}
      {!isLoading && !isError && serialized2 && (
        <Grid container component="form" ref={formRef}>
          <Grid container columnSpacing={1.5} rowSpacing={4}>
            <Grid item xs={12}>
              {tpOutletSelectOptions && (
                <FormControl fullWidth>
                  <InputLabel htmlFor="tpOutlet">{t('fiber.tpOutlet')}</InputLabel>
                  <NativeSelect
                    {...register('tpOutlet', { required: true })}
                    input={<OutlinedInput fullWidth inputProps={{ 'data-testid': 'add-fiber-tpOutlet' }} />}
                    id="tpOutlet"
                  >
                    {tpOutletSelectOptions.map((tpOutletToPick) => (
                      <option key={tpOutletToPick} value={tpOutletToPick}>
                        {tpOutletToPick}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('tpOutletPort', {
                  valueAsNumber: true,
                  required: true,
                  validate: (tpOutletPort) => {
                    if (
                      G.isNotNullable(pickedtpOutletNode) &&
                      !isTPOutletOutgoingPortAvailable(serialized2, pickedtpOutletNode, tpOutletPort)
                    ) {
                      return t('fiber.add.error.invalidtpOutletPort');
                    }
                    return true;
                  },
                })}
                inputProps={{
                  'data-testid': 'add-fiber-fiber',
                }}
                type="number"
                fullWidth
                label={t('fiber.tpOutletPort')}
                variant="outlined"
                helperText={getErrorMessage('tpOutletPort')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('nodePort', {
                  valueAsNumber: true,
                  required: true,
                  validate: (nodePort) => {
                    if (G.isNotNullable(node) && !isNodeIncomingPortAvailable(serialized2, node, nodePort)) {
                      return t('fiber.add.error.nodePort');
                    }
                    return true;
                  },
                })}
                inputProps={{
                  'data-testid': 'add-fiber-fiber',
                }}
                type="number"
                fullWidth
                label={t('fiber.nodePort')}
                variant="outlined"
                helperText={getErrorMessage('nodePort')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('category', { required: true })}
                select
                fullWidth
                label={t('fiber.category')}
                variant="outlined"
                helperText={getErrorMessage('category')}
                value={watch('category') || ''}
              >
                {Object.values(TPCategory).map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('shielding', { required: true })}
                select
                fullWidth
                label={t('fiber.shielding')}
                variant="outlined"
                helperText={getErrorMessage('shielding')}
                value={watch('shielding') || ''}
              >
                {Object.values(TPShielding).map((shielding) => (
                  <MenuItem key={shielding} value={shielding}>
                    {shielding}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('length', { valueAsNumber: true })}
                inputProps={{ type: 'number', step: '0.01', 'data-testid': 'add-fiber-length' }}
                fullWidth
                label={t('tp.length')}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container direction="column" sx={{ pt: 2 }}>
            <Typography sx={{ fontSize: 16, pb: 1 }}>{t('fiber.add.currentConnections')}</Typography>
            {connectedNodes.length > 0 ? (
              connectedNodes.map((connectedNode) => (
                <Typography key={connectedNode?.id} sx={{ fontSize: 14, pb: 1, pl: 1 }}>
                  {`${connectedNode?.text} port ${connectedNode.port}`}
                </Typography>
              ))
            ) : (
              <Typography sx={{ fontSize: 14, pb: 1, pl: 1 }}>{t('fiber.add.noConnections')}</Typography>
            )}
          </Grid>
          <Grid container sx={styles.addFiberSubmitContainer}>
            {isLoadingUpdateConnection && <CircularProgress />}
            {!isLoadingUpdateConnection && !isSuccessUpdateConnection && (
              <Button onClick={connectButton} variant="contained" data-testid="add-fiber-form-save">
                {t('general.save')}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
