import { z } from 'zod';

export const repeaterSchema = z.object({
  text: z.string().min(1),
  location: z.string(),
  unitType: z.string(),
  serialNumber: z.string(),
});

export type RepeaterSchema = z.infer<typeof repeaterSchema>;
