import { A } from '@mobily/ts-belt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC } from 'react';
import { PreviewItem } from '../types/PreviewItem';

type Props = {
  label: string;
  items: ReadonlyArray<PreviewItem>;
  onItemClick: Lambda<PreviewItem, void>;
};

const Photos: FC<{ items: ReadonlyArray<string>; label: string }> = ({ items, label }) => {
  const amountOfPhotos = items.length;

  if (amountOfPhotos === 0) {
    return null;
  }

  const maxFirstThreePhotosUrls = A.slice(items, 0, 3);

  return (
    <List sx={{ display: 'flex', gap: 0.25, p: 0 }}>
      {maxFirstThreePhotosUrls.map((photoUrl, index) => (
        <ListItem key={`${label}-${photoUrl}}`} sx={{ p: 0 }}>
          <Box component="img" src={photoUrl} alt={`${label}-image-${index}`} width={32} height={32} />
        </ListItem>
      ))}
    </List>
  );
};

export const ObjectAccordion: FC<Props> = ({ label, items, onItemClick }) => {
  const amountOfItems = items.length;
  const { t } = useTranslation();

  if (amountOfItems === 0) {
    return (
      <Typography>
        {label} {t('areas.object.unavailable')}
      </Typography>
    );
  }

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container gap={1}>
          <Typography>{label}</Typography>
          <Typography color="primary">({amountOfItems})</Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {items.map((item, index) => {
            return (
              <Box key={item.id}>
                <ListItem
                  key={item.id}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    onItemClick(item);
                  }}
                >
                  <Stack sx={{ width: '100%' }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Typography
                        color="primary"
                        sx={{ flex: '1 1', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {item.text ?? `${item.id.split('-')[0]} - ${index}`}
                      </Typography>
                      <Photos items={item.photosUrls} label={label} />
                    </Grid>
                    <Typography>{item.location}</Typography>
                  </Stack>
                </ListItem>
                <Divider />
              </Box>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
