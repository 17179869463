import { requestGQL } from 'common/graphQlClient';
import { useMutation, useQueryClient } from 'react-query';

import { CREATE_PREMISE_DROP_ANTENNA } from '../api';

export const useCreatePremiseDropAntenna = (propertyId: null | number, premiseId: null | number) => {
  const queryClient = useQueryClient();

  return useMutation<string, { message: string }>(
    async () =>
      requestGQL(CREATE_PREMISE_DROP_ANTENNA, {
        propertyId,
        premiseId,
        dropAntennaDetails: { location: '', photosURLs: [], text: '', portCount: 1, unitType: '', serialNumber: '' },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['outletsByPropertyId', propertyId]);
        queryClient.invalidateQueries(['propertyRadioConnection', propertyId]);
        queryClient.invalidateQueries(['dropAntennasByPropertyId', propertyId]);
      },
    },
  );
};
