import { FC, ReactElement } from 'react';
import { useBoolean } from 'usehooks-ts';

import { G } from '@mobily/ts-belt';
import { Box, Button, Dialog, DialogContent, Grid, IconButton, Toolbar } from '@mui/material';

import { publicUrl } from '../../../config';
import { useTranslation } from '../../hooks/useTranslation';
import { Lambda } from '../../types';
import { styles } from './ConfirmationPrompt.styles';

export const ConfirmationPrompt: FC<{
  actionEl: Lambda<{ onClick: Lambda<unknown | void, void> }, ReactElement>;
  additionalEl?: ReactElement;
  onConfirm?: Lambda<void, void>;
  disabled?: boolean;
}> = ({ actionEl, additionalEl, onConfirm, disabled }) => {
  const { value, setFalse, setTrue } = useBoolean();
  const { t } = useTranslation();

  const handleClickAccept = () => {
    if (G.isNotNullable(onConfirm)) {
      onConfirm();
    }
    setFalse();
  };

  const handleClickCancel = () => {
    setFalse();
  };

  return (
    <>
      {actionEl({ onClick: setTrue })}
      <Dialog open={value} onClose={setFalse} PaperProps={{ sx: styles.dialog }}>
        <Toolbar sx={styles.dialogTopbar}>
          <IconButton size="large" color="inherit" onClick={setFalse}>
            <Box component="img" src={`${publicUrl}/img/icons/x.svg`} alt="add" />
          </IconButton>
        </Toolbar>
        <DialogContent sx={styles.dialogContent}>
          {additionalEl}
          <Grid container sx={styles.dialogActionButtons}>
            <Button variant="outlined" onClick={handleClickCancel}>
              {t('general.cancel')}
            </Button>
            <Button variant="contained" onClick={handleClickAccept} disabled={disabled}>
              {t('general.accept')}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
