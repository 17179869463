import { GetPropertyRadioConnectionResponse } from 'app/connection/api';
import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const GET_RADIO_CONNECTION = gql`
  query propertyRadioConnections($propertyId: Int!) {
    propertyById(id: $propertyId) {
      radioConnectionByRadioConnectionId {
        id
        serialized2
      }
    }
  }
`;

export const getPropertyRadioConnections = (propertyId: number) => {
  return requestGQL<GetPropertyRadioConnectionResponse>(GET_RADIO_CONNECTION, { propertyId });
};
