import {
  getConnectionsFromSerializedData,
  getRadioConnectionsFromSerializedData,
} from 'app/connection/getConnectionsFromSerializedData';
import { optionGet } from 'common/helpers';

import { A, D, G, O, pipe } from '@mobily/ts-belt';

import { z } from 'zod';
import { GetPremiseResponse, GetPremisesResponse, SinglePremiseGetPremisesResponse } from './api';

export const installationBookingSchema = z.object({
  bookingDate: z.string().min(1),
  notes: z.string(),
});

export type InstallationBookingSchema = z.infer<typeof installationBookingSchema>;

export type Premise = Readonly<{
  id: number;
  name: string;
  description: null | string;
  tenant: null | 'EMPTY' | string;
}>;

export type PremiseOnPremisesList = Premise &
  Readonly<{
    connections: ReadonlyArray<string>;
    radioConnections: ReadonlyArray<string>;
    tags: ReadonlyArray<PremiseTag>;
    type: null | PremiseType;
  }>;

export type PremiseOnSinglePremise = Premise &
  Readonly<{
    contacts: {
      tenant: {
        name: null | string;
        email: null | string;
        phone: null | string;
      };
      it: {
        name: null | string;
        email: null | string;
        phone: null | string;
      };
    };
    property: string;
    notes: null | string;
    isNotAccessible: boolean;
    reason: null | NotAccesibleReason;
    propertyId: number;
    installationBookings: ReadonlyArray<{
      id: number;
      bookingDate: string;
      notes: null | string;
    }>;
  }>;

export enum PremiseType {
  Office = 'office',
  Store = 'store',
  School = 'school',
  Clinic = 'clinic',
  Restaurant = 'restaurant',
  Storage = 'storage',
}

export enum PremiseTag {
  IncludedInRent = 'included-in-rent',
  Vacant = 'vacant',
  InterimSolution = 'interim-solution',
}

export enum NotAccesibleReason {
  NotFound = 'notFound',
  Refused = 'refused',
  Other = 'other',
}

const mapSinglePremise = (responsePremise: SinglePremiseGetPremisesResponse): PremiseOnPremisesList => ({
  id: responsePremise.id,
  name: responsePremise.name,
  description: responsePremise.description ?? '',
  tenant: pipe(
    responsePremise,
    D.get('premisesTenantsByPremisesId'),
    O.flatMap(D.get('nodes')),
    O.flatMap(A.last),
    // TODO: test this empty
    O.flatMap((tenant) => {
      if (G.isNullable(tenant)) {
        return 'EMPTY';
      }

      return tenant.companyByTenantId?.name;
    }),
    O.toNullable,
  ),
  connections: pipe(
    responsePremise,
    O.flatMap(D.get('propertyByPropertyId')),
    O.flatMap(D.get('connectionByConnectionId')),
    O.flatMap(O.fromNullable),
    O.flatMap(D.get('serialized2')),
    O.flatMap(getConnectionsFromSerializedData),
    O.flatMap(A.find((connection) => connection.premisesId === responsePremise.id)),
    O.flatMap(D.get('inputs')),
    O.fromNullable,
    O.getWithDefault<ReadonlyArray<string>>([]),
  ),
  radioConnections: pipe(
    responsePremise,
    O.flatMap(D.get('propertyByPropertyId')),
    O.flatMap(D.get('radioConnectionByRadioConnectionId')),
    O.flatMap(O.fromNullable),
    O.flatMap(D.get('serialized2')),
    O.flatMap(getRadioConnectionsFromSerializedData),
    O.flatMap(A.find((connection) => connection.premisesId === responsePremise.id)),
    O.flatMap(D.get('inputs')),
    O.fromNullable,
    O.getWithDefault<ReadonlyArray<string>>([]),
  ),
  tags: responsePremise.tags
    .map((tag) => {
      switch (tag) {
        case 'vakant':
          return PremiseTag.Vacant;
        case 'vacant':
          return PremiseTag.Vacant;
        case 'included-in-rent':
          return PremiseTag.IncludedInRent;
        case 'interim-solution':
          return PremiseTag.InterimSolution;
        default:
          return null;
      }
    })
    .filter(G.isNotNullable) as ReadonlyArray<PremiseTag>,
  type: pipe(
    responsePremise,
    optionGet('premisesTypeId'),
    O.flatMap((premisesTypeId) => {
      const map: Record<number, PremiseType> = {
        1: PremiseType.Office,
        2: PremiseType.Store,
        3: PremiseType.School,
        // there is no type 4
        5: PremiseType.Clinic,
        6: PremiseType.Restaurant,
        7: PremiseType.Storage,
      };

      return map[premisesTypeId] ?? null;
    }),
    O.toNullable,
  ),
});

export const parseGetPremisesResponse = (
  response: GetPremisesResponse,
): null | ReadonlyArray<PremiseOnPremisesList> => {
  return pipe(
    response,
    D.get('allPremises'),
    O.flatMap(D.get('nodes')),
    O.flatMap(A.map(mapSinglePremise)),
    O.toNullable,
  );
};

export const parseGetPremiseResponse = (response: GetPremiseResponse): null | PremiseOnSinglePremise => {
  return pipe(
    response,
    D.get('premisesById'),
    O.flatMap((premise) => {
      const tenant = pipe(premise, D.get('premisesTenantsByPremisesId'), optionGet('nodes'), O.flatMap(A.head));

      return {
        id: premise.id,
        name: premise.name,
        description: premise.description,
        tenant: pipe(O.fromNullable(tenant), optionGet('companyByTenantId'), optionGet('name')),
        contacts: {
          tenant: {
            name: pipe(O.fromNullable(tenant), optionGet('contactPersonTenant')),
            email: pipe(O.fromNullable(tenant), optionGet('contactPersonTenantEmail')),
            phone: pipe(O.fromNullable(tenant), optionGet('contactPersonTenantMobile')),
          },
          it: {
            name: pipe(O.fromNullable(tenant), optionGet('contactPersonIT')),
            email: pipe(O.fromNullable(tenant), optionGet('contactPersonITEmail')),
            phone: pipe(O.fromNullable(tenant), optionGet('contactPersonITMobile')),
          },
        },
        propertyId: premise.propertyByPropertyId.id,
        reason: pipe(
          premise,
          optionGet('reason'),
          O.flatMap((a) => {
            const isNotAccessibleReason = (v: string): v is NotAccesibleReason =>
              A.includes(['notFound', 'other', 'refused'], v);

            return isNotAccessibleReason(a) ? a : null;
          }),
          O.toNullable,
        ),
        notes: pipe(premise, optionGet('notes')),
        isNotAccessible: premise.isNotAccessible,
        property: premise.propertyByPropertyId.name,
        installationBookings: premise.premiseInstallationBookingsByPremiseId.nodes,
      };
    }),
    O.toNullable,
  );
};

export const tagIconSourceMap: Record<PremiseTag, string> = {
  [PremiseTag.Vacant]: 'vacant.svg',
  [PremiseTag.IncludedInRent]: 'includedInRent.svg',
  [PremiseTag.InterimSolution]: 'interimSolution.svg',
};

export const tagTranslationMap: Record<PremiseTag, string> = {
  [PremiseTag.Vacant]: 'premise.tags.vacant',
  [PremiseTag.IncludedInRent]: 'premise.tags.includedInRent',
  [PremiseTag.InterimSolution]: 'premise.tags.interimSolution',
};
