import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const DELETE_WIFIDOT = gql`
  mutation deleteWifiDot($input: DeleteNodeInput!) {
    deleteWifiDot(input: $input)
  }
`;

export const deleteWifiDot = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_WIFIDOT, { input: { propertyId, nodeId } });
};
