import { FC, PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { ThemeProvider } from '@mui/material';

import { isTestEnv } from '../../config';
import { InternationalizationProvider } from '../internationalization/components/InternationalizationProvider';
import { RouterProvider } from '../routes/components/RouterProvider';
import { theme } from '../theme/materialTheme';
import { ToastProvider } from '../ui/ToastProvider';

export const AppProviders: FC<PropsWithChildren> = ({ children }) => {
  return (
    <InternationalizationProvider>
      <QueryClientProvider client={new QueryClient({ defaultOptions: { mutations: { retry: isTestEnv ? 0 : 3 } } })}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={theme}>
          <RouterProvider>
            <ToastProvider>{children}</ToastProvider>
          </RouterProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </InternationalizationProvider>
  );
};
