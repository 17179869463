import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const CREATE_RACK = gql`
  mutation createRack($input: BasicObjectInput!) {
    createRack(input: $input)
  }
`;

export const createRack = (propertyId: number) => (basicObjectDetails: any) => {
  return requestGQL(CREATE_RACK, { input: { propertyId, basicObjectDetails } });
};
