import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_TRUNK = gql`
  mutation patchTrunk($input: TrunkInput!) {
    patchTrunk(input: $input)
  }
`;

export const patchTrunk = (propertyId: number) => (trunkDetails: any) => {
  return requestGQL(PATCH_TRUNK, { input: { propertyId, trunkDetails } });
};
