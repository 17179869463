import { requestGQL } from 'common/graphQlClient';
import { useMutation, useQueryClient } from 'react-query';

import { DELETE_OUTLET_PHOTO, OutletPhotoUpload } from '../api';

export const useMutateDeleteOutletPhoto = (propertyId: null | number) => {
  const queryClient = useQueryClient();

  const { data, mutate, isLoading, isError, isSuccess } = useMutation<string, unknown, OutletPhotoUpload>(
    async (outletPhotoDelete) => requestGQL(DELETE_OUTLET_PHOTO, outletPhotoDelete),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['outletsByPropertyId', propertyId]);
        queryClient.invalidateQueries(['propertyConnection', propertyId]);
        queryClient.invalidateQueries(['allPremises', propertyId]);
      },
    },
  );

  return { data, mutate, isLoading, isError, isSuccess };
};
