import { FC, PropsWithChildren } from 'react';

import { Typography } from '@mui/material';

import * as styles from './ValidationError.styles';

export const ValidationError: FC<PropsWithChildren> = ({ children }) => (
  <Typography variant="caption" sx={styles.message}>
    {children}
  </Typography>
);
