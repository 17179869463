import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_ODF = gql`
  mutation createODF($input: ODFInput!) {
    createODF(input: $input)
  }
`;

export const createODF = (propertyId: number) => (odfDetails: any) => {
  return requestGQL(CREATE_ODF, { input: { propertyId, odfDetails } });
};
