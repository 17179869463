import { O, pipe } from '@mobily/ts-belt';
import { usePropertyRadioContext } from 'app/properties/components/SingleRadioProperty';
import { useQuery } from 'react-query';

import { GetPropertyRadioConnectionResponse } from 'app/connection/api';
import * as TE from 'common/TaskEither';
import { getPropertyRadioConnections } from '../api/getPropertyRadioConnections';

export const useGetPropertyRadioConnections = (): TE.ITaskEither<string, GetPropertyRadioConnectionResponse> => {
  const { id: propertyId } = usePropertyRadioContext();

  const query = useQuery(
    ['propertyRadioConnection', propertyId],
    () => pipe(propertyId, O.map(getPropertyRadioConnections), O.getExn),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  switch (query.status) {
    case 'error':
      return TE.Error(JSON.stringify(query.error));
    case 'success':
      return TE.Ok(query.data);
    default:
      return TE.Loading;
  }
};
