import { Outlet } from 'app/outlets/Outlet';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, CircularProgress, Grid, MenuItem, TextField } from '@mui/material';
import { useQueryClient } from 'react-query';
import { usePatchTwistedPair } from 'app/outlets/hooks/usePatchTwistedPair';
import { zodResolver } from '@hookform/resolvers/zod';

import { TwistedPair } from 'app/outlets/TPOutlet';
import { EditTPSchema, editTPSchema } from 'app/fibers/editTPSchema';
import { TPCategory } from 'app/fibers/TPCategory';
import { TPShielding } from 'app/fibers/TPShielding';
import { styles } from '../AddFiber.styles';

export const EditTPForm: FC<{
  fiber: TwistedPair;
  outletId: Outlet['id'];
  propertyId: number;
  onSuccess: () => void;
}> = ({ fiber, outletId, propertyId, onSuccess }) => {
  const { t } = useTranslation();

  const { mutate, isLoading, isSuccess, isError } = usePatchTwistedPair(outletId, propertyId, fiber);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EditTPSchema>({
    defaultValues: {
      length: fiber.length ?? 0,
      category: fiber.category ?? '',
      shielding: fiber.shielding ?? '',
    },
    resolver: zodResolver(editTPSchema),
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isError) toast.error(t('error.generalMessage'));
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('general.updatedSuccessfully'));
      queryClient.invalidateQueries(['outletsByPropertyId', propertyId]);
      onSuccess();
    }
  }, [isSuccess]);

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: EditTPSchema) => {
    mutate({ fiberForm: values });
  };

  return (
    <Grid container sx={styles.centerContainer}>
      {isLoading && <CircularProgress />}
      {!isLoading && !isError && (
        <Grid container component="form">
          <Grid container columnSpacing={1.5} rowSpacing={4}>
            <Grid item xs={6}>
              <TextField
                value={fiber.crossConnectionPanelName}
                disabled
                fullWidth
                label={t('fiber.crossConnectionPanel')}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={fiber.from.portId}
                disabled
                fullWidth
                label={t('fiber.crossPanelPort')}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register('length', { valueAsNumber: true })}
                inputProps={{ type: 'number', step: '0.01', 'data-testid': 'add-fiber-length' }}
                fullWidth
                required
                label={t('fiber.length')}
                variant="outlined"
                helperText={getErrorMessage('length')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register('category', { required: true })}
                select
                fullWidth
                required
                label={t('fiber.category')}
                variant="outlined"
                helperText={getErrorMessage('category')}
                value={watch('category') || ''}
              >
                {Object.values(TPCategory).map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('shielding', { required: true })}
                select
                fullWidth
                required
                label={t('fiber.shielding')}
                variant="outlined"
                helperText={getErrorMessage('shielding')}
                value={watch('shielding') || ''}
              >
                {Object.values(TPShielding).map((shielding) => (
                  <MenuItem key={shielding} value={shielding}>
                    {shielding}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container sx={styles.addFiberSubmitContainer}>
            <Button onClick={handleSubmit(handleSave)} variant="contained" data-testid="add-fiber-form-save">
              {t('general.save')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
