import { navigate } from 'common/helpers';
import { useTranslation } from 'common/hooks/useTranslation';
import { clientID, loginRedirectUrl, loginUrl } from 'config';
import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { G } from '@mobily/ts-belt';
import { Button, Grid, Typography } from '@mui/material';

import { useLoginPageContext } from '../contexts/LoginPageContext';
import * as styles from './LoginByBankID.styles';
import * as loginMethodStyles from './loginMethod.styles';

export const LoginByBankID: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { setTypeOfLogin } = useLoginPageContext();

  const loginByBankIdUrl = useMemo(
    () =>
      `${loginUrl}?client_id=${clientID}&scope=offline_access&response_type=code&redirect_uri=${loginRedirectUrl}&epw=hidden&state=${encodeURIComponent(
        JSON.stringify({
          client_id: clientID,
          invitation_token: searchParams.get('invite'),
        }),
      )}`,
    [searchParams.get('invite')],
  );

  const isInitialLoad = useMemo(() => {
    return G.isNotNullable(searchParams.get('invite'));
  }, [searchParams.get('invite')]);

  const clickOnLoginByBankIDUrl = () => navigate(loginByBankIdUrl);

  const changeTypeOfLoginToEmail = () => setTypeOfLogin('email');

  return (
    <Grid container sx={loginMethodStyles.container}>
      {isInitialLoad ? (
        <Typography data-testid="bankID-initial-text" sx={loginMethodStyles.description}>
          {t('login.BankIDInitialText')}
        </Typography>
      ) : (
        <Typography data-testid="bankID-initial-text" sx={loginMethodStyles.description}>
          {t('login.BankIDLoginText')}
        </Typography>
      )}
      <Grid sx={styles.loginByBankIDContainer}>
        <Button data-testid="login-bankID-button" variant="contained" onClick={clickOnLoginByBankIDUrl}>
          {t('login.loginByBankIDButton')}
        </Button>
      </Grid>
      <Button
        data-testid="login-by-mail-button"
        variant="text"
        sx={loginMethodStyles.changeLoginMethodLink}
        onClick={changeTypeOfLoginToEmail}
      >
        {t('login.loginByEmailButton')}
      </Button>
    </Grid>
  );
};
