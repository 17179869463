import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const DELETE_COUPLER = gql`
  mutation deleteCoupler($input: DeleteNodeInput!) {
    deleteCoupler(input: $input)
  }
`;

export const deleteCoupler = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_COUPLER, { input: { propertyId, nodeId } });
};
