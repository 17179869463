import { Styles } from '../../theme/materialTheme';

export const container: Styles = {
  px: { xs: 0, sm: 3 },
};

export const infoLinkValue: Styles = {
  gap: 1.5,
  textDecoration: 'underline',
  fontWeight: 400,
  alignSelf: 'start',
  wordBreak: 'break-all',
};

export const infoContainer: Styles = {
  alignItems: 'start',
  flexDirection: 'column',
};

export const infoLabel: Styles = (theme) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
  textTransform: 'uppercase',
});

export const infoSection: Styles = (theme) => ({
  mt: 1.5,
  pb: 2.5,
  borderBottom: `1px solid ${theme.palette.line.main}`,
  flexDirection: 'column',
});

export const loader: Styles = {
  mt: 2,
};

export const warningContainer: Styles = {
  mt: 15,
  flexDirection: 'column',
  alignItems: 'center',
};

export const warningInfo: Styles = (theme) => ({
  mt: 4,
  color: theme.palette.text.secondary,
  fontSize: 24,
  lineHeight: 1.3,
  textAlign: 'center',
  mx: 2.5,
  fontWeight: 400,
});
