import { useQuery } from 'react-query';

import { G } from '@mobily/ts-belt';

import { requestGQL } from '../../../common/graphQlClient';
import { isTestEnv, oneHourInMs } from '../../../config';
import { GET_PREMISES, GetPremisesResponse } from '../api';
import { parseGetPremisesResponse, PremiseOnPremisesList } from '../Premise';

export const usePremisesList = (propertyId: number | null) => {
  return useQuery<GetPremisesResponse, unknown, null | ReadonlyArray<PremiseOnPremisesList>>(
    ['allPremises', propertyId],
    () => requestGQL(GET_PREMISES, { propertyId }),
    {
      refetchOnWindowFocus: false,
      retry: isTestEnv ? 0 : 3,
      staleTime: oneHourInMs,
      enabled: G.isNotNullable(propertyId),
      select: (data) => parseGetPremisesResponse(data),
    },
  );
};
