import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_SPLITTER = gql`
  mutation createSplitter($input: SplitterInput!) {
    createSplitter(input: $input)
  }
`;

export const createSplitter = (propertyId: number) => (splitterDetails: any) => {
  return requestGQL(CREATE_SPLITTER, { input: { propertyId, splitterDetails } });
};
