import create from 'zustand';

import { getNavigatorLanguage, Language } from './Language';

type InternationalizationState = {
  language: Language;
  changeLanguage: (language: Language) => void;
};

export const useInternationalizationState = create<InternationalizationState>()((set) => ({
  language: getNavigatorLanguage(),
  changeLanguage: (language) => set({ language }),
}));

export const internationalizationState = () => useInternationalizationState.getState();
