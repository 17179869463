import { useAuthenticationState } from 'app/authentication/state';
import { AddFiber } from 'app/fibers/components/AddFiber';
import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { useTranslation } from 'common/hooks/useTranslation';
import * as commonStyles from 'common/styles';
import { Lambda } from 'common/types';
import { FC, useCallback, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { G } from '@mobily/ts-belt';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper, Typography } from '@mui/material';
import { AvailableOutletTypes } from 'app/outlets/AvailiableOutletTypes';
import { EditFiberDialog } from 'app/fibers/components/components/EditFiberDialog';
import { EditTPForm } from 'app/fibers/components/components/EditTPForm';
import { TwistedPair, TPOutlet } from '../../../TPOutlet';
import { HowToFindTPOutlet } from '../../HowToFindTPOutlet/HowToFindTPOutlet';
import { OutletMenu } from '../../OutletMenu/OutletMenu';
import { OutletPhotos } from '../../OutletPhotos/OutletPhotos';
import { DeleteFiberDialog } from './DeleteFiberDialog';
import { FiberTPOutletDetails } from './FiberTPOutletDetails';
import * as styles from './styles';
import { DeleteTPOutletFiber } from './DeleteTpOutletFiber';

export const SinglePremiseTPOutlet: FC<{
  outlet: TPOutlet;
  premise: PremiseOnSinglePremise;
  onDelete?: Lambda<void, void>;
}> = ({ outlet, premise, onDelete }) => {
  const { t } = useTranslation();

  const { value: isDeleteFiberOpen, setValue: setIsDeleteFiberOpen } = useBoolean(false);
  const { value: isEditFiberOpen, setValue: setIsEditFiberOpen } = useBoolean(false);
  const [fiberToDelete, setFiberToDelete] = useState<null | TwistedPair>(null);
  const [fiberToEdit, setFiberToEdit] = useState<null | TwistedPair>(null);
  const { userId, roles } = useAuthenticationState();

  const canUserDeleteFiber = useCallback(
    (fiber: TwistedPair) => {
      if (roles.includes('cf_admin') || roles.includes('cf_contractor_pm')) return true;

      const fiberCreatorId = fiber.personId;

      if (fiberCreatorId !== null && userId === fiberCreatorId) return true;

      return false;
    },
    [userId, roles],
  );

  return (
    <>
      <DeleteFiberDialog
        isOpen={G.isNotNullable(fiberToDelete) && isDeleteFiberOpen}
        onClose={() => {
          setFiberToDelete(null);
          setIsDeleteFiberOpen(false);
        }}
      >
        {G.isNotNullable(fiberToDelete) && (
          <DeleteTPOutletFiber
            outletId={outlet.id}
            propertyId={premise.propertyId}
            fiber={fiberToDelete}
            onCancel={() => {
              setFiberToDelete(null);
              setIsDeleteFiberOpen(false);
            }}
            onSuccess={() => {
              setFiberToDelete(null);
              setIsDeleteFiberOpen(false);
            }}
          />
        )}
      </DeleteFiberDialog>
      <EditFiberDialog
        isOpen={isEditFiberOpen}
        onClose={() => {
          setIsEditFiberOpen(false);
          setFiberToEdit(null);
        }}
        outletType={AvailableOutletTypes.TPOUTLET}
      >
        {fiberToEdit && (
          <EditTPForm
            fiber={fiberToEdit}
            outletId={outlet.id}
            propertyId={premise.propertyId}
            onSuccess={() => {
              setIsEditFiberOpen(false);
              setFiberToEdit(null);
            }}
          />
        )}
      </EditFiberDialog>
      <Grid container sx={styles.outlet}>
        <Paper sx={commonStyles.card}>
          <Grid container sx={styles.cardTop}>
            <Typography sx={styles.photosHeader}>{t('premise.photos')}</Typography>
            <OutletMenu
              outlet={outlet}
              premise={premise}
              onDelete={onDelete}
              outletType={AvailableOutletTypes.TPOUTLET}
            />
          </Grid>
          <OutletPhotos outlet={outlet} premise={premise} outletType={AvailableOutletTypes.TPOUTLET} />
          <HowToFindTPOutlet outlet={outlet} premise={premise} />
          <Grid container sx={styles.fibersContainer}>
            {outlet.fibers.map((tp) => {
              return (
                <Accordion disableGutters square key={tp.id} data-cy={`fiber-accordion-${tp.to.portId}`}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon titleAccess="accordion-expand" />}
                    data-cy="fiber-accordion-header"
                  >
                    {tp.to.portId}
                  </AccordionSummary>
                  <AccordionDetails data-cy="fiber-accordion-details">
                    <FiberTPOutletDetails fiber={tp} />
                    <Grid sx={styles.actionFiberContainer}>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => {
                          setFiberToEdit(tp);
                          setIsEditFiberOpen(true);
                        }}
                      >
                        <EditIcon /> {t('tp.edit.button')}
                      </Button>
                      {canUserDeleteFiber(tp) && (
                        <Button
                          variant="text"
                          color="error"
                          onClick={() => {
                            setFiberToDelete(tp);
                            setIsDeleteFiberOpen(true);
                          }}
                        >
                          <DeleteIcon /> {t('tp.delete.button')}
                        </Button>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Grid>
          <Grid container sx={styles.centerContainer}>
            <AddFiber premise={premise} outlet={outlet} outletType={AvailableOutletTypes.TPOUTLET} />
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};
