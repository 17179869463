import { LoginPage } from 'app/authentication/components/LoginPage';
import { Entrance } from 'app/authentication/Entrance';
import { Logout } from 'app/authentication/Logout';
import { useAuthenticationState } from 'app/authentication/state';
import { PremisesList } from 'app/premises/components/PremisesList/PremisesList';
import { SinglePremise } from 'app/premises/components/SinglePremise/SinglePremise';
import { SingleRadioPremise } from 'app/premises/components/SinglePremise/SingleRadioPremise';
import { PropertiesList } from 'app/properties/components/PropertiesList';
import { PropertyInformations } from 'app/properties/components/PropertyInformations';
import { SingleProperty } from 'app/properties/components/SingleProperty';
import { SingleRadioProperty } from 'app/properties/components/SingleRadioProperty';
import { AppTopbar } from 'app/topbar/components/AppTopbar';
import { FC, useCallback } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { G } from '@mobily/ts-belt';
import { Container } from '@mui/material';
import { Areas } from '../../areas/Areas';

export const AppRoutes: FC = () => {
  const { token } = useAuthenticationState();

  const RootPageWrapper = useCallback(() => {
    if (G.isNullable(token)) return <Navigate replace to="/login" />;

    return (
      <>
        <AppTopbar />
        <Outlet />
      </>
    );
  }, [token]);

  return (
    // TODO: test guards redirections and navbar conditional rendering
    <Container maxWidth="sm">
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route path="/entrance" element={<Entrance />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<RootPageWrapper />}>
          <Route path="/" element={<PropertiesList />} />
          <Route path="/property/:id" element={<SingleProperty />}>
            <Route path="areas" element={<Areas />} />
            <Route path="premises" element={<PremisesList />} />
            <Route index element={<PropertyInformations />} />
          </Route>
          <Route path="/premise/:id" element={<SinglePremise />} />
          <Route path="/radio/property/:id" element={<SingleRadioProperty />}>
            <Route path="areas" element={<Areas isRadio />} />
            <Route path="premises" element={<PremisesList isRadio />} />
            <Route index element={<PropertyInformations />} />
          </Route>
          <Route path="/radio/premise/:id" element={<SingleRadioPremise />} />
        </Route>
      </Routes>
    </Container>
  );
};
