import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_INPUT_ANTENNA = gql`
  mutation patchInputAntenna($input: InputAntennaInput!) {
    patchInputAntenna(input: $input)
  }
`;

export const patchInputAntenna = (propertyId: number) => (inputAntennaDetails: any) => {
  return requestGQL(PATCH_INPUT_ANTENNA, { input: { propertyId, inputAntennaDetails } });
};
