import { requestGQL } from 'common/graphQlClient';
import { isTestEnv, oneHourInMs } from 'config';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  GET_PROPERTY_FIBER_CHECKLIST,
  GetPropertyFiberChecklistResponse,
  PropertyFiberChecklist,
  CREATE_FIBER_PROPERTY_CHECKLIST,
  UPDATE_FIBER_PROPERTY_CHECKLIST_BY_PROPERTY_ID,
} from '../api';

export const useFiberChecklist = (propertyId: number | null) => {
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery<GetPropertyFiberChecklistResponse | null>(
    ['propertyFiberChecklist', propertyId],
    () => requestGQL(GET_PROPERTY_FIBER_CHECKLIST, { propertyId }),
    {
      refetchOnWindowFocus: false,
      retry: isTestEnv ? 0 : 3,
      staleTime: oneHourInMs,
    },
  );

  const {
    mutate: updatePropertyFiberChecklist,
    isLoading: isLoadingUpdatePropertyFiberChecklist,
    isError: isErrorUpdatePropertyFiberChecklist,
    isSuccess: isSuccessUpdatePropertyFiberChecklist,
  } = useMutation<unknown, unknown, Partial<PropertyFiberChecklist>>(
    (fiberChecklistPatch: Partial<PropertyFiberChecklist>) =>
      requestGQL(UPDATE_FIBER_PROPERTY_CHECKLIST_BY_PROPERTY_ID, { propertyId, fiberChecklistPatch }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['propertyById', propertyId]);
      },
    },
  );

  const {
    mutate: createPropertyFiberChecklist,
    isLoading: isLoadingCreatePropertyFiberChecklist,
    isError: isErrorCreatePropertyFiberChecklist,
    isSuccess: isSuccessCreatePropertyFiberChecklist,
  } = useMutation<unknown, unknown, Partial<PropertyFiberChecklist>>(
    (checklist: Partial<PropertyFiberChecklist>) =>
      requestGQL(CREATE_FIBER_PROPERTY_CHECKLIST, { propertyId, checklist }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['propertyById', propertyId]);
      },
    },
  );

  return {
    data,
    isLoading,
    isError,
    updatePropertyFiberChecklist,
    isLoadingUpdatePropertyFiberChecklist,
    isErrorUpdatePropertyFiberChecklist,
    isSuccessUpdatePropertyFiberChecklist,
    createPropertyFiberChecklist,
    isLoadingCreatePropertyFiberChecklist,
    isErrorCreatePropertyFiberChecklist,
    isSuccessCreatePropertyFiberChecklist,
  };
};
