import { requestGQL } from 'common/graphQlClient';
import { useMutation, useQueryClient } from 'react-query';

import { MutatePatchFiber, PATCH_FIBER } from '../api';
import { Fiber, Outlet } from '../Outlet';

export const parseFiberToRequest =
  (outletId: Outlet['id']) =>
  (fiber: Fiber) =>
  (fiberForm: Partial<Fiber>): MutatePatchFiber => {
    return {
      id: fiber.id,
      odfId: fiber.odfId ?? '',
      odfPort: fiber.fiber ?? '',
      outletId,
      outletPort: fiber.toPort ?? '',
      ...(fiberForm.duct ? { duct: fiberForm.duct } : {}),
      ...(fiberForm.length ? { length: fiberForm.length } : {}),
      ...(fiberForm.db1310 ? { db1310: fiberForm.db1310 } : {}),
      ...(fiberForm.db1550 ? { db1550: fiberForm.db1550 } : {}),
    };
  };

export const usePatchFiber = (outletId: string, propertyId: number, fiber: Fiber) => {
  const queryClient = useQueryClient();
  const { data, mutate, isLoading, isError, isSuccess } = useMutation<string, unknown, { fiberForm: Partial<Fiber> }>(
    async ({ fiberForm }) =>
      requestGQL(PATCH_FIBER, {
        propertyId,
        fiber: parseFiberToRequest(outletId)(fiber)(fiberForm),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['propertyConnection', propertyId]);
      },
    },
  );

  return { data, mutate, isLoading, isError, isSuccess };
};
