import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_COMBINER = gql`
  mutation createCombiner($input: CombinerInput!) {
    createCombiner(input: $input)
  }
`;

export const createCombiner = (propertyId: number) => (combinerDetails: any) => {
  return requestGQL(CREATE_COMBINER, { input: { propertyId, combinerDetails } });
};
