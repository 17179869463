import { requestGQL } from 'common/graphQlClient';
import { useMutation } from 'react-query';

import { Coax, MutatePatchCoax, PATCH_COAX } from '../api';
import { Outlet } from '../Outlet';

export const parseTwistedPairToRequest =
  (outletId: Outlet['id']) =>
  (coax: Coax) =>
  (fiberForm: Partial<Coax>): MutatePatchCoax => {
    return {
      id: coax.id,
      class: fiberForm.class ?? coax.class ?? '',
      length: fiberForm.length ?? coax.length ?? 0,
      from: { portId: coax.from.portId, nodeId: coax.from.nodeId },
      to: { portId: coax.to.portId, nodeId: outletId },
    };
  };

export const usePatchCoax = (outletId: string, propertyId: number, coax: Coax) => {
  const { data, mutate, isLoading, isError, isSuccess } = useMutation<string, unknown, { fiberForm: Partial<Coax> }>(
    async ({ fiberForm }) =>
      requestGQL(PATCH_COAX, {
        propertyId,
        coaxial: parseTwistedPairToRequest(outletId)(coax)(fiberForm as Partial<Coax>),
      }),
  );

  return { data, mutate, isLoading, isError, isSuccess };
};
