import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';

import { postLogin } from '../api';
import { useLoginPageContext } from '../contexts/LoginPageContext';
import { useIdbToken } from '../hooks/useIdbToken';
import { LoginFormSchema, loginFormSchema } from '../loginFormSchema';
import { useAuthenticationState } from '../state';
import * as styles from './LoginByEmail.styles';
import * as loginMethodStyles from './loginMethod.styles';

export const LoginByEmail: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(loginFormSchema),
  });
  const { t } = useTranslation();

  const { setTypeOfLogin } = useLoginPageContext();

  const getErrorMessage = useErrorMessage(errors);

  const { changeToken } = useAuthenticationState();
  const { set: setIdbToken } = useIdbToken();

  const navigate = useNavigate();

  const {
    mutate: login,
    isLoading: isLoadingToken,
    error: isErrorToken,
    data: token,
    isSuccess: isSuccessToken,
  } = useMutation<string, string, LoginFormSchema>((credentials) => postLogin(credentials));

  useEffect(() => {
    if (isErrorToken) {
      toast.error(t('login.authentication.error'));
    }
  }, [isErrorToken]);

  useEffect(() => {
    if (isSuccessToken && token) {
      changeToken(token);
      setIdbToken(token);
      navigate('/');
      toast.success(t('login.authentication.success'));
    }
  }, [token, isSuccessToken]);

  const handleSubmitCallback = (values: LoginFormSchema) => {
    login(values);
  };

  return (
    <Grid container component="form" sx={loginMethodStyles.container} onSubmit={handleSubmit(handleSubmitCallback)}>
      <Typography sx={loginMethodStyles.description}>{t('login.EmailInitialText')}</Typography>

      {/* TODO: use FormControl and InputLabel */}
      <TextField
        sx={styles.emailInput}
        fullWidth
        label={t('login.emailInput.label')}
        placeholder={t('login.emailInput.placeholder')}
        variant="outlined"
        helperText={getErrorMessage('email')}
        disabled={isLoadingToken}
        {...register('email')}
      />
      <TextField
        sx={styles.passwordInput}
        fullWidth
        label={t('login.passwordInput.label')}
        placeholder={t('login.passwordInput.placeholder')}
        variant="outlined"
        helperText={getErrorMessage('password')}
        disabled={isLoadingToken}
        type="password"
        {...register('password')}
      />
      <Button
        variant="text"
        sx={loginMethodStyles.changeLoginMethodLink}
        onClick={() => setTypeOfLogin('bankid')}
        disabled={isLoadingToken}
      >
        {t('login.loginByBankIDButton')}
      </Button>
      {isLoadingToken && <CircularProgress sx={loginMethodStyles.submitActionContainer} />}
      {!isLoadingToken && (
        <Button sx={loginMethodStyles.submitActionContainer} type="submit" variant="contained">
          {t('login.loginButton')}
        </Button>
      )}
    </Grid>
  );
};
