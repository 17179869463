export enum RadioNodeOptions {
  BASE_STATION = 'Base Station',
  RADIO_UNIT = 'Radio Unit',
  INPUT_ANTENNA = 'Input Antenna',
  DROP_ANTENNA = 'Drop Antenna',
  COMBINER = 'Combiner',
  COUPLER = 'Coupler',
  SPLITTER = 'Splitter',
  REPEATER = 'Repeater',
}
