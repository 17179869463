import { Styles } from '../../../app/theme/materialTheme';

export const container: Styles = {
  mt: 1.5,
  alignItems: 'start',
  flexDirection: 'column',
};

export const label: Styles = (theme) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
  textTransform: 'uppercase',
});

export const value: Styles = {
  fontSize: 18,
  fontWeight: 400,
};

export const linkValue: Styles = (theme) => ({
  textDecoration: 'underline',
  color: theme.palette.primary.main,
});
