import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { useTranslation } from 'common/hooks/useTranslation';
import { publicUrl } from 'config';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { DropAntennas } from 'app/outlets/DropAntenna';

import { useCreatePremiseDropAntenna } from 'app/outlets/hooks/useCreatePremiseDropAntenna';
import { SinglePremiseDropAntenna } from './components/SinglePremiseDropAntenna';
import * as styles from './PremiseDropAntennas.styles';

export const PremiseDropAntennas: FC<{ dropAntennas: DropAntennas; premise: PremiseOnSinglePremise }> = ({
  dropAntennas,
  premise,
}) => {
  const { t } = useTranslation();
  const [currentOutletIndex, setCurrentOutletIndex] = useState<null | number>(dropAntennas.length === 0 ? null : 0);

  const { mutate, isSuccess, error, isLoading } = useCreatePremiseDropAntenna(premise.propertyId, premise.id);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('general.updatedSuccessfully'));
      if (currentOutletIndex === null) {
        setCurrentOutletIndex(0);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      toast.error(t('premise.outlet.add.error'));
    }
  }, [error]);

  return (
    <Grid container>
      <Grid container sx={styles.outletsList}>
        {dropAntennas.length > 0 && (
          <Grid container sx={styles.outlets}>
            {dropAntennas.map((outlet, i) => (
              <Button
                key={outlet.id}
                sx={styles.outletButton}
                variant={currentOutletIndex === i ? 'contained' : 'outlined'}
                onClick={() => {
                  setCurrentOutletIndex(i);
                }}
                data-testid={`outlet-button-${i}`}
              >
                {`${t('premise.dropAntenna')} ${i + 1}`}
              </Button>
            ))}
            <IconButton sx={styles.addOutletButton} onClick={() => mutate()}>
              <AddIcon titleAccess="add-drop-antenna" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {dropAntennas.length === 0 && (
        <Grid container sx={styles.warningContainer}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Box component="img" src={`${publicUrl}/img/icons/warning.svg`} alt="warning" />
              <Typography sx={styles.warningInfo}>{t('premise.noDropAntennas')}</Typography>
              <Button sx={styles.addFirstOutlet} variant="contained" onClick={() => mutate()}>
                {t('premise.outlet.addFirstDropAntenna')}
              </Button>
            </>
          )}
        </Grid>
      )}
      <Grid container>
        {dropAntennas.length > 0 &&
          dropAntennas.map(
            (outlet, index) =>
              currentOutletIndex === index && (
                <SinglePremiseDropAntenna
                  key={outlet.id}
                  outlet={outlet}
                  premise={premise}
                  onDelete={() => setCurrentOutletIndex((value) => (value !== null && value >= 1 ? value - 1 : null))}
                />
              ),
          )}
      </Grid>
    </Grid>
  );
};
