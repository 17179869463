import { getTPOutletById } from 'app/connection/Connection';
import { useConnection } from 'app/connection/hooks/useConnection';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { G } from '@mobily/ts-belt';
import { Button, CircularProgress, Grid } from '@mui/material';
import { useDeleteTwistedPair } from '../../../hooks/useDeleteTwistedPair';
import * as styles from './styles';
import { TwistedPair } from '../../../TPOutlet';

export const DeleteTPOutletFiber: FC<{
  propertyId: number;
  outletId: string;
  fiber: TwistedPair;
  onSuccess?: Lambda<void, void>;
  onCancel?: Lambda<void, void>;
}> = ({ propertyId, outletId, fiber, onSuccess, onCancel }) => {
  const { t } = useTranslation();
  const { data, isLoading: isLoadingConnection } = useConnection(propertyId);
  const {
    mutate,
    isLoading: isLoadingMutateConnection,
    isError: isErrorMutateConnection,
    isSuccess: isSuccessMutateConnection,
  } = useDeleteTwistedPair(propertyId);

  useEffect(() => {
    if (isErrorMutateConnection) {
      toast.error(t('error.generalMessage'));
    }
  }, [isErrorMutateConnection]);

  useEffect(() => {
    if (isSuccessMutateConnection) {
      toast.success(t('general.updatedSuccessfully'));

      if (G.isNotNullable(onSuccess)) {
        onSuccess();
      }
    }
  }, [isSuccessMutateConnection]);

  const deleteFiber = useCallback(() => {
    if (G.isNotNullable(data)) {
      const pickedOutlet = getTPOutletById(data.serialized2, outletId);

      if (G.isNotNullable(pickedOutlet)) {
        mutate({ fiberId: fiber.id });
      }
    }
  }, [data, fiber, outletId]);

  const isLoading = useMemo(() => {
    return isLoadingConnection || isLoadingMutateConnection;
  }, [isLoadingConnection, isLoadingMutateConnection]);

  return (
    <Grid container>
      {isLoading && (
        <Grid container sx={styles.loaderContainer}>
          <CircularProgress />
        </Grid>
      )}
      {!isLoading && (
        <Grid container>
          <Grid container sx={styles.deleteFibersButtonsContainer}>
            <Button
              variant="contained"
              onClick={() => G.isNotNullable(onCancel) && onCancel()}
              data-testid="delete-fiber-cancel"
            >
              {t('general.cancel')}
            </Button>
            <Button variant="outlined" onClick={deleteFiber} data-testid="delete-fiber-accept">
              {t('general.accept')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
