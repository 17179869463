import { FC, PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';

import { mapLang } from '../mapLang';
import { useInternationalizationState } from '../state';

export const InternationalizationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { language } = useInternationalizationState();

  return (
    <IntlProvider locale={language} messages={mapLang[language]}>
      {children}
    </IntlProvider>
  );
};
