import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const DELETE_SPLITTER = gql`
  mutation deleteSplitter($input: DeleteNodeInput!) {
    deleteSplitter(input: $input)
  }
`;

export const deleteSplitter = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_SPLITTER, { input: { propertyId, nodeId } });
};
