import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_COMBINER = gql`
  mutation patchCombiner($input: CombinerInput!) {
    patchCombiner(input: $input)
  }
`;

export const patchCombiner = (propertyId: number) => (combinerDetails: any) => {
  return requestGQL(PATCH_COMBINER, { input: { propertyId, combinerDetails } });
};
