import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_TECHNICAL_ODF = gql`
  mutation patchTechnicalODF($input: ODFInput!) {
    patchTechnicalODF(input: $input)
  }
`;

export const patchTechnicalODF = (propertyId: number) => (odfDetails: any) => {
  return requestGQL(PATCH_TECHNICAL_ODF, { input: { propertyId, odfDetails } });
};
