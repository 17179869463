import 'react-toastify/dist/ReactToastify.min.css';

import { FC, PropsWithChildren } from 'react';
import { ToastContainer } from 'react-toastify';

export const ToastProvider: FC<PropsWithChildren> = ({ children }) => (
  <>
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    {children}
  </>
);
