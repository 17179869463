import { Styles } from 'app/theme/materialTheme';

export const container: Styles = {
  px: 3,
};

export const infoSection: Styles = (theme) => ({
  mt: 1.5,
  pb: 2.5,
  borderBottom: `1px solid ${theme.palette.line.main}`,
});
