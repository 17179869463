import { mergeStyles } from 'app/theme/materialTheme';
import { useBreakpoint } from 'common/hooks/useMediaQuery';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { publicUrl } from 'config';
import { FC, PropsWithChildren } from 'react';

import { Box, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';

import { OutletTypes } from 'app/outlets/components/OutletMenu/OutletMenu';
import { AvailableOutletTypes } from 'app/outlets/AvailiableOutletTypes';
import * as styles from '../../../outlets/components/PremiseOutlets/components/styles';

export const EditFiberDialog: FC<
  PropsWithChildren<{ isOpen: boolean; onClose: Lambda<void, void>; outletType: OutletTypes }>
> = ({ isOpen, onClose, children, outletType }) => {
  const { t } = useTranslation();
  const { isTabletUp } = useBreakpoint();

  return (
    <Dialog open={isOpen} PaperProps={{ sx: styles.dialog }} fullScreen={!isTabletUp}>
      <Toolbar sx={mergeStyles([styles.dialogTopbar, isTabletUp ? {} : styles.smallDialogTopbar])}>
        <Typography>
          {outletType === AvailableOutletTypes.OUTLET ? t('fiber.edit.dialog.header') : t('tp.edit.dialog.header')}
        </Typography>
        <IconButton size={isTabletUp ? 'large' : 'small'} color="inherit" onClick={() => onClose()}>
          <Box component="img" src={`${publicUrl}/img/icons/x.svg`} alt="add" />
        </IconButton>
      </Toolbar>

      <DialogContent sx={styles.dialogContent}>{children}</DialogContent>
    </Dialog>
  );
};
