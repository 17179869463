import { A, O, pipe } from '@mobily/ts-belt';

import {
  GetPropertyConnectionResponse,
  GetPropertyRadioConnectionResponse,
  NodeData,
  NodesData,
} from 'app/connection/api';
import { PreviewItem } from './types/PreviewItem';

const getPreviewItemFromNode = (node: NodeData): PreviewItem => ({
  id: node.id,
  location: O.fromNullable(node.cfObject?.location),
  photosUrls: node.cfObject?.images ?? [],
  text: O.fromNullable(node.text),
});

const getPreviewItemsFor = (model: string) => (nodesData: NodesData) =>
  pipe(
    nodesData,
    A.filter((node) => node.model === model && !node.group),
    // filter out all nodes that are inside premise (group)
    A.map(getPreviewItemFromNode),
  );

export const getAreas = (response: GetPropertyConnectionResponse) => {
  const nodes = response.propertyById.connectionByConnectionId?.serialized2.nodeDataArray ?? [];

  const incomingFibers = getPreviewItemsFor('bundle')(nodes);
  const switches = getPreviewItemsFor('switch')(nodes);
  const technicalSwitches = getPreviewItemsFor('technicalSwitch')(nodes);
  const trunks = getPreviewItemsFor('trunk')(nodes);
  const ODFs = getPreviewItemsFor('odf')(nodes);
  const technicalODFs = getPreviewItemsFor('technicalOdf')(nodes);
  const wifiDots = getPreviewItemsFor('wifiDot')(nodes);
  const tpOutlets = getPreviewItemsFor('tpOutlet')(nodes);
  const crossPanels = getPreviewItemsFor('crossPanel')(nodes);
  const technicalEquipments = getPreviewItemsFor('technicalEquipment')(nodes);
  const fireBreaches = getPreviewItemsFor('fireBreach')(nodes);
  const racks = getPreviewItemsFor('rack')(nodes);
  const cableRoutes = getPreviewItemsFor('cableRoute')(nodes);

  return {
    incomingFibers,
    switches,
    technicalSwitches,
    trunks,
    ODFs,
    technicalODFs,
    wifiDots,
    tpOutlets,
    crossPanels,
    technicalEquipments,
    fireBreaches,
    racks,
    cableRoutes,
  };
};

export const getAreasRadio = (response: GetPropertyRadioConnectionResponse) => {
  const nodes = response.propertyById.radioConnectionByRadioConnectionId?.serialized2.nodeDataArray ?? [];

  const baseStations = getPreviewItemsFor('baseStation')(nodes);
  const radioUnits = getPreviewItemsFor('radioUnit')(nodes);
  const inputAntennas = getPreviewItemsFor('inputAntenna')(nodes);
  const dropAntennas = getPreviewItemsFor('dropAntenna')(nodes);
  const combiners = getPreviewItemsFor('combiner')(nodes);
  const couplers = getPreviewItemsFor('coupler')(nodes);
  const splitters = getPreviewItemsFor('splitter')(nodes);
  const repeaters = getPreviewItemsFor('repeater')(nodes);

  return {
    baseStations,
    radioUnits,
    inputAntennas,
    dropAntennas,
    combiners,
    couplers,
    splitters,
    repeaters,
  };
};
