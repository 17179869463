import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_SWITCH = gql`
  mutation createSwitch($input: SwitchInput!) {
    createSwitch(input: $input)
  }
`;

export const createSwitch = (propertyId: number) => (switchDetails: any) => {
  return requestGQL(CREATE_SWITCH, { input: { propertyId, switchDetails } });
};
