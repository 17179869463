import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_CABLE_ROUTE = gql`
  mutation patchCableRoute($input: BasicObjectInput!) {
    patchCableRoute(input: $input)
  }
`;

export const patchCableRoute = (propertyId: number) => (basicObjectDetails: any) => {
  return requestGQL(PATCH_CABLE_ROUTE, { input: { propertyId, basicObjectDetails } });
};
