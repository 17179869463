import { GetPropertyConnectionResponse } from 'app/connection/api';
import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const GET_CONNECTION = gql`
  query propertyConnection($propertyId: Int!) {
    propertyById(id: $propertyId) {
      connectionByConnectionId {
        id
        serialized2
      }
    }
  }
`;

export const getPropertyConnections = (propertyId: number) => {
  return requestGQL<GetPropertyConnectionResponse>(GET_CONNECTION, { propertyId });
};
