import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_CROSS_CONNECTION_PANEL = gql`
  mutation patchCrossConnectionPanel($input: CrossConnectionPanelInput!) {
    patchCrossConnectionPanel(input: $input)
  }
`;

export const patchCrossConnectionPanel = (propertyId: number) => (crossConnectionPanelDetails: any) => {
  return requestGQL(PATCH_CROSS_CONNECTION_PANEL, { input: { propertyId, crossConnectionPanelDetails } });
};
