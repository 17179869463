import { z } from 'zod';

export const editFiberSchema = z.object({
  duct: z.string(),
  length: z.number().min(0).multipleOf(0.01),
  db1310: z.number().min(0).multipleOf(0.01),
  db1550: z.number().min(0).multipleOf(0.01),
});

export type EditFiberSchema = z.infer<typeof editFiberSchema>;
