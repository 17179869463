import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_REPEATER = gql`
  mutation patchRepeater($input: RepeaterInput!) {
    patchRepeater(input: $input)
  }
`;

export const patchRepeater = (propertyId: number) => (repeaterDetails: any) => {
  return requestGQL(PATCH_REPEATER, { input: { propertyId, repeaterDetails } });
};
