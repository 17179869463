import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_CROSS_CONNECTION_PANEL = gql`
  mutation createCrossConnectionPanel($input: CrossConnectionPanelInput!) {
    createCrossConnectionPanel(input: $input)
  }
`;

export const createCrossConnectionPanel = (propertyId: number) => (crossConnectionPanelDetails: any) => {
  return requestGQL(CREATE_CROSS_CONNECTION_PANEL, { input: { propertyId, crossConnectionPanelDetails } });
};
