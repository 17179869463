import { zodResolver } from '@hookform/resolvers/zod';
import { O } from '@mobily/ts-belt';
import { Button, CircularProgress, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ODFContactTypes } from 'app/areas/ODFContactTypes';
import { SwitchTypes } from 'app/areas/SwitchTypes';
import { useDeleteSwitch } from '../../hooks/useDelete';
import { usePatchSwitch } from '../../hooks/usePatch';
import { SwitchDetails } from '../../types/ObjectDetails';
import { switchSchema, SwitchSchema } from '../../types/SwitchSchema';
import { PhotosField } from '../../ui/PhotosField';
import { EnlargedPhotoDialog } from '../EnlargedPhotoDialog';

type Props = {
  details: SwitchDetails;
  onSave: Lambda<void, void>;
  onDelete?: Lambda<void, void>;
};

export const Switch: FC<Props> = ({ details, onDelete, onSave }) => {
  const { t } = useTranslation();
  const {
    connectionsCount,
    location,
    model,
    brand,
    installedAt,
    incomingIP,
    propertyOf,
    text,
    id,
    contactType,
    switchType,
  } = details;
  const [pickedImage, setPickedImage] = useState<O.Option<string>>(O.None);
  const { mutate: patchSwitch, isLoading: isLoadingPatchSwitch } = usePatchSwitch({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });
  const { mutate: deleteSwitch, isLoading: isLoadingDeleteSwitch } = useDeleteSwitch({
    onSuccess: () => {
      if (onDelete) onDelete();
      toast.success(t('general.deletedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<SwitchSchema>({
    defaultValues: {
      text: O.getWithDefault(text, ''),
      connectionsCount: O.getWithDefault(connectionsCount, 0),
      location: O.getWithDefault(location, ''),
      model: O.getWithDefault(model, ''),
      brand: O.getWithDefault(brand, ''),
      propertyOf: O.getWithDefault(propertyOf, ''),
      installedAt: O.getWithDefault(installedAt, new Date().toISOString()),
      incomingIP: O.getWithDefault(incomingIP, ''),
      contactType: O.getWithDefault(contactType, ''),
      switchType: O.getWithDefault(switchType, ''),
    },
    mode: 'onChange',
    resolver: zodResolver(switchSchema),
  });

  const [photosURLs, setPhotosURLs] = useState(details.photosURLs ?? []);

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: SwitchSchema) => {
    patchSwitch({ ...values, id, photosURLs });
  };

  const handleDelete = () => {
    deleteSwitch(details.id);
  };

  return (
    <>
      <EnlargedPhotoDialog
        photoUrl={pickedImage}
        onClose={() => {
          setPickedImage(O.None);
        }}
      />
      <Stack component="form" onSubmit={handleSubmit(handleSave)}>
        <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.switch.label')}</Typography>
        <Stack gap={2} width="100%">
          <PhotosField
            value={photosURLs}
            onChange={setPhotosURLs}
            onItemClick={(photoUrl) => {
              setPickedImage(O.Some(photoUrl));
            }}
          />
          <TextField label={t('areas.fields.name')} {...register('text')} />
          <TextField label={t('areas.fields.location')} {...register('location')} />
          <TextField
            label={t('areas.fields.connectionCount')}
            type="number"
            {...register('connectionsCount', {
              valueAsNumber: true,
            })}
            helperText={getErrorMessage('connectionsCount')}
          />
          <TextField
            {...register('switchType', { required: true })}
            select
            fullWidth
            label={t('areas.switch.switchType')}
            variant="outlined"
            helperText={getErrorMessage('switchType')}
            value={watch('switchType') || ''}
          >
            {Object.values(SwitchTypes).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          <TextField label={t('areas.fields.model')} {...register('model')} />
          <TextField label={t('areas.fields.brand')} {...register('brand')} />
          <TextField label={t('areas.fields.propertyOf')} {...register('propertyOf')} />
          <TextField label={t('areas.fields.installedAt')} {...register('installedAt')} disabled />
          <TextField label={t('areas.fields.incomingIP')} {...register('incomingIP')} />
          <TextField
            {...register('contactType', { required: true })}
            select
            fullWidth
            label={t('areas.odf.contactType')}
            variant="outlined"
            helperText={getErrorMessage('contactType')}
            value={watch('contactType') || ''}
          >
            {Object.values(ODFContactTypes).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Grid container justifyContent="space-between" mt={2}>
          <Button variant="text" color="error" onClick={handleDelete} disabled={isLoadingDeleteSwitch}>
            {isLoadingDeleteSwitch ? <CircularProgress size={16} /> : t('general.delete')}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={!isValid || isLoadingPatchSwitch}>
            {isLoadingPatchSwitch ? <CircularProgress size={16} /> : t('general.save')}
          </Button>
        </Grid>
      </Stack>
    </>
  );
};
