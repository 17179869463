import { Fiber, Outlet } from 'app/outlets/Outlet';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import { useQueryClient } from 'react-query';
import { usePatchFiber } from 'app/outlets/hooks/usePatchFiber';
import { zodResolver } from '@hookform/resolvers/zod';
import { EditFiberSchema, editFiberSchema } from 'app/fibers/editFiberSchema';
import { styles } from '../AddFiber.styles';

export const EditFiberForm: FC<{
  fiber: Fiber;
  outletId: Outlet['id'];
  propertyId: number;
  onSuccess: () => void;
}> = ({ fiber, outletId, propertyId, onSuccess }) => {
  const { t } = useTranslation();

  const { mutate, isLoading, isSuccess, isError } = usePatchFiber(outletId, propertyId, fiber);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditFiberSchema>({
    defaultValues: {
      duct: fiber.duct ?? '',
      length: fiber.length ?? 0,
      db1310: fiber.db1310 ?? 0,
      db1550: fiber.db1550 ?? 0,
    },
    resolver: zodResolver(editFiberSchema),
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isError) toast.error(t('error.generalMessage'));
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('general.updatedSuccessfully'));
      queryClient.invalidateQueries(['outletsByPropertyId', propertyId]);
      onSuccess();
    }
  }, [isSuccess]);

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: EditFiberSchema) => {
    mutate({ fiberForm: values });
  };

  return (
    <Grid container sx={styles.centerContainer}>
      {isLoading && <CircularProgress />}
      {!isLoading && !isError && (
        <Grid container component="form">
          <Grid container columnSpacing={1.5} rowSpacing={4}>
            <Grid item xs={6}>
              <TextField value={fiber.odf} disabled fullWidth label={t('fiber.odf')} variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <TextField value={fiber.fiber} disabled fullWidth label={t('fiber.fiber')} variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register('duct')}
                inputProps={{
                  'data-testid': 'add-fiber-duct',
                }}
                fullWidth
                required
                label={t('fiber.duct')}
                variant="outlined"
                helperText={getErrorMessage('duct')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register('length', { valueAsNumber: true })}
                inputProps={{ type: 'number', step: '0.01', 'data-testid': 'add-fiber-length' }}
                fullWidth
                required
                label={t('fiber.length')}
                variant="outlined"
                helperText={getErrorMessage('length')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register('db1310', { valueAsNumber: true })}
                inputProps={{ type: 'number', step: '0.01', 'data-testid': 'add-fiber-db1310' }}
                fullWidth
                required
                label={t('fiber.db1310')}
                variant="outlined"
                helperText={getErrorMessage('db1310')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register('db1550', { valueAsNumber: true })}
                inputProps={{ type: 'number', step: '0.01', 'data-testid': 'add-fiber-db1550' }}
                fullWidth
                required
                label={t('fiber.db1550')}
                variant="outlined"
                helperText={getErrorMessage('db1550')}
              />
            </Grid>
          </Grid>
          <Grid container sx={styles.addFiberSubmitContainer}>
            <Button onClick={handleSubmit(handleSave)} variant="contained" data-testid="add-fiber-form-save">
              {t('general.save')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
