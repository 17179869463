import { O, pipe } from '@mobily/ts-belt';
import { Lambda } from 'common/types';
import { useMutation, useQueryClient } from 'react-query';
import { usePropertyRadioContext } from 'app/properties/components/SingleRadioProperty';
import { usePremiseRadioContext } from 'app/premises/components/SinglePremise/SingleRadioPremise';
import { usePropertyContext } from '../../properties/components/SingleProperty';
import { deleteBundle } from '../api/deleteBundle';
import { deleteCableRoute } from '../api/deleteCableRoute';
import { deleteFireBreach } from '../api/deleteFireBreach';
import { deleteODF } from '../api/deleteODF';
import { deleteTechnicalODF } from '../api/deleteTechnicalODF';
import { deleteRack } from '../api/deleteRack';
import { deleteSwitch } from '../api/deleteSwitch';
import { deleteTechnicalSwitch } from '../api/deleteTechnicalSwitch';
import { deleteWifiDot } from '../api/deleteWifiDot';
import { deleteTpOutlet } from '../api/deleteTpOutlet';
import { deleteCrossConnectionPanel } from '../api/deleteCrossPanel';
import { deleteTechnicalEquipment } from '../api/deleteTechnicalEquipment';
import { deleteTrunk } from '../api/deleteTrunk';
import { deleteBaseStation } from '../api/deleteBaseStation';
import { deleteDropAntenna } from '../api/deleteDropAntenna';
import { deleteRadioUnit } from '../api/deleteRadioUnit';
import { deleteInputAntenna } from '../api/deleteInputAntenna';
import { deleteCoupler } from '../api/deleteCoupler';
import { deleteCombiner } from '../api/deleteCombiner';
import { deleteRepeater } from '../api/deleteRepeater';
import { deleteSplitter } from '../api/deleteSplitter';

type SuccessErrorCallbacks = {
  onSuccess: Lambda<void, void>;
  onError: Lambda<void, void>;
};

const useDeleteNode = (
  apiCallFn: Lambda<number, Lambda<any, Promise<unknown>>>,
  { onSuccess, onError }: SuccessErrorCallbacks,
) => {
  const { id } = usePropertyContext();
  const { id: radioId } = usePropertyRadioContext();
  const { id: propertyID } = usePremiseRadioContext();
  const queryClient = useQueryClient();

  const currentPropertyId = id || radioId || propertyID;

  return useMutation<unknown, unknown, string>(
    (nodeId) =>
      pipe(
        currentPropertyId,
        O.map((propertyId) => apiCallFn(propertyId)(nodeId)),
        O.getExn,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['propertyConnection', currentPropertyId]);
        queryClient.invalidateQueries(['propertyRadioConnection', currentPropertyId]);
        onSuccess();
      },
      onError: () => {
        onError();
      },
    },
  );
};

export const useDeleteBundle = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteBundle, callbacks);
};

export const useDeleteODF = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteODF, callbacks);
};

export const useDeleteTechnicalODF = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteTechnicalODF, callbacks);
};

export const useDeleteSwitch = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteSwitch, callbacks);
};

export const useDeleteTechnicalSwitch = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteTechnicalSwitch, callbacks);
};

export const useDeleteWifiDot = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteWifiDot, callbacks);
};

export const useDeleteTpOutlet = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteTpOutlet, callbacks);
};

export const useDeleteCrossPanel = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteCrossConnectionPanel, callbacks);
};

export const useDeleteTechnicalEquipment = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteTechnicalEquipment, callbacks);
};

export const useDeleteTrunk = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteTrunk, callbacks);
};

export const useDeleteFireBreach = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteFireBreach, callbacks);
};

export const useDeleteRack = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteRack, callbacks);
};

export const useDeleteCableRoute = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteCableRoute, callbacks);
};

export const useDeleteBaseStation = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteBaseStation, callbacks);
};

export const useDeleteDropAntenna = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteDropAntenna, callbacks);
};

export const useDeleteRadioUnit = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteRadioUnit, callbacks);
};

export const useDeleteInputAntenna = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteInputAntenna, callbacks);
};

export const useDeleteCoupler = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteCoupler, callbacks);
};

export const useDeleteCombiner = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteCombiner, callbacks);
};

export const useDeleteRepeater = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteRepeater, callbacks);
};

export const useDeleteSplitter = (callbacks: SuccessErrorCallbacks) => {
  return useDeleteNode(deleteSplitter, callbacks);
};
