import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { requestGQL } from 'common/graphQlClient';
import { useTranslation } from 'common/hooks/useTranslation';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { FormControl, Grid, MenuItem, TextField } from '@mui/material';

import { ODFContactTypes } from 'app/areas/ODFContactTypes';
import { UPDATE_OUTLET_LOCATION } from '../../api';
import { Outlet } from '../../Outlet';
import { styles } from './OutletContactType.styles';

export const OutletContactType: FC<{
  outlet: Outlet;
  premise: PremiseOnSinglePremise;
}> = ({ outlet, premise }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [outletContactType, setOutletContactType] = useState(outlet.contactType);

  const { mutate, isLoading } = useMutation<string, unknown, { location: string; contactType: string }>(
    async ({ location, contactType }) =>
      requestGQL(UPDATE_OUTLET_LOCATION, {
        propertyId: premise.propertyId,
        outletId: outlet.id,
        location,
        contactType,
      }),
    {
      retry: 0,
      onSuccess: () => {
        toast.success(t('general.updatedSuccessfully'));
        queryClient.invalidateQueries(['outletsByPropertyId', premise.propertyId]);
      },
    },
  );

  useEffect(() => {
    if (outletContactType !== outlet.contactType) {
      mutate({ location: outlet.location ?? '', contactType: outletContactType as string });
    }
  }, [outletContactType]);

  return (
    <Grid container sx={styles.locationContainer}>
      <FormControl fullWidth>
        <TextField
          select
          disabled={isLoading}
          fullWidth
          label={t('areas.odf.contactType')}
          variant="outlined"
          value={outletContactType}
          onChange={(e) => setOutletContactType(e.target.value)}
        >
          {Object.values(ODFContactTypes).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Grid>
  );
};
