import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

import { useIndexedDb } from '../../common/hooks/useIndexedDb';
import { useAuthenticationState } from './state';

export const Logout: FC = () => {
  const { changeToken } = useAuthenticationState();
  const { remove: removeTokenFromIdb } = useIndexedDb<string>('token');

  useEffectOnce(() => {
    removeTokenFromIdb();
    changeToken(null);
  });

  return <Navigate replace to="/" />;
};
