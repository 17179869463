export type Language = 'en' | 'sv';

export const getNavigatorLanguage = (): Language => {
  const navigatorLanguage = navigator.language;

  if (navigatorLanguage.startsWith('sv')) {
    return 'sv';
  }

  return 'en';
};
