import { z } from 'zod';

export const incomingFiberSchema = z.object({
  text: z.string().min(1),
  location: z.string(),
  room: z.string(),
  connectionsCount: z.number().min(0).int(),
  netOwner: z.string(),
  length: z.number().min(0).multipleOf(0.1),
});

export type IncomingFiberSchema = z.infer<typeof incomingFiberSchema>;
