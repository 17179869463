import { z } from 'zod';

export const odfSchema = z.object({
  text: z.string().min(1),
  connectionsCount: z.number().min(0).int(),
  location: z.string().min(1),
  contactType: z.string().min(1),
});

export type ODFSchema = z.infer<typeof odfSchema>;
