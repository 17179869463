import { O, pipe } from '@mobily/ts-belt';
import { Alert, Box, Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateCoupler } from 'app/areas/hooks/useCreate';
import { CouplerSchema, couplerSchema } from 'app/areas/types/CouplerSchema';
import { PhotosField } from 'app/areas/ui/PhotosField';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { CouplerDetails } from 'app/areas/types/ObjectDetails';
import { EnlargedPhotoDialog } from '../EnlargedPhotoDialog';

import { OptionalSelect } from '../../ui/OptionalSelect';
import { useGetPropertyRadioConnections } from '../../hooks/useGetPropertyRadioConnections';
import { getRadioObject } from '../../getRadioObject';
import { getAreasRadio } from '../../getAreas';
import { Coupler } from '../ObjectDetails/Coupler';

export const CreateCoupler: FC<{ onSave: Lambda<void, void> }> = ({ onSave }) => {
  const { status, data } = useGetPropertyRadioConnections();
  const [objectId, setObjectId] = useState<O.Option<string>>(O.None);
  const { t } = useTranslation();

  const [pickedImage, setPickedImage] = useState<O.Option<string>>(O.None);
  const { mutate: createCoupler, isLoading: isLoadingCreateCoupler } = useCreateCoupler({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CouplerSchema>({
    defaultValues: {
      text: '',
      location: '',
      portCount: 4,
      unitType: '',
    },
    mode: 'onChange',
    resolver: zodResolver(couplerSchema),
  });

  const [photosURLs, setPhotosURLs] = useState<CouplerDetails['photosURLs']>([]);

  const getErrorMessage = useErrorMessage(errors);

  if (status === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'error') {
    return <Alert severity="error">{t('areas.properties.error')}</Alert>;
  }

  const { couplers } = getAreasRadio(data);

  const selectedObjectDetails = pipe(
    objectId,
    O.flatMap((id) => getRadioObject(id)(data)),
  );

  const handleSave = (values: CouplerSchema) => {
    createCoupler({ ...values, photosURLs });
  };

  return (
    <Stack>
      {!objectId && (
        <>
          <Box my={2}>
            <EnlargedPhotoDialog
              photoUrl={pickedImage}
              onClose={() => {
                setPickedImage(O.None);
              }}
            />
            <Stack component="form" onSubmit={handleSubmit(handleSave)}>
              <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.coupler.label')}</Typography>
              <Stack gap={2} width="100%">
                <TextField label={t('areas.fields.name')} {...register('text')} helperText={getErrorMessage('text')} />
                <TextField
                  label={t('areas.fields.portCount')}
                  {...register('portCount', {
                    valueAsNumber: true,
                  })}
                  type="number"
                  helperText={getErrorMessage('portCount')}
                />
                <PhotosField
                  value={photosURLs}
                  onChange={setPhotosURLs}
                  onItemClick={(photoUrl) => {
                    setPickedImage(O.Some(photoUrl));
                  }}
                />
                <TextField
                  label={t('areas.fields.location')}
                  {...register('location')}
                  helperText={getErrorMessage('location')}
                />
                <TextField
                  label={t('areas.fields.unitType')}
                  {...register('unitType')}
                  helperText={getErrorMessage('unitType')}
                />
              </Stack>
              <Grid container justifyContent="space-between" mt={2}>
                <Button variant="contained" color="primary" type="submit" disabled={!isValid || isLoadingCreateCoupler}>
                  {isLoadingCreateCoupler ? <CircularProgress size={16} /> : t('general.create')}
                </Button>
              </Grid>
            </Stack>
          </Box>
          <Box mt={2}>
            <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.object.edit')}</Typography>
          </Box>
        </>
      )}
      {couplers.length === 0 ? (
        <Alert severity="error" sx={{ width: 'unset' }}>
          {t('areas.object.noAvailable')}
        </Alert>
      ) : (
        <>
          <OptionalSelect
            options={couplers.map(({ id, text }) => ({ id, label: O.getWithDefault(text, id) }))}
            value={objectId}
            onChange={setObjectId}
          />
          <Box mt={2}>
            {O.match(
              selectedObjectDetails,
              (details) => {
                if (details.type !== 'Coupler') {
                  return null;
                }
                return <Coupler key={details.id} details={details} onSave={onSave} />;
              },
              () => (
                <Alert severity="info" sx={{ width: 'unset' }}>
                  {t('areas.object.noSelected')}
                </Alert>
              ),
            )}
          </Box>
        </>
      )}
    </Stack>
  );
};
