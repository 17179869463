import { Styles } from 'app/theme/materialTheme';

export const infoSection: Styles = (theme) => ({
  mt: 1.5,
  pb: 2.5,
  borderBottom: `1px solid ${theme.palette.line.main}`,
});

export const toggleLabel: Styles = {
  '.MuiFormControlLabel-label': {
    fontSize: 18,
  },
};

export const loader: Styles = {
  position: 'absolute',
  bottom: 12,
  right: 12,
  width: 16,
  height: 16,
  zIndex: 999,
};

export const button: Styles = {
  mt: 2,
};

export const container: Styles = {
  flexDirection: 'column',
  alignItems: 'center',
};
