import { useTranslation } from 'common/hooks/useTranslation';
import { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import * as styles from '../PropertyCard.styles';

export const ConnectionsNumbers: FC<{
  amountOfContracts: number;
  amountOfPremises: number;
  toInstall: number;
  description: string;
}> = ({ description, amountOfContracts, amountOfPremises, toInstall }) => {
  const { t } = useTranslation();

  return (
    <Grid data-cy={`property-statistics-${description}`} container sx={styles.statsContainer}>
      <Grid item xs={4} container sx={styles.singleStatContainer}>
        <Typography sx={[styles.statsNumber, styles.amountOfPremises]}>{amountOfPremises}</Typography>
        <Typography sx={styles.statsDescription}>{t('properties.premises.label')}</Typography>
      </Grid>
      <Grid item xs={4} container sx={styles.singleStatContainer}>
        <Typography sx={[styles.statsNumber, styles.amountOfContracts]}>{amountOfContracts}</Typography>
        <Typography sx={styles.statsDescription}>{t('properties.installed.label')}</Typography>
      </Grid>
      <Grid item xs={4} container sx={styles.singleStatContainer}>
        <Typography sx={[styles.statsNumber, styles.toInstall]}>{toInstall}</Typography>
        <Typography sx={styles.statsDescription}>{t('properties.notInstalled.label')}</Typography>
      </Grid>
    </Grid>
  );
};
