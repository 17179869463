import { mergeStyles } from 'app/theme/materialTheme';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { publicUrl } from 'config';
import { ChangeEvent, FC, useRef } from 'react';
import Resizer from 'react-image-file-resizer';

import { G } from '@mobily/ts-belt';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { styles } from '../OutletPhotos.styles';

export const ImagePicker: FC<{
  onChange: Lambda<File, void>;
  isLoading?: boolean;
}> = ({ onChange, isLoading }) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();

  const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (G.isNotNullable(file)) {
      Resizer.imageFileResizer(
        file,
        800,
        800,
        'JPEG',
        100,
        0,
        (resizedFile) => {
          const fileWithName = new File([resizedFile as File], file.name);
          onChange(fileWithName);
        },
        'file',
      );
    }
  };

  return (
    <>
      <Box sx={styles.hidden} ref={inputRef} component="input" accept="image/*" type="file" onChange={onChangeFile} />
      <Button
        data-cy="add-photo"
        sx={mergeStyles([styles.photo, styles.addPhotoButton])}
        onClick={() => {
          inputRef.current?.click();
        }}
      >
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <>
            <Box component="img" src={`${publicUrl}/img/icons/addPhoto.svg`} alt="add" />
            <Typography sx={styles.addPhotoText}>{t('premise.addPhoto')}</Typography>
          </>
        )}
      </Button>
    </>
  );
};
