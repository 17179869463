import { Styles } from '../../../../theme/materialTheme';

export const outletsList: Styles = {
  flexWrap: 'nowrap',
};

export const outlets: Styles = {
  flexWrap: 'nowrap',
  overflowX: 'scroll',
  gap: 2,
  flex: '0 1 auto',
  alignItems: 'center',
  '::-webkit-scrollbar': {
    display: 'none',
  },
};

export const outletButton: Styles = {
  px: 3.25,
  py: 1.75,
  fontSize: 17,
  flex: '0 0 auto',
};

export const warningContainer: Styles = {
  mt: 15,
  flexDirection: 'column',
  alignItems: 'center',
};

export const warningInfo: Styles = (theme) => ({
  mt: 4,
  color: theme.palette.text.secondary,
  fontSize: 24,
  lineHeight: 1.3,
  textAlign: 'center',
  mx: 2.5,
  fontWeight: 400,
});

export const addOutletButton: Styles = (theme) => ({
  border: '2px solid',
  borderColor: theme.palette.line.dark,
});

export const addFirstOutlet: Styles = {
  mt: 2,
};
