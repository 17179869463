import { mergeStyles } from 'app/theme/materialTheme';
import { useTranslation } from 'common/hooks/useTranslation';
import * as commonStyles from 'common/styles';
import { publicUrl } from 'config';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { G } from '@mobily/ts-belt';
import { Box, Grid, Paper, Typography } from '@mui/material';

import { PremiseOnPremisesList, PremiseType, tagIconSourceMap, tagTranslationMap } from '../../../Premise';
import * as styles from './styles';

export const PremiseCard: FC<{ premise: PremiseOnPremisesList; isRadio?: Boolean }> = ({
  premise,
  isRadio = false,
}) => {
  const { t } = useTranslation();

  const typeTranslationId = useMemo(() => {
    if (G.isNullable(premise.type)) {
      return null;
    }

    const map: Record<PremiseType, string> = {
      office: t('premise.type.office'),
      store: t('premise.type.store'),
      school: t('premise.type.school'),
      clinic: t('premise.type.clinic'),
      restaurant: t('premise.type.restaurant'),
      storage: t('premise.type.storage'),
    };

    return map[premise.type];
  }, [premise.type, t]);

  return (
    <Paper
      sx={mergeStyles([commonStyles.card, styles.card])}
      component={Link}
      to={`${isRadio ? '/radio' : ''}/premise/${premise.id}`}
    >
      <Typography data-cy={`premise-name-${premise.name}`} sx={styles.name}>
        {premise.name}
      </Typography>
      {premise.description && <Typography sx={styles.description}>{premise.description}</Typography>}
      {premise.tenant && premise.tenant !== 'EMPTY' && (
        <Grid data-cy={`premise-tenant-${premise.tenant}`} container sx={styles.tenantContainer}>
          <Typography sx={styles.tenant}>{premise.tenant}</Typography>
          {typeTranslationId && <Typography sx={styles.secondaryText}>{`(${typeTranslationId})`}</Typography>}
        </Grid>
      )}
      {premise.tenant && premise.tenant === 'EMPTY' && (
        <Typography sx={styles.accentText}>{t('premise.empty')}</Typography>
      )}
      {!isRadio && premise.connections.length > 0 && (
        <Grid container sx={styles.connectionsContainer}>
          {premise.connections.map((connection) => (
            <Grid key={connection} sx={styles.connector}>
              <Typography sx={styles.accentText}>{connection}</Typography>
            </Grid>
          ))}
        </Grid>
      )}
      {isRadio && premise.radioConnections.length > 0 && (
        <Grid container sx={styles.connectionsContainer}>
          {premise.radioConnections.map((radioConnection) => (
            <Grid key={radioConnection} sx={styles.connector}>
              <Typography sx={styles.accentText}>{radioConnection}</Typography>
            </Grid>
          ))}
        </Grid>
      )}
      {premise.tags.length > 0 && (
        <Grid container sx={styles.tagsContainer}>
          {premise.tags.map((tag) => {
            return (
              <Grid container key={tag} sx={styles.tag}>
                <Typography sx={styles.tagText}>{t(tagTranslationMap[tag])}</Typography>
                <Box component="img" src={`${publicUrl}/img/icons/${tagIconSourceMap[tag]}`} alt={tag} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Paper>
  );
};
