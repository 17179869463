import { F, O } from '@mobily/ts-belt';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC } from 'react';

export const OptionalSelect: FC<{
  options: ReadonlyArray<{ id: string; label: string }>;
  value: O.Option<string>;
  onChange: Lambda<O.Option<string>, void>;
}> = ({ value, onChange, options }) => {
  const { t } = useTranslation();

  const selectValue = O.match(value, F.identity, F.always(''));

  const handleSelect = ({ target: { value: v } }: SelectChangeEvent<string>) => {
    return v === '' ? onChange(O.None) : onChange(O.Some(v));
  };

  return (
    <Select value={selectValue} onChange={handleSelect}>
      <MenuItem disabled value="">
        {t('areas.object.select.label')}
      </MenuItem>
      {options.map(({ id, label }) => (
        <MenuItem key={id} value={id}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};
