import { z } from 'zod';

export const baseStationSchema = z.object({
  text: z.string().min(1).optional(),
  location: z.string().optional(),
  portCount: z.number().min(0).int().optional(),
  operator: z.string().optional(),
  unitType: z.string().optional(),
  voltage: z.number().min(0).int().optional(),
  other: z.string().optional(),
});

export type BaseStationSchema = z.infer<typeof baseStationSchema>;
