import { O, pipe } from '@mobily/ts-belt';
import { Alert, Box, Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateRepeater } from 'app/areas/hooks/useCreate';
import { RepeaterSchema, repeaterSchema } from 'app/areas/types/RepeaterSchema';
import { PhotosField } from 'app/areas/ui/PhotosField';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { RepeaterDetails } from 'app/areas/types/ObjectDetails';
import { EnlargedPhotoDialog } from '../EnlargedPhotoDialog';

import { OptionalSelect } from '../../ui/OptionalSelect';
import { useGetPropertyRadioConnections } from '../../hooks/useGetPropertyRadioConnections';
import { getRadioObject } from '../../getRadioObject';
import { getAreasRadio } from '../../getAreas';
import { Repeater } from '../ObjectDetails/Repeater';

export const CreateRepeater: FC<{ onSave: Lambda<void, void> }> = ({ onSave }) => {
  const { status, data } = useGetPropertyRadioConnections();
  const [objectId, setObjectId] = useState<O.Option<string>>(O.None);
  const { t } = useTranslation();

  const [pickedImage, setPickedImage] = useState<O.Option<string>>(O.None);
  const { mutate: createRepeater, isLoading: isLoadingCreateRepeater } = useCreateRepeater({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RepeaterSchema>({
    defaultValues: {
      text: '',
      location: '',
      unitType: '',
      serialNumber: '',
    },
    mode: 'onChange',
    resolver: zodResolver(repeaterSchema),
  });

  const [photosURLs, setPhotosURLs] = useState<RepeaterDetails['photosURLs']>([]);

  const getErrorMessage = useErrorMessage(errors);

  if (status === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'error') {
    return <Alert severity="error">{t('areas.properties.error')}</Alert>;
  }

  const { repeaters } = getAreasRadio(data);

  const selectedObjectDetails = pipe(
    objectId,
    O.flatMap((id) => getRadioObject(id)(data)),
  );

  const handleSave = (values: RepeaterSchema) => {
    createRepeater({ ...values, photosURLs });
  };

  return (
    <Stack>
      {!objectId && (
        <>
          <Box my={2}>
            <EnlargedPhotoDialog
              photoUrl={pickedImage}
              onClose={() => {
                setPickedImage(O.None);
              }}
            />
            <Stack component="form" onSubmit={handleSubmit(handleSave)}>
              <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.repeater.label')}</Typography>
              <Stack gap={2} width="100%">
                <TextField label={t('areas.fields.name')} {...register('text')} helperText={getErrorMessage('text')} />
                <PhotosField
                  value={photosURLs}
                  onChange={setPhotosURLs}
                  onItemClick={(photoUrl) => {
                    setPickedImage(O.Some(photoUrl));
                  }}
                />
                <TextField
                  label={t('areas.fields.location')}
                  {...register('location')}
                  helperText={getErrorMessage('location')}
                />
                <TextField
                  label={t('areas.fields.unitType')}
                  {...register('unitType')}
                  helperText={getErrorMessage('unitType')}
                />
                <TextField
                  label={t('areas.fields.serialNumber')}
                  {...register('serialNumber')}
                  helperText={getErrorMessage('serialNumber')}
                />
              </Stack>
              <Grid container justifyContent="space-between" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isValid || isLoadingCreateRepeater}
                >
                  {isLoadingCreateRepeater ? <CircularProgress size={16} /> : t('general.create')}
                </Button>
              </Grid>
            </Stack>
          </Box>
          <Box mt={2}>
            <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.object.edit')}</Typography>
          </Box>
        </>
      )}
      {repeaters.length === 0 ? (
        <Alert severity="error" sx={{ width: 'unset' }}>
          {t('areas.object.noAvailable')}
        </Alert>
      ) : (
        <>
          <OptionalSelect
            options={repeaters.map(({ id, text }) => ({ id, label: O.getWithDefault(text, id) }))}
            value={objectId}
            onChange={setObjectId}
          />
          <Box mt={2}>
            {O.match(
              selectedObjectDetails,
              (details) => {
                if (details.type !== 'Repeater') {
                  return null;
                }
                return <Repeater key={details.id} details={details} onSave={onSave} />;
              },
              () => (
                <Alert severity="info" sx={{ width: 'unset' }}>
                  {t('areas.object.noSelected')}
                </Alert>
              ),
            )}
          </Box>
        </>
      )}
    </Stack>
  );
};
