import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const DELETE_RADIO_UNIT = gql`
  mutation deleteRadioUnit($input: DeleteNodeInput!) {
    deleteRadioUnit(input: $input)
  }
`;

export const deleteRadioUnit = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_RADIO_UNIT, { input: { propertyId, nodeId } });
};
