import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { useTranslation } from 'common/hooks/useTranslation';
import { isTestEnv } from 'config';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useDebounce } from 'usehooks-ts';

import { G } from '@mobily/ts-belt';
import PendingIcon from '@mui/icons-material/Pending';
import { FormControl, Grid, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';

import { DropAntennaWithFibers } from 'app/outlets/DropAntenna';
import { usePatchTpOutlet } from 'app/areas/hooks/usePatch';
import { Outlet } from '../../Outlet';
import { styles } from './HowToFindTPOutlet.styles';
import { TPOutlet } from '../../TPOutlet';

export const HowToFindTPOutlet: FC<{
  outlet: Outlet | TPOutlet | DropAntennaWithFibers;
  premise: PremiseOnSinglePremise;
}> = ({ outlet, premise }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [outletLocation, setOutletLocation] = useState(outlet.location);
  const debouncedOutletLocation = useDebounce(outletLocation, !isTestEnv ? 1000 : 50);

  const { mutate: patchTPOutlet, isLoading } = usePatchTpOutlet({
    onSuccess: () => {
      toast.success(t('general.updatedSuccessfully'));
      queryClient.invalidateQueries(['outletsByPropertyId', premise.propertyId]);
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const locationInputValue = outletLocation ?? '';

  const handleSetOutletChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOutletLocation(e.target.value);
  };

  useEffect(() => {
    if (G.isNotNullable(debouncedOutletLocation) && debouncedOutletLocation !== outlet.location) {
      patchTPOutlet({ id: outlet.id, location: debouncedOutletLocation });
    }
  }, [debouncedOutletLocation]);

  return (
    <Grid container sx={styles.locationContainer}>
      <FormControl fullWidth>
        <InputLabel htmlFor="howToFind">{t('premise.howToFind')}</InputLabel>
        <OutlinedInput
          id="howToFind"
          multiline
          rows={2}
          value={locationInputValue}
          onChange={handleSetOutletChange}
          endAdornment={
            isLoading ? (
              <InputAdornment position="end" sx={styles.loadingAdornment}>
                <PendingIcon />
              </InputAdornment>
            ) : null
          }
        />
      </FormControl>
    </Grid>
  );
};
