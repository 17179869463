import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { useRef } from 'react';
import { useBoolean } from 'usehooks-ts';

type Props<A extends string> = {
  options: ReadonlyArray<A>;
  onSelect: Lambda<A, void>;
};

export const SelectButton = <A extends string>({ options, onSelect }: Props<A>) => {
  const { value, toggle, setFalse } = useBoolean(false);
  const buttonAnchor = useRef<null | HTMLButtonElement>(null);
  const { t } = useTranslation();

  const handleMenuItemClick = (menuOption: A) => () => {
    onSelect(menuOption);
    setFalse();
  };

  const buttonVariant = value ? 'outlined' : 'contained';

  const endIcon = value ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  return (
    <>
      <Button fullWidth ref={buttonAnchor} variant={buttonVariant} onClick={toggle} endIcon={endIcon}>
        {t('areas.object.addButton.label')}
      </Button>

      <Menu
        anchorEl={buttonAnchor.current}
        open={value}
        onClose={setFalse}
        PaperProps={{
          sx: {
            width: buttonAnchor.current?.getBoundingClientRect().width ?? '100%',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={handleMenuItemClick(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
