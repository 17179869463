import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_TRUNK = gql`
  mutation createTrunk($input: TrunkInput!) {
    createTrunk(input: $input)
  }
`;

export const createTrunk = (propertyId: number) => (trunkDetails: any) => {
  return requestGQL(CREATE_TRUNK, { input: { propertyId, trunkDetails } });
};
