import { PremiseDropAntennas } from 'app/outlets/components/PremiseRadioOutlets/PremiseDropAntennas/PremiseDropAntennas';
import { useDropAntennas } from 'app/outlets/hooks/useDropAntennas';
import { RoutesParamsMap } from 'app/routes/Route';
import { optionParseInt } from 'common/helpers';
import { useTranslation } from 'common/hooks/useTranslation';
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CircularProgress, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';

import { G, O } from '@mobily/ts-belt';
import { usePremise } from '../../hooks/usePremise';
import { PremiseInformations } from './components/PremiseInformations/PremiseInformations';
import * as styles from './SinglePremise.styles';

export const PremiseRadioContext = createContext<{ id: O.Option<number> }>({ id: O.None });

export const usePremiseRadioContext = () => {
  const context = useContext(PremiseRadioContext);

  if (G.isNullable(context)) {
    throw new Error('No Premise Radio Context available.');
  }

  return context;
};

export const SingleRadioPremise: FC = () => {
  const params = useParams<RoutesParamsMap['premise/:id']>();
  const parsedId = useMemo(() => optionParseInt(params.id), [params]);
  const { isLoadingPremise, isErrorPremise, premise } = usePremise(parsedId);
  const { isLoadingDropAntennas, isErrorDropAntennas, dropAntennas } = useDropAntennas(premise);
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  const premiseContextValue = useMemo(() => ({ id: premise?.propertyId }), [premise]);

  useEffect(() => {
    if (isErrorPremise || isErrorDropAntennas) {
      toast.error(t('error.generalMessage'));
    }
  }, [isErrorPremise, isErrorDropAntennas, t]);

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.titleContainer}>
        <IconButton
          data-cy="go-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon titleAccess="back" sx={styles.backIcon} />
          {!isLoadingPremise && !isErrorPremise && premise && (
            <Grid container>
              <Typography sx={styles.header}>{premise.name}</Typography>
            </Grid>
          )}
        </IconButton>
      </Grid>

      <Tabs variant="fullWidth" value={tabIndex} onChange={(_, v) => setTabIndex(v)}>
        <Tab
          data-cy="tab-informations"
          label={<Typography sx={styles.tabLabel}>{t('premise.informations')}</Typography>}
        />
        <Tab
          data-cy="tab-dropantennas"
          label={<Typography sx={styles.tabLabel}>{t('premise.dropAntennas')}</Typography>}
        />
      </Tabs>
      <PremiseRadioContext.Provider value={premiseContextValue}>
        <Grid container sx={styles.contentContainer}>
          {isLoadingPremise && tabIndex === 0 && <CircularProgress />}
          {!isLoadingPremise && !isErrorPremise && tabIndex === 0 && premise && (
            <PremiseInformations premise={premise} />
          )}
          {isLoadingDropAntennas && tabIndex === 1 && <CircularProgress />}
          {!isLoadingDropAntennas && !isErrorDropAntennas && tabIndex === 1 && dropAntennas && premise && (
            <PremiseDropAntennas dropAntennas={dropAntennas} premise={premise} />
          )}
        </Grid>
      </PremiseRadioContext.Provider>
    </Grid>
  );
};
