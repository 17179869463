import { FC } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

import { D, O, pipe } from '@mobily/ts-belt';

import { useIdbToken } from './hooks/useIdbToken';
import { useAuthenticationState } from './state';

export const Entrance: FC = () => {
  const [searchParams] = useSearchParams();
  const { changeToken } = useAuthenticationState();
  const { set: setIdbToken } = useIdbToken();

  useEffectOnce(() => {
    pipe(
      searchParams.get('auth'),
      O.mapNullable(JSON.parse),
      O.flatMap(D.get('token')),
      O.tap(changeToken),
      O.tap(setIdbToken),
    );
  });

  return <Navigate replace to="/" />;
};
