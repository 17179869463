import { Styles } from '../../theme/materialTheme';

export const container: Styles = { mt: 9, flexDirection: 'column', alignItems: 'center' };

export const searchInput: Styles = { mt: 2 };

export const warningContainer: Styles = {
  mt: 15,
  flexDirection: 'column',
  alignItems: 'center',
};

export const warningInfo: Styles = (theme) => ({
  mt: 4,
  color: theme.palette.text.secondary,
  fontSize: 24,
  lineHeight: 1.3,
  textAlign: 'center',
  mx: 2.5,
  fontWeight: 400,
});

export const loader: Styles = {
  mt: 2,
  justifySelf: 'center',
};

export const list: Styles = {
  mt: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 1.75,
  width: '100%',
};

export const pendingAdornment: Styles = {
  zIndex: 2,
};
