import { O, pipe } from '@mobily/ts-belt';

import { getPremisesFromSerializedData } from '../connection/getConnectionsFromSerializedData';
import { GetPropertiesConnectionsResponse } from './api';
import { BasicPropertyOnPropertiesList } from './types';

export const mergePropertiesDataWithConnections = (
  properties: ReadonlyArray<BasicPropertyOnPropertiesList>,
  connectionsResponse: GetPropertiesConnectionsResponse,
) =>
  properties.map((property) => {
    const connectionNodes = connectionsResponse.allProperties.nodes;
    const matchingConnection = connectionNodes.find((connection) => connection.id === property.id);

    const amountOfConnections = pipe(
      O.fromNullable(matchingConnection?.connectionByConnectionId?.serialized2),
      O.flatMap(getPremisesFromSerializedData),
      O.mapWithDefault(0, (connectedPremises) => connectedPremises.length),
    );

    return {
      ...property,
      amountOfConnections,
      toInstall: property.amountOfPremises - amountOfConnections,
    };
  });
