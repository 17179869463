import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_SWITCH = gql`
  mutation patchSwitch($input: SwitchInput!) {
    patchSwitch(input: $input)
  }
`;

export const patchSwitch = (propertyId: number) => (switchDetails: any) => {
  return requestGQL(PATCH_SWITCH, { input: { propertyId, switchDetails } });
};
