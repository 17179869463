import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_BASE_STATION = gql`
  mutation patchBaseStation($input: BaseStationInput!) {
    patchBaseStation(input: $input)
  }
`;

export const patchBaseStation = (propertyId: number) => (baseStationDetails: any) => {
  return requestGQL(PATCH_BASE_STATION, { input: { propertyId, baseStationDetails } });
};
