import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { FC, memo, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDebounce } from 'usehooks-ts';
import { z } from 'zod';
import { CoaxClass } from 'app/fibers/CoaxClass';
import { DropAntennaWithFibers } from 'app/outlets/DropAntenna';
import { Coax } from 'app/outlets/api';
import { useAuthenticationState } from '../../../../../authentication/state';
import { useErrorMessage } from '../../../../../ui/validationError/useErrorMessage';
import { usePatchCoax } from '../../../../hooks/usePatchCoax';
import * as styles from './styles';

export const fiberDropAntennaSchema = z.object({
  class: z.string().min(1),
  length: z.number().min(0).multipleOf(0.1),
});

export type FiberDropAntennaSchema = {
  class: CoaxClass;
  length: number;
};

export const FiberDropAntennaDetails: FC<{ fiber: Coax; nodeId: DropAntennaWithFibers['id']; propertyId: number }> =
  memo(({ fiber, nodeId, propertyId }) => {
    const { t } = useTranslation();
    const { userId, roles } = useAuthenticationState();
    const { mutate, isSuccess, isError } = usePatchCoax(nodeId, propertyId, fiber);

    const {
      register,
      handleSubmit,
      watch,
      control,
      formState: { errors, isDirty },
    } = useForm<FiberDropAntennaSchema>({
      defaultValues: {
        class: (fiber.class as CoaxClass) ?? CoaxClass.THICK,
        length: fiber.length ?? 0,
      },
      mode: 'onChange',
      resolver: zodResolver(fiberDropAntennaSchema),
    });

    const values = useWatch({ control });
    const debouncedValues = useDebounce(values, 500);

    const getErrorMessage = useErrorMessage(errors);

    useEffect(() => {
      if (isDirty) {
        handleSubmit((fiberForm) => {
          mutate({ fiberForm });
        })();
      }
    }, [debouncedValues]);

    const canEditFiber = fiber.personId === userId || roles.includes('cf_admin') || roles.includes('cf_contractor_pm');

    useEffect(() => {
      if (isSuccess) toast.success(t('general.updatedSuccessfully'));
    }, [isSuccess]);

    useEffect(() => {
      if (isError) toast.error(t('error.generalMessage'));
    }, [isError]);

    return (
      <Grid container sx={styles.fiberDetailsContainer}>
        <TextField fullWidth value={fiber.sourceNode?.text} label={t('coax.fromNode')} disabled />
        <TextField fullWidth value={fiber.from.portId} label={t('coax.fromPort')} disabled />
        <TextField
          {...register('class', { required: true })}
          select
          fullWidth
          label={t('coax.class')}
          variant="outlined"
          helperText={getErrorMessage('class')}
          value={watch('class') || ''}
        >
          {Object.values(CoaxClass).map((cClass) => (
            <MenuItem key={cClass} value={cClass}>
              {cClass}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          {...register('length', {
            valueAsNumber: true,
          })}
          label={t('coax.length')}
          disabled={!canEditFiber}
          type="number"
          inputProps={{ step: '0.1' }}
          helperText={getErrorMessage('length')}
        />
      </Grid>
    );
  });
