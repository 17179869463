import { zodResolver } from '@hookform/resolvers/zod';
import { O } from '@mobily/ts-belt';
import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDeleteFireBreach } from '../../hooks/useDelete';
import { usePatchFireBreach } from '../../hooks/usePatch';
import { fireBreachSchema, FireBreachSchema } from '../../types/FireBreachSchema';
import { FireBreachDetails } from '../../types/ObjectDetails';
import { PhotosField } from '../../ui/PhotosField';
import { EnlargedPhotoDialog } from '../EnlargedPhotoDialog';

type Props = {
  details: FireBreachDetails;
  onSave: Lambda<void, void>;
  onDelete: Lambda<void, void>;
};

export const FireBreach: FC<Props> = ({ details, onSave, onDelete }) => {
  const { t } = useTranslation();
  const { location, text, id } = details;
  const [pickedImage, setPickedImage] = useState<O.Option<string>>(O.None);
  const { mutate: patchFireBreach, isLoading: isLoadingFireBreach } = usePatchFireBreach({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });
  const { mutate: deleteFireBreach, isLoading: isLoadingDeleteFireBreach } = useDeleteFireBreach({
    onSuccess: () => {
      onDelete();
      toast.success(t('general.deletedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<FireBreachSchema>({
    defaultValues: {
      text: O.getWithDefault(text, ''),
      location: O.getWithDefault(location, ''),
    },
    mode: 'onChange',
    resolver: zodResolver(fireBreachSchema),
  });

  const [photosURLs, setPhotosURLs] = useState(details.photosURLs ?? []);

  const handleSave = (values: FireBreachSchema) => {
    patchFireBreach({ ...values, id, photosURLs });
  };

  const handleDelete = () => {
    deleteFireBreach(details.id);
  };

  return (
    <>
      <EnlargedPhotoDialog
        photoUrl={pickedImage}
        onClose={() => {
          setPickedImage(O.None);
        }}
      />
      <Stack component="form" onSubmit={handleSubmit(handleSave)}>
        <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.fireBreach.label')}</Typography>
        <Stack gap={2} width="100%">
          <PhotosField
            value={photosURLs}
            onChange={setPhotosURLs}
            onItemClick={(photoUrl) => {
              setPickedImage(O.Some(photoUrl));
            }}
          />
          <TextField label={t('areas.fields.name')} {...register('text')} disabled />
          <TextField label={t('areas.fields.location')} {...register('location')} />
        </Stack>
        <Grid container justifyContent="space-between" mt={2}>
          <Button variant="text" color="error" onClick={handleDelete} disabled={isLoadingDeleteFireBreach}>
            {isLoadingDeleteFireBreach ? <CircularProgress size={16} /> : t('general.delete')}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={!isValid || isLoadingFireBreach}>
            {isLoadingFireBreach ? <CircularProgress size={16} /> : t('general.save')}
          </Button>
        </Grid>
      </Stack>
    </>
  );
};
