import { requestGQL } from 'common/graphQlClient';
import { useMutation } from 'react-query';

import { MutatePatchTwistedPair, PATCH_TWISTED_PAIR } from '../api';
import { Fiber, Outlet } from '../Outlet';
import { TwistedPair } from '../TPOutlet';

export const parseTwistedPairToRequest =
  (outletId: Outlet['id']) =>
  (twistedPair: TwistedPair) =>
  (fiberForm: Partial<TwistedPair>): MutatePatchTwistedPair => {
    return {
      id: twistedPair.id,
      category: fiberForm.category ?? twistedPair.category ?? '',
      shielding: fiberForm.shielding ?? twistedPair.shielding ?? '',
      length: fiberForm.length ?? twistedPair.length ?? 0,
      from: { portId: twistedPair.from.portId, nodeId: twistedPair.from.nodeId },
      to: { portId: twistedPair.to.portId, nodeId: outletId },
    };
  };

export const usePatchTwistedPair = (outletId: string, propertyId: number, twistedPair: TwistedPair) => {
  const { data, mutate, isLoading, isError, isSuccess } = useMutation<string, unknown, { fiberForm: Partial<Fiber> }>(
    async ({ fiberForm }) =>
      requestGQL(PATCH_TWISTED_PAIR, {
        propertyId,
        twistedPair: parseTwistedPairToRequest(outletId)(twistedPair)(fiberForm as Partial<TwistedPair>),
      }),
  );

  return { data, mutate, isLoading, isError, isSuccess };
};
