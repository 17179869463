import { F, O } from '@mobily/ts-belt';
import { Box, Dialog, IconButton, Toolbar } from '@mui/material';
import { FC } from 'react';
import { Lambda } from 'common/types';
import { publicUrl } from 'config';

export const EnlargedPhotoDialog: FC<{ photoUrl: O.Option<string>; onClose: Lambda<void, void> }> = ({
  photoUrl,
  onClose,
}) => {
  return (
    <Dialog
      open={O.isSome(photoUrl)}
      fullScreen
      onClose={() => {
        onClose();
      }}
      style={{ width: '100%' }}
    >
      <Toolbar>
        {onClose && (
          <IconButton size="large" color="inherit" onClick={() => onClose()}>
            <Box component="img" src={`${publicUrl}/img/icons/x.svg`} alt="close" />
          </IconButton>
        )}
      </Toolbar>
      {O.match(
        photoUrl,
        (pickedImageUrl) => (
          <Box component="img" src={pickedImageUrl} alt="add" style={{ width: '100%' }} />
        ),
        F.always(null),
      )}
    </Dialog>
  );
};
