import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_RACK = gql`
  mutation patchRack($input: BasicObjectInput!) {
    patchRack(input: $input)
  }
`;

export const patchRack = (propertyId: number) => (basicObjectDetails: any) => {
  return requestGQL(PATCH_RACK, { input: { propertyId, basicObjectDetails } });
};
