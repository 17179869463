import { O } from '@mobily/ts-belt';
import { Alert, CircularProgress } from '@mui/material';
import { Lambda } from 'common/types';
import { FC } from 'react';
import { useGetPropertyRadioConnections } from 'app/areas/hooks/useGetPropertyRadioConnections';
import { getRadioObject } from '../../getRadioObject';

import { BaseStation } from './BaseStation';
import { DropAntenna } from './DropAntenna';
import { RadioUnit } from './RadioUnit';
import { InputAntenna } from './InputAntenna';
import { Combiner } from './Combiner';
import { Coupler } from './Coupler';
import { Repeater } from './Repeater';
import { Splitter } from './Splitter';

type Props = {
  objectId: string;
  onSave: Lambda<void, void>;
  onDelete: Lambda<void, void>;
};

export const RadioObjectDetails: FC<Props> = ({ objectId, onDelete, onSave }) => {
  const { status, data } = useGetPropertyRadioConnections();

  if (status === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'error') {
    return <Alert severity="error">Could not get the object details.</Alert>;
  }
  const radioObjectDetails = getRadioObject(objectId)(data);

  if (O.isNone(radioObjectDetails)) {
    return <Alert severity="error">Could not get the object details.</Alert>;
  }

  switch (radioObjectDetails.type) {
    case 'BaseStation':
      return <BaseStation details={radioObjectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'DropAntenna':
      return <DropAntenna details={radioObjectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'RadioUnit':
      return <RadioUnit details={radioObjectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'InputAntenna':
      return <InputAntenna details={radioObjectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'Combiner':
      return <Combiner details={radioObjectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'Coupler':
      return <Coupler details={radioObjectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'Repeater':
      return <Repeater details={radioObjectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'Splitter':
      return <Splitter details={radioObjectDetails} onDelete={onDelete} onSave={onSave} />;
    default:
      return <Alert severity="error">Could not get the object details.</Alert>;
  }
};
