import { Components, PaletteOptions } from '@mui/material';

import { colors } from './colors';

export const materialComponentsOverrides = (palette: PaletteOptions): Components => ({
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        marginTop: 0.5,
        borderRadius: 0.5,
        '.MuiOutlinedInput-notchedOutline': {
          background: colors.mono100, // always white, no matter the palette
          top: 0,
          '& legend': {
            display: 'none',
          },
        },
        '.MuiOutlinedInput-input': {
          fontSize: 15,
          zIndex: 2,
          height: 40,
          boxSizing: 'border-box',
          padding: '8px 12px 10px',
        },
      },
      multiline: {
        padding: 0,
      },
    },
  },
  MuiAccordion: {
    styleOverrides: { root: { boxShadow: 'none', fontFamily: '"Cabin", "Roboto", "Helvetica", "Arial", sans-serif' } },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: { fontSize: 16, position: 'relative', transform: 'none' },
    },
    defaultProps: { shrink: true },
  },
  MuiLink: {
    styleOverrides: {
      root: { fontFamily: '"Cabin", "Roboto", "Helvetica", "Arial", sans-serif', fontSize: 18 },
    },
  },
  MuiButton: {
    styleOverrides: {
      contained: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 16,
        paddingBottom: 16,
        borderRadius: 24,
        lineHeight: 1,
        textTransform: 'capitalize',
        minWidth: 'unset',
      },
      outlined: {
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 16,
        paddingBottom: 16,
        borderRadius: 24,
        lineHeight: 1,
        textTransform: 'capitalize',
        minWidth: 'unset',
      },
      text: {
        fontSize: 18,
        padding: 0,
        textTransform: 'unset',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        width: '100%',
        '.MuiButtonBase-root.MuiTab-root': {
          textTransform: 'capitalize',
          paddingBottom: 4,
          fontSize: 18,
          '&:not(.Mui-selected)': { color: palette.text!.secondary },
        },
      },
      indicator: { height: 4, bottom: 4 },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        width: '100%',
      },
    },
  },
});
