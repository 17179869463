import { z } from 'zod';

export const radioUnitSchema = z.object({
  text: z.string().min(1),
  location: z.string(),
  portCount: z.number().int().min(1),
  unitType: z.string(),
  serialNumber: z.string(),
});

export type RadioUnitSchema = z.infer<typeof radioUnitSchema>;
