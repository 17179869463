import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_WIFIDOT = gql`
  mutation createWifiDot($input: WifiDotInput!) {
    createWifiDot(input: $input)
  }
`;

export const createWifiDot = (propertyId: number) => (wifiDotDetails: any) => {
  return requestGQL(CREATE_WIFIDOT, { input: { propertyId, wifiDotDetails } });
};
