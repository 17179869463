import { del, get, set } from 'idb-keyval';
import { useState } from 'react';
import { useBoolean, useEffectOnce } from 'usehooks-ts';

export const useIndexedDb = <A>(key: string) => {
  const [value, setValue] = useState<undefined | A>(undefined);
  const { value: isLoading, setValue: setIsLoading } = useBoolean(true);
  const { value: isError, setValue: setIsError } = useBoolean(false);

  useEffectOnce(() => {
    const handleIdbUpdate = () => {
      setIsLoading(true);
      get<A>(key)
        .then((v) => {
          setValue(v);
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    handleIdbUpdate();

    window.addEventListener('idbUpdate', handleIdbUpdate);

    return () => window.removeEventListener('idbUpdate', handleIdbUpdate);
  });

  const setIdbValue = (v: A) => {
    return set(key, v)
      .then(() => {
        const idbUpdateCustomEvent = new CustomEvent('idbUpdate');
        window.dispatchEvent(idbUpdateCustomEvent);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const delIdbValue = () => {
    setIsLoading(true);
    del(key)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    value,
    remove: delIdbValue,
    set: setIdbValue,
    isLoading,
    isError,
  };
};
