import { z } from 'zod';

export const technicalEquipmentSchema = z.object({
  text: z.string().min(1),
  portCount: z.number().min(0).int(),
  location: z.string(),
  ip: z.string(),
});

export type TechnicalEquipmentSchema = z.infer<typeof technicalEquipmentSchema>;
