import { requestGQL } from 'common/graphQlClient';
import { useQuery } from 'react-query';

import { G } from '@mobily/ts-belt';

import { isTestEnv, oneHourInMs } from '../../../config';
import { GET_PROPERTY, GetPropertyResponse } from '../api';
import { parseGetPropertyResponse, PropertyOnSinglePropertyInformations } from '../Property';

export const useProperty = (id: number | null) => {
  // TODO: extract that usequery so these options won't be necessary
  return useQuery<GetPropertyResponse, unknown, null | Readonly<PropertyOnSinglePropertyInformations>>(
    ['propertyById', id],
    () => requestGQL(GET_PROPERTY, { id }),
    {
      enabled: G.isNotNullable(id),
      refetchOnWindowFocus: false,
      retry: isTestEnv ? 0 : 3,
      staleTime: oneHourInMs,
      select: (data) => parseGetPropertyResponse(data),
    },
  );
};
