import { G } from '@mobily/ts-belt';

import { useConnection } from '../../connection/hooks/useConnection';
import { useMutateDeleteOutletPhoto } from './useMutateDeleteOutletPhoto';
import { azureDelete, getAzureSignedLinkOutletPhotoDelete } from '../api';

export const useDeletePhoto = (propertyId: null | number, outletId: null | string) => {
  const {
    serialized2,
    id,
    isLoading: isLoadingUseConnection,
    isError: isErrorUseConnection,
  } = useConnection(propertyId);

  const {
    mutate,
    isLoading: isLoadingMutateDeleteOutletPhoto,
    isError: isErrorMutateDeleteOutletPhoto,
    isSuccess,
  } = useMutateDeleteOutletPhoto(propertyId);

  const deletePhoto = async (photoUrl: string) => {
    const { url, query } = await getAzureSignedLinkOutletPhotoDelete({ outletId, connectionId: id, photoUrl });

    const photoName = photoUrl.substring(photoUrl.lastIndexOf('/') + 1);

    await azureDelete(photoName, `${url}?${query}`);

    if (G.isNotNullable(serialized2) && G.isNotNullable(outletId)) {
      mutate({ outletId, connectionId: id, photoUrl });
    }
  };

  return {
    deletePhoto,
    isLoading: isLoadingUseConnection || isLoadingMutateDeleteOutletPhoto,
    isError: isErrorUseConnection || isErrorMutateDeleteOutletPhoto,
    isSuccess,
  };
};
