import { createStylesMap } from 'app/theme/materialTheme';

export const styles = createStylesMap({
  centerContainer: {
    justifyContent: 'center',
  },
  addFiberSubmitContainer: {
    mt: 2,
    justifyContent: 'center',
  },
});
