import { A, D, O, pipe } from '@mobily/ts-belt';

import { Premise } from '../premises/Premise';
import { GetOutletsResponse, LinkSideInput } from './api';
import {
  getDataCFObject,
  getFibersNodesByOutlet,
  getNodeLocation,
  getNodePhotos,
  getNodesData,
  getCrossConnectionPanelNodeByFiber,
  getTPOutletsNodesByPremiseId,
} from './connectionSerializedData';
import { LinkData, SerializedData } from '../connection/api';

export type TwistedPair = {
  id: string;
  category: string;
  length: number;
  personId: string | null;
  crossConnectionPanelName: string;
  from: LinkSideInput;
  to: LinkSideInput;
  shielding: string;
};

export type TPOutlet = {
  id: string;
  text: string;
  imagesUrls: ReadonlyArray<string>;
  location: null | string;
  fibers: ReadonlyArray<TwistedPair>;
};

export type TPOutlets = ReadonlyArray<TPOutlet>;

export const mapLinkDataToTwistedPair = (fiberNode: LinkData, serializedData: SerializedData) => {
  return {
    category: getDataCFObject(fiberNode)?.category ?? '',
    crossConnectionPanelName:
      getDataCFObject(fiberNode)?.crossConnectionPanel ??
      getCrossConnectionPanelNodeByFiber(serializedData, fiberNode)?.text ??
      '',
    from: {
      nodeId: fiberNode.from,
      portId: fiberNode.fromPort,
    },
    id: fiberNode.id,
    length: getDataCFObject(fiberNode)?.length ?? 0,
    personId: getDataCFObject(fiberNode)?.personId ?? null,
    to: {
      nodeId: fiberNode.to,
      portId: fiberNode.toPort,
    },
    shielding: getDataCFObject(fiberNode)?.shielding ?? '',
  };
};
export const parseGetTPOutletsResponse =
  (premise: Premise) =>
  (response: GetOutletsResponse): TPOutlets => {
    // TODO: extract to some 'connection' hook with updating the connections also
    return pipe(
      response,
      O.flatMap(D.get('propertyById')),
      O.flatMap(D.get('connectionByConnectionId')),
      O.flatMap(O.fromNullable),
      O.flatMap(D.get('serialized2')),
      O.mapWithDefault([], (serializedData) => {
        return pipe(
          serializedData,
          getNodesData,
          getTPOutletsNodesByPremiseId(premise.id),
          A.map((outletNode) => ({
            id: outletNode.id,
            text: outletNode.text || outletNode.cfObject?.text || '',
            imagesUrls: getNodePhotos(outletNode),
            location: getNodeLocation(outletNode),
            fibers: getFibersNodesByOutlet(serializedData, outletNode).map((fiberNode) => {
              return mapLinkDataToTwistedPair(fiberNode, serializedData);
            }) as TwistedPair[],
          })),
        );
      }),
    );
  };
