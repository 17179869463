import axios from 'axios';
import { gql } from 'graphql-request';

import { O } from '@mobily/ts-belt';

import { clientID, IAMUri } from '../../config';

export type LoginResponseOK = {
  access_token: string;
  expires_in: number;
  token_type: 'Bearer';
  userId: string;
};

export type LoginResponseError = {
  error: string;
  error_description: string;
  error_reason: string;
};

// TODO: use better typed lib than axios || extract it and wrap
export const postLogin = ({ email, password }: { email: string; password: string }) => {
  return axios
    .postForm<LoginResponseOK>(`${IAMUri}/oauth2/token`, {
      client_id: clientID,
      grant_type: 'password',
      loginId: email,
      password,
    })
    .then(
      (resOK) => resOK.data.access_token,
      (resErr) => {
        throw resErr.message;
      },
    );
};

export type QueryPersonByIdResponse = {
  personById: {
    id: string;
    settings?: O.Option<Record<string, unknown>>;
  };
};

export const QUERY_PERSON_BY_ID = gql`
  query personById($id: UUID!) {
    personById(id: $id) {
      id
      settings
    }
  }
`;

export type MutateUpdatePersonById = {
  settings: Record<string, unknown>;
};

export const UPDATE_PERSON_BY_ID = gql`
  mutation updatePersonById($id: UUID!, $personPatch: PersonPatch!) {
    updatePersonById(input: { id: $id, personPatch: $personPatch }) {
      person {
        id
      }
    }
  }
`;
