import { Styles } from 'app/theme/materialTheme';

export const outlet: Styles = { mt: 2 };

export const photosHeader: Styles = { mt: 1 };

export const enlargedPhoto: Styles = {
  width: '100%',
};

export const dialog: Styles = {
  width: '100%',
};

export const dialogContent: Styles = {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  p: 1.5,
  flexDirection: 'column',
};

export const dialogTopbar: Styles = {
  justifyContent: 'space-between',
};

export const smallDialogTopbar: Styles = {
  minHeight: 5,
};

export const fibersContainer: Styles = {
  mt: 2,
  flexDirection: 'column',
};

export const fiberDetailsContainer: Styles = {
  gap: 2,
};

export const centerContainer: Styles = {
  justifyContent: 'center',
};

export const deleteFiberContainer: Styles = {
  mt: 2,
};

export const deleteFibersButtonsContainer: Styles = {
  mt: 2,
  justifyContent: 'space-evenly',
  flexDirection: { xs: 'column', sm: 'row' },
  gap: { xs: 1, sm: 2 },
};

export const loaderContainer: Styles = {
  my: 2,
  justifyContent: 'center',
};

export const deletePhotoButton: Styles = {
  mt: 2,
};

export const photoDeleteConfirmationDialog: Styles = {
  mx: 2,
};

export const cardTop: Styles = {
  justifyContent: 'space-between',
};
