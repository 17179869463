import { Styles } from 'app/theme/materialTheme';

export const header: Styles = {
  width: 'calc(100% + 32px)',
  marginLeft: '-16px',
  marginTop: '-16px',
};

export const links: Styles = {
  cursor: 'pointer',
  padding: 1,
  textAlign: 'center',
};

export const linkRadio: Styles = {
  backgroundColor: '#ccecec',
  ':hover': {
    backgroundColor: '#b8ebeb',
  },
};

export const linkNetwork: Styles = {
  backgroundColor: '#ecdfcc',
  ':hover': {
    backgroundColor: '#ebd3b8',
  },
};

// TODO: introduce some ONE LINE TYPOGRAPHY component that will cover non breakable one line texts
export const description: Styles = (theme) => ({
  fontSize: 20,
  fontWeight: 700,
  color: theme.palette.primary.main,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const infoContainer: Styles = {
  justifyContent: 'space-between',
  mt: 0.5,
  alignItems: 'center',
  columnGap: 1,
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
};

export const infoLabel: Styles = {
  mt: 0.5,
  fontSize: 12,
  alignItems: 'center',
  textTransform: 'uppercase',
};

export const infoValue: Styles = { fontSize: 18, overflow: 'hidden', textOverflow: 'ellipsis' };

export const separateLine: Styles = (theme) => ({ my: 1, border: '0.5px solid', borderColor: theme.palette.line.main });

export const statsContainer: Styles = { justifyContent: 'space-between' };

export const singleStatContainer: Styles = { flexDirection: 'column', alignItems: 'center' };

export const statsNumber: Styles = (theme) => ({ fontWeight: 700, fontSize: 20, color: theme.palette.text.secondary });

export const statsDescription: Styles = (theme) => ({
  mt: 1,
  color: theme.palette.text.secondary,
  fontSize: 12,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
});

export const amountOfPremises: Styles = (theme) => ({
  color: theme.palette.text.secondary,
});

export const amountOfContracts: Styles = (theme) => ({ color: theme.palette.primary.main });

export const toInstall: Styles = (theme) => ({
  color: theme.palette.error.main,
});

export const detailsLoaderContainer: Styles = {
  flexWrap: 'nowrap',
  gap: 2,
};

export const detailsSekeleton: Styles = {
  width: '100%',
  height: 56,
};
