import { O } from '@mobily/ts-belt';
import { Alert, CircularProgress } from '@mui/material';
import { Lambda } from 'common/types';
import { FC } from 'react';
import { getObject } from '../../getObject';
import { useGetPropertyConnections } from '../../hooks/useGetPropertyConnections';
import { CableRoute } from './CableRoute';
import { FireBreach } from './FireBreach';
import { IncomingFiber } from './IncomingFiber';
import { ODF } from './ODF';
import { Rack } from './Rack';
import { Switch } from './Switch';
import { Trunk } from './Trunk';
import { TechnicalODF } from './TechnicalODF';
import { TechnicalSwitch } from './TechnicalSwitch';
import { WifiDot } from './WifiDot';
import { TpOutlet } from './TpOutlet';
import { CrossConnectionPanel } from './CrossConnectionPanel';
import { TechnicalEquipment } from './TechnicalEquipment';

type Props = {
  objectId: string;
  onSave: Lambda<void, void>;
  onDelete: Lambda<void, void>;
};

export const ObjectDetails: FC<Props> = ({ objectId, onDelete, onSave }) => {
  const { status, data } = useGetPropertyConnections();

  if (status === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'error') {
    return <Alert severity="error">Could not get the object details.</Alert>;
  }

  const objectDetails = getObject(objectId)(data);

  if (O.isNone(objectDetails)) {
    return <Alert severity="error">Could not get the object details.</Alert>;
  }

  switch (objectDetails.type) {
    case 'IncomingFiber':
      return <IncomingFiber details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'Trunk':
      return <Trunk details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'Switch':
      return <Switch details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'ODF':
      return <ODF details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'TechnicalODF':
      return <TechnicalODF details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'TechnicalSwitch':
      return <TechnicalSwitch details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'WifiDot':
      return <WifiDot details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'TpOutlet':
      return <TpOutlet details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'CrossConnectionPanel':
      return <CrossConnectionPanel details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'FireBreach':
      return <FireBreach details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'TechnicalEquipment':
      return <TechnicalEquipment details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'Rack':
      return <Rack details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    case 'CableRoute':
      return <CableRoute details={objectDetails} onDelete={onDelete} onSave={onSave} />;
    default:
      return <Alert severity="error">Could not get the object details.</Alert>;
  }
};
