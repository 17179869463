import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { G } from '@mobily/ts-belt';

import { requestGQL } from '../../../common/graphQlClient';
import { isTestEnv, oneHourInMs } from '../../../config';
import { CREATE_PREMISE_INSTALLATION_BOOKING, GET_PREMISE, GetPremiseResponse, UPDATE_PREMISE_NOTES } from '../api';
import { parseGetPremiseResponse, PremiseOnSinglePremise } from '../Premise';

export const usePremise = (id: number | null) => {
  const queryClient = useQueryClient();

  // TODO: extract that usequery so these options won't be necessary
  const {
    data,
    isLoading: isLoadingPremise,
    isError: isErrorPremise,
  } = useQuery<GetPremiseResponse>(['premiseById', id], () => requestGQL(GET_PREMISE, { id }), {
    enabled: G.isNotNullable(id),
    refetchOnWindowFocus: false,
    retry: isTestEnv ? 0 : 3,
    staleTime: oneHourInMs,
  });

  const {
    mutate: updatePremiseNotes,
    isLoading: isLoadingUpdatePremiseNotes,
    isError: isErrorUpdatePremiseNotes,
    isSuccess: isSuccessUpdatePremiseNotes,
  } = useMutation<unknown, unknown, Partial<PremiseOnSinglePremise>>(
    (updatedPremise) => requestGQL(UPDATE_PREMISE_NOTES, { id, premisesPatch: updatedPremise }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['premiseById', id]);
      },
    },
  );

  const {
    mutate: createPremiseInstallationBooking,
    isLoading: isLoadingPremiseInstallationBooking,
    isError: isErrorPremiseInstallationBooking,
    isSuccess: isSuccessPremiseInstallationBooking,
  } = useMutation<unknown, unknown, Partial<PremiseOnSinglePremise>>(
    (updatedPremise) => requestGQL(CREATE_PREMISE_INSTALLATION_BOOKING, { premiseId: id, ...updatedPremise }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['premiseById', id]);
      },
    },
  );

  const parsedPremise = useMemo(() => {
    if (G.isNullable(data)) {
      return null;
    }

    return parseGetPremiseResponse(data);
  }, [data]);

  return {
    premise: parsedPremise,
    isLoadingPremise,
    isErrorPremise,
    updatePremiseNotes,
    isLoadingUpdatePremiseNotes,
    isErrorUpdatePremiseNotes,
    isSuccessUpdatePremiseNotes,
    createPremiseInstallationBooking,
    isLoadingPremiseInstallationBooking,
    isErrorPremiseInstallationBooking,
    isSuccessPremiseInstallationBooking,
  };
};
