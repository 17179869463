import { useTranslation } from 'common/hooks/useTranslation';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useBoolean, useDebounce } from 'usehooks-ts';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  NativeSelect,
  OutlinedInput,
  Switch,
} from '@mui/material';

import { usePremise } from '../../../../../hooks/usePremise';
import { NotAccesibleReason, PremiseOnSinglePremise } from '../../../../../Premise';
import * as styles from './styles';

export const NotAccessibleNotes: FC<{ premise: PremiseOnSinglePremise }> = ({ premise }) => {
  const { updatePremiseNotes, isLoadingUpdatePremiseNotes, isErrorUpdatePremiseNotes, isSuccessUpdatePremiseNotes } =
    usePremise(premise.id);
  const { value: isNotAccessible, toggle: toggleIsNotAccessible } = useBoolean(premise.isNotAccessible);

  const [reason, setReason] = useState<NotAccesibleReason>(premise.reason ?? NotAccesibleReason.Other);
  const [notes, setNotes] = useState(premise.notes ?? '');
  const debouncedNotes = useDebounce(notes, 500);
  const debouncedReason = useDebounce(reason, 500);
  const debouncedIsNotAccessible = useDebounce(isNotAccessible, 500);

  const { t } = useTranslation();

  useEffect(() => {
    if (debouncedIsNotAccessible === premise.isNotAccessible) return;

    if (premise.isNotAccessible && !isNotAccessible) {
      updatePremiseNotes({ isNotAccessible: false, notes: null, reason: null });
      return;
    }

    updatePremiseNotes({ isNotAccessible });
  }, [debouncedIsNotAccessible]);

  useEffect(() => {
    if (debouncedNotes === premise.notes) return;

    updatePremiseNotes({ notes });
  }, [debouncedNotes]);

  useEffect(() => {
    if (debouncedReason === premise.reason) return;

    updatePremiseNotes({ reason });
  }, [debouncedReason]);

  useEffect(() => {
    if (isErrorUpdatePremiseNotes) {
      toast.error(t('error.generalMessage'));
    }
  }, [isErrorUpdatePremiseNotes, t]);

  return (
    <Grid container sx={styles.container}>
      <FormControlLabel
        sx={styles.toggleLabel}
        control={<Switch checked={isNotAccessible} onChange={toggleIsNotAccessible} />}
        label={t('premise.note.toggleLabel')}
      />

      {isNotAccessible && (
        <>
          <FormControl fullWidth>
            <InputLabel htmlFor="reason">{t('premise.note.reason.label')}</InputLabel>
            {/* TODO: use react-hook-form */}
            <NativeSelect
              id="reason"
              input={<OutlinedInput fullWidth />}
              value={reason}
              onChange={(e) => setReason(e.target.value as NotAccesibleReason)}
            >
              <option value={NotAccesibleReason.NotFound}>{t('premise.note.reason.notFound')}</option>
              <option value={NotAccesibleReason.Refused}>{t('premise.note.reason.refused')}</option>
              <option value={NotAccesibleReason.Other}>{t('premise.note.reason.other')}</option>
            </NativeSelect>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel htmlFor="notes">{t('premise.note.note.label')}</InputLabel>
            <OutlinedInput
              id="notes"
              label={t('premise.note.note.label')}
              placeholder={t('premise.note.note.placeholder')}
              multiline
              rows={4}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              endAdornment={
                <>
                  {isLoadingUpdatePremiseNotes && <CircularProgress sx={styles.loader} />}
                  {isSuccessUpdatePremiseNotes && <TaskAltIcon sx={styles.loader} color="primary" />}
                </>
              }
            />
          </FormControl>
        </>
      )}
    </Grid>
  );
};
