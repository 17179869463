import { O, pipe } from '@mobily/ts-belt';
import { Lambda } from 'common/types';
import { useMutation, useQueryClient } from 'react-query';
import { usePropertyRadioContext } from 'app/properties/components/SingleRadioProperty';
import { usePropertyContext } from '../../properties/components/SingleProperty';
import { createBundle } from '../api/createBundle';
import { createCableRoute } from '../api/createCableRoute';
import { createFireBreach } from '../api/createFireBreach';
import { createODF } from '../api/createODF';
import { createTechnicalODF } from '../api/createTechnicalODF';
import { createRack } from '../api/createRack';
import { createSwitch } from '../api/createSwitch';
import { createTechnicalSwitch } from '../api/createTechnicalSwitch';
import { createWifiDot } from '../api/createWifiDot';
import { createTpOutlet } from '../api/createTpOutlet';
import { createCrossConnectionPanel } from '../api/createCrossPanel';
import { createTechnicalEquipment } from '../api/createTechnicalEquipment';
import { createTrunk } from '../api/createTrunk';
import { createBaseStation } from '../api/createBaseStation';
import { createDropAntenna } from '../api/createDropAntenna';
import { createRadioUnit } from '../api/createRadioUnit';
import { createInputAntenna } from '../api/createInputAntenna';
import { createCombiner } from '../api/createCombiner';
import { createCoupler } from '../api/createCoupler';
import { createRepeater } from '../api/createRepeater';
import { createSplitter } from '../api/createSplitter';

type SuccessErrorCallbacks = {
  onSuccess: Lambda<void, void>;
  onError: Lambda<void, void>;
};

const useCreateNode = (
  type: string,
  apiCallFn: Lambda<number, Lambda<any, Promise<unknown>>>,
  { onSuccess, onError }: SuccessErrorCallbacks,
) => {
  const { id } = usePropertyContext();
  const { id: radioId } = usePropertyRadioContext();

  const currentPropertyId = id || radioId;
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, unknown>(
    (details: any) =>
      pipe(
        currentPropertyId,
        O.map((propertyId) => apiCallFn(propertyId)({ ...details, type })),
        O.getExn,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['propertyConnection', currentPropertyId]);
        queryClient.invalidateQueries(['propertyRadioConnection', currentPropertyId]);
        onSuccess();
      },
      onError: () => {
        onError();
      },
    },
  );
};

export const useCreateBundle = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('bundle', createBundle, callbacks);
};

export const useCreateODF = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('odf', createODF, callbacks);
};

export const useCreateTechnicalODF = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('technicalOdf', createTechnicalODF, callbacks);
};

export const useCreateSwitch = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('switch', createSwitch, callbacks);
};

export const useCreateTechnicalSwitch = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('technicalSwitch', createTechnicalSwitch, callbacks);
};

export const useCreateWifiDot = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('wifiDot', createWifiDot, callbacks);
};

export const useCreateTpOutlet = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('tpOutlet', createTpOutlet, callbacks);
};

export const useCreateCrossPanel = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('crossPanel', createCrossConnectionPanel, callbacks);
};

export const useCreateTechnicalEquipment = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('technicalEquipment', createTechnicalEquipment, callbacks);
};

export const useCreateTrunk = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('trunk', createTrunk, callbacks);
};

export const useCreateFireBreach = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('fireBreach', createFireBreach, callbacks);
};

export const useCreateRack = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('rack', createRack, callbacks);
};

export const useCreateCableRoute = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('cableRoute', createCableRoute, callbacks);
};

export const useCreateBaseStation = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('baseStation', createBaseStation, callbacks);
};

export const useCreateDropAntenna = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('dropAntenna', createDropAntenna, callbacks);
};

export const useCreateRadioUnit = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('radioUnit', createRadioUnit, callbacks);
};

export const useCreateInputAntenna = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('inputAntenna', createInputAntenna, callbacks);
};

export const useCreateCombiner = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('combiner', createCombiner, callbacks);
};

export const useCreateCoupler = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('coupler', createCoupler, callbacks);
};

export const useCreateRepeater = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('repeater', createRepeater, callbacks);
};

export const useCreateSplitter = (callbacks: SuccessErrorCallbacks) => {
  return useCreateNode('splitter', createSplitter, callbacks);
};
