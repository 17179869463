import { A, D, O, pipe } from '@mobily/ts-belt';

import { Premise } from '../premises/Premise';
import { Coax, CoaxClass, GetRadioOutletsResponse } from './api';
import {
  getAnyNodeByFiber,
  getDataCFObject,
  getFibersNodesByOutlet,
  getNodeLocation,
  getNodePhotos,
  getNodesData,
  getRadioOutletsNodesByPremiseId,
} from './connectionSerializedData';
import { LinkData, SerializedData } from '../connection/api';

export type DropAntennaWithFibers = {
  id: string;
  text: string;
  photosURLs: ReadonlyArray<string>;
  location: null | string;
  fibers: ReadonlyArray<Coax>;
};

export type DropAntennas = ReadonlyArray<DropAntennaWithFibers>;

export const mapLinkDataToCoax = (fiberNode: LinkData, serializedData: SerializedData) => {
  return {
    sourceNode: getAnyNodeByFiber(serializedData, fiberNode),
    class: getDataCFObject(fiberNode)?.class as CoaxClass,
    from: {
      nodeId: fiberNode.from,
      portId: fiberNode.fromPort,
    },
    id: fiberNode.id,
    length: getDataCFObject(fiberNode)?.length ?? 0,
    personId: getDataCFObject(fiberNode)?.personId ?? null,
    to: {
      nodeId: fiberNode.to,
      portId: fiberNode.toPort,
    },
  };
};

export const parseGetDropAntennasResponse =
  (premise: Premise) =>
  (response: GetRadioOutletsResponse): DropAntennas => {
    // TODO: extract to some 'connection' hook with updating the connections also
    return pipe(
      response,
      O.flatMap(D.get('propertyById')),
      O.flatMap(D.get('radioConnectionByRadioConnectionId')),
      O.flatMap(O.fromNullable),
      O.flatMap(D.get('serialized2')),
      O.mapWithDefault([], (serializedData) => {
        return pipe(
          serializedData,
          getNodesData,
          getRadioOutletsNodesByPremiseId(premise.id),
          A.map((outletRadioNode) => ({
            id: outletRadioNode.id,
            text: outletRadioNode.text || outletRadioNode.cfObject?.text || '',
            photosURLs: getNodePhotos(outletRadioNode),
            location: getNodeLocation(outletRadioNode),
            portCount: outletRadioNode.portCount,
            unitType: outletRadioNode.cfObject?.unitType,
            serialNumber: outletRadioNode.cfObject?.serialNumber,
            fibers: getFibersNodesByOutlet(serializedData, outletRadioNode).map((fiberNode) => {
              return mapLinkDataToCoax(fiberNode, serializedData);
            }),
          })),
        );
      }),
    );
  };
