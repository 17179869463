import { Box, Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import { FC } from 'react';

import { O } from '@mobily/ts-belt';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { publicUrl } from 'config';
import { ObjectDetails } from './ObjectDetails/ObjectDetails';
import { RadioObjectDetails } from './ObjectDetails/RadioObjectDetails';

export const ObjectDetailsDialog: FC<{
  selectedNodeId: O.Option<string>;
  onClose: Lambda<void, void>;
  isRadio: Boolean;
}> = ({ selectedNodeId, onClose, isRadio }) => {
  const { t } = useTranslation();

  const renderDetails = () => {
    if (O.isNone(selectedNodeId)) {
      return null;
    }

    const DetailsComponent = isRadio ? RadioObjectDetails : ObjectDetails;

    return <DetailsComponent objectId={selectedNodeId} onSave={onClose} onDelete={onClose} />;
  };

  return (
    <Dialog
      open={O.isSome(selectedNodeId)}
      onClose={() => {
        onClose();
      }}
      PaperProps={{ sx: { margin: 0.5, p: 2, width: 375 } }}
    >
      <Toolbar sx={{ p: '0!important', justifyContent: 'space-between' }}>
        <Typography>{t('areas.object.details.label')}</Typography>
        <IconButton
          color="inherit"
          onClick={() => {
            onClose();
          }}
        >
          <Box component="img" src={`${publicUrl}/img/icons/x.svg`} alt="close" />
        </IconButton>
      </Toolbar>
      {renderDetails()}
    </Dialog>
  );
};
