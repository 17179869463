import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const DELETE_CABLE_ROUTE = gql`
  mutation deleteCableRoute($input: DeleteNodeInput!) {
    deleteCableRoute(input: $input)
  }
`;

export const deleteCableRoute = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_CABLE_ROUTE, { input: { propertyId, nodeId } });
};
