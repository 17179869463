import { mergeStyles } from 'app/theme/materialTheme';
import { useTranslation } from 'common/hooks/useTranslation';
import * as commonStyles from 'common/styles';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material';

import { BasicPropertyOnPropertiesList, ExtendedPropertyOnPropertyList } from '../../types';
import { ConnectionsNumbers } from './components/ConnectionsNumbers';
import * as styles from './PropertyCard.styles';

const checkIfExtended = (
  props: ExtendedPropertyOnPropertyList | BasicPropertyOnPropertiesList,
): props is ExtendedPropertyOnPropertyList => {
  return 'amountOfConnections' in props && 'toInstall' in props;
};

export const PropertyCard: FC<ExtendedPropertyOnPropertyList | BasicPropertyOnPropertiesList> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id, description, address, owner, amountOfPremises } = props;
  const connectionsAvailable = checkIfExtended(props);

  return (
    <Paper sx={commonStyles.card}>
      <Grid container sx={styles.header}>
        <Grid
          item
          xs={6}
          component="a"
          onClick={() => navigate(`property/${id}`)}
          sx={mergeStyles([styles.links, styles.linkNetwork])}
        >
          <Typography>{t('properties.button.network')}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          component="a"
          onClick={() => navigate(`radio/property/${id}`)}
          sx={mergeStyles([styles.links, styles.linkRadio])}
        >
          <Typography>{t('properties.button.radioNetwork')}</Typography>
        </Grid>
      </Grid>
      <Typography data-cy={`property-description-${description}`} sx={styles.description}>
        {description}
      </Typography>
      <Grid data-cy={`property-address-${description}`} container sx={styles.infoContainer}>
        <Typography sx={styles.infoLabel}>{t('properties.address.label')}</Typography>
        <Typography sx={styles.infoValue}>{address}</Typography>
      </Grid>
      <Grid data-cy={`property-owner-${description}`} container sx={styles.infoContainer}>
        <Typography sx={styles.infoLabel}>{t('properties.owner.label')}</Typography>
        <Typography sx={styles.infoValue}>{owner}</Typography>
      </Grid>
      <Box component="hr" sx={styles.separateLine} />
      {connectionsAvailable ? (
        <ConnectionsNumbers
          description={description}
          amountOfContracts={props.amountOfConnections}
          amountOfPremises={amountOfPremises}
          toInstall={props.toInstall}
        />
      ) : (
        <Grid container sx={styles.detailsLoaderContainer} role="progressbar">
          <Skeleton variant="rectangular" sx={styles.detailsSekeleton} />
          <Skeleton variant="rectangular" sx={styles.detailsSekeleton} />
          <Skeleton variant="rectangular" sx={styles.detailsSekeleton} />
        </Grid>
      )}
    </Paper>
  );
};
