import { G } from '@mobily/ts-belt';
import { createTheme, PaletteOptions } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

import { colors } from './colors';
import { materialComponentsOverrides } from './materialComponentsOverrides';

declare module '@mui/material/styles' {
  interface Palette {
    line: Palette['primary'];
  }
  interface PaletteOptions {
    line: PaletteOptions['primary'];
  }
}

const palette: PaletteOptions = {
  primary: { main: colors.darkGreen },
  background: { default: colors.mono96 },
  line: { main: colors.mono87 },
  text: { secondary: colors.mono45 },
  error: { main: colors.orange },
};

export const theme = createTheme({
  palette,
  typography: {
    allVariants: {
      fontFamily: '"Cabin", "Roboto", "Helvetica", "Arial", sans-serif',
      letterSpacing: 0,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: materialComponentsOverrides(palette),
});

export type AppTheme = typeof theme;
export type Styles = SystemStyleObject<AppTheme> | ((theme: AppTheme) => SystemStyleObject<AppTheme>);

export const mergeStyles =
  (styles: Styles[]) =>
  (appTheme: AppTheme): SystemStyleObject<AppTheme> => {
    return styles.reduce<SystemStyleObject<AppTheme>>((acc, next) => {
      return G.isFunction(next) ? { ...acc, ...next(appTheme) } : { ...acc, ...next };
    }, {});
  };

export const createStylesMap = <T extends Record<string, Styles>>(stylesMap: T) => stylesMap;
