import { FC } from 'react';
import { useMutation, useQuery } from 'react-query';

import { G } from '@mobily/ts-belt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, MenuItem, Select } from '@mui/material';

import { requestGQL } from '../../../common/graphQlClient';
import { publicUrl } from '../../../config';
import { QUERY_PERSON_BY_ID, QueryPersonByIdResponse, UPDATE_PERSON_BY_ID } from '../../authentication/api';
import { useAuthenticationState } from '../../authentication/state';
import { Language } from '../Language';
import { useInternationalizationState } from '../state';
import * as styles from './LanguagePicker.styles';

const SelectIcon: FC = () => <ExpandMoreIcon sx={styles.icon} />;

export const LanguagePicker: FC = () => {
  const { language, changeLanguage } = useInternationalizationState();
  const { userId } = useAuthenticationState();
  const { data: userData } = useQuery<QueryPersonByIdResponse, unknown>(
    'personById',
    () => requestGQL(QUERY_PERSON_BY_ID, { id: userId! }),
    {
      enabled: G.isNotNullable(userId),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  const { mutate: updateUserLanguage } = useMutation<unknown, unknown, string>((pickedLanguage) =>
    requestGQL(UPDATE_PERSON_BY_ID, {
      id: userId,
      personPatch: {
        settings: { ...userData?.personById.settings, language: pickedLanguage },
      },
    }),
  );

  const handleLanguageChange = (pickedLanguage: Language) => {
    changeLanguage(pickedLanguage);

    if (G.isNotNullable(userData) && G.isNotNullable(userId)) {
      updateUserLanguage(pickedLanguage);
    }
  };

  return (
    <Select
      value={language}
      onChange={(e) => handleLanguageChange(e.target.value as Language)}
      IconComponent={SelectIcon}
      sx={styles.select}
      MenuProps={{ sx: styles.menu }}
      data-testid="language-picker"
    >
      <MenuItem data-testid="menu-item-en" value={'en' as Language}>
        <Box sx={styles.flag} component="img" src={`${publicUrl}/img/icons/en.svg`} alt="british flag" />
        <span>EN</span>
      </MenuItem>
      <MenuItem data-testid="menu-item-sv" value={'sv' as Language}>
        <Box sx={styles.flag} component="img" src={`${publicUrl}/img/icons/sv.svg`} alt="swedish flag" />
        <span>SV</span>
      </MenuItem>
    </Select>
  );
};
