import { DetailsInformation } from 'common/components/DetailsInformation/DetailsInformation';
import { redirectToEmail, redirectToPhone } from 'common/helpers';
import { useTranslation } from 'common/hooks/useTranslation';
import { FC, useMemo } from 'react';

import { Grid } from '@mui/material';

import { PremiseOnSinglePremise } from '../../../../Premise';
import { NotAccessibleNotes } from './components/NotAccessibleNotes';
import { InstallationBookings } from './components/InstallationBookings';
import * as styles from './PremiseInformations.styles';

export const PremiseInformations: FC<{ premise: PremiseOnSinglePremise }> = ({ premise }) => {
  const { t } = useTranslation();

  const textNA = useMemo(() => t('notAvailable.general'), [t]);

  return (
    <Grid container sx={styles.container} data-cy="premise-informations">
      <NotAccessibleNotes premise={premise} />
      <InstallationBookings premise={premise} />
      <Grid container sx={styles.infoSection}>
        <DetailsInformation label={t('premise.tenant')} value={premise.tenant ?? t('premise.empty')} />
      </Grid>
      <Grid container sx={styles.infoSection}>
        {/* TODO: map through the values? */}
        <DetailsInformation label={t('premise.contactPersonTenant')} value={premise.contacts.tenant.name ?? textNA} />
        <DetailsInformation
          label={t('premise.tenantEmail')}
          value={premise.contacts.tenant.email ?? textNA}
          url={premise.contacts.tenant.email ? redirectToEmail(premise.contacts.tenant.email) : undefined}
        />
        <DetailsInformation
          label={t('premise.tenantMobile')}
          value={premise.contacts.tenant.phone ?? textNA}
          url={premise.contacts.tenant.phone ? redirectToPhone(premise.contacts.tenant.phone) : undefined}
        />
      </Grid>
      <Grid container sx={styles.infoSection}>
        <DetailsInformation label={t('premise.contactPersonIT')} value={premise.contacts.it.name ?? textNA} />
        <DetailsInformation
          label={t('premise.ITemail')}
          value={premise.contacts.it.email ?? textNA}
          url={premise.contacts.it.email ? redirectToEmail(premise.contacts.it.email) : undefined}
        />
        <DetailsInformation
          label={t('premise.ITmobile')}
          value={premise.contacts.it.phone ?? textNA}
          url={premise.contacts.it.phone ? redirectToPhone(premise.contacts.it.phone) : undefined}
        />
      </Grid>
    </Grid>
  );
};
