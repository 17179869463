import { useAuthenticationState } from 'app/authentication/state';
// import { AddFiber } from 'app/fibers/components/AddFiber';
import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { useTranslation } from 'common/hooks/useTranslation';
import * as commonStyles from 'common/styles';
import { Lambda } from 'common/types';
import { FC, useCallback, useState } from 'react';
import { useBoolean } from 'usehooks-ts';
import { G } from '@mobily/ts-belt';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Paper } from '@mui/material';
import { DropAntennaWithFibers } from 'app/outlets/DropAntenna';
import { Coax } from 'app/outlets/api';
import { DropAntenna } from 'app/areas/components/ObjectDetails/DropAntenna';
import { DropAntennaDetails } from 'app/areas/types/ObjectDetails';
import { DeleteFiberDialog } from './DeleteFiberDialog';
import { FiberDropAntennaDetails } from './FiberDropAntennaDetails';
import * as styles from './styles';
import { DeleteDropAntennaCoaxialLink } from './DeleteDropAntennaCoaxialLink';

export const SinglePremiseDropAntenna: FC<{
  outlet: DropAntennaWithFibers;
  premise: PremiseOnSinglePremise;
  onDelete: Lambda<void, void>;
}> = ({ outlet, premise, onDelete }) => {
  const { t } = useTranslation();

  const { value: isDeleteFiberOpen, setValue: setIsDeleteFiberOpen } = useBoolean(false);
  const [fiberToDelete, setFiberToDelete] = useState<null | Coax>(null);
  const { userId, roles } = useAuthenticationState();

  const canUserDeleteFiber = useCallback(
    (fiber: Coax) => {
      if (roles.includes('cf_admin') || roles.includes('cf_contractor_pm')) return true;

      const fiberCreatorId = fiber.personId;

      if (fiberCreatorId !== null && userId === fiberCreatorId) return true;

      return false;
    },
    [userId, roles],
  );

  return (
    <>
      <DeleteFiberDialog
        isOpen={G.isNotNullable(fiberToDelete) && isDeleteFiberOpen}
        onClose={() => {
          setFiberToDelete(null);
          setIsDeleteFiberOpen(false);
        }}
      >
        {G.isNotNullable(fiberToDelete) && (
          <DeleteDropAntennaCoaxialLink
            outletId={outlet.id}
            propertyId={premise.propertyId}
            fiber={fiberToDelete}
            onCancel={() => {
              setFiberToDelete(null);
              setIsDeleteFiberOpen(false);
            }}
            onSuccess={() => {
              setFiberToDelete(null);
              setIsDeleteFiberOpen(false);
            }}
          />
        )}
      </DeleteFiberDialog>
      <Grid container sx={styles.outlet}>
        <Paper sx={commonStyles.card}>
          <DropAntenna details={outlet as unknown as DropAntennaDetails} onDelete={onDelete} onSave={() => {}} />
          <Grid container sx={styles.fibersContainer}>
            {outlet.fibers.map((fiber) => (
              <Accordion disableGutters square key={fiber.id} data-cy={`fiber-accordion-${fiber.to.portId}`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon titleAccess="accordion-expand" />}
                  data-cy="fiber-accordion-header"
                >
                  {fiber.to.portId}
                </AccordionSummary>
                <AccordionDetails data-cy="fiber-accordion-details">
                  <FiberDropAntennaDetails fiber={fiber} nodeId={outlet.id} propertyId={premise.propertyId} />
                  {canUserDeleteFiber(fiber) && (
                    <Grid sx={styles.deleteFiberContainer}>
                      <Button
                        variant="text"
                        color="error"
                        onClick={() => {
                          setFiberToDelete(fiber);
                          setIsDeleteFiberOpen(true);
                        }}
                      >
                        <DeleteIcon /> {t('coax.delete.button')}
                      </Button>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
          <Grid container sx={styles.centerContainer}>
            {/* TODO: add fiber, or other connection here if nessesary ? */}
            {/* <AddFiber premise={premise} outlet={outlet} outletType={AvailableOutletTypes.TPOUTLET} /> */}
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};
