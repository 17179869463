import { PremiseOutlets } from 'app/outlets/components/PremiseOutlets/PremiseOutlets';
import { PremiseTPOutlets } from 'app/outlets/components/PremiseTPOutlets/PremiseTPOutlets';
import { useOutlets } from 'app/outlets/hooks/useOutlets';
import { useTPOutlets } from 'app/outlets/hooks/useTPOutlets';
import { RoutesParamsMap } from 'app/routes/Route';
import { optionParseInt } from 'common/helpers';
import { useTranslation } from 'common/hooks/useTranslation';
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { CircularProgress, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';

import { O, G } from '@mobily/ts-belt';
import { usePremise } from '../../hooks/usePremise';
import { PremiseInformations } from './components/PremiseInformations/PremiseInformations';
import * as styles from './SinglePremise.styles';

export const PremiseContext = createContext<{ id: O.Option<number> }>({ id: O.None });

export const usePremiseContext = () => {
  const context = useContext(PremiseContext);

  if (G.isNullable(context)) {
    throw new Error('No Premise Context available.');
  }

  return context;
};

export const SinglePremise: FC = () => {
  const params = useParams<RoutesParamsMap['premise/:id']>();
  const parsedId = useMemo(() => optionParseInt(params.id), [params]);
  const { isLoadingPremise, isErrorPremise, premise } = usePremise(parsedId);
  const { isLoadingOutlets, isErrorOutlets, outlets } = useOutlets(premise);
  const { isLoadingTPOutlets, isErrorTPOutlets, tpOutlets } = useTPOutlets(premise);
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  const premiseContextValue = useMemo(() => ({ id: premise?.propertyId }), [premise]);

  useEffect(() => {
    if (isErrorPremise || isErrorOutlets) {
      toast.error(t('error.generalMessage'));
    }
  }, [isErrorPremise, isErrorOutlets, t]);

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.titleContainer}>
        <IconButton
          data-cy="go-back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon titleAccess="back" sx={styles.backIcon} />
          {!isLoadingPremise && !isErrorPremise && premise && (
            <Grid container>
              <Typography sx={styles.header}>{premise.name}</Typography>
            </Grid>
          )}
        </IconButton>
      </Grid>

      <Tabs variant="fullWidth" value={tabIndex} onChange={(_, v) => setTabIndex(v)}>
        <Tab
          data-cy="tab-informations"
          label={<Typography sx={styles.tabLabel}>{t('premise.informations')}</Typography>}
        />
        <Tab data-cy="tab-outlets" label={<Typography sx={styles.tabLabel}>{t('premise.outlets')}</Typography>} />
        <Tab data-cy="tab-tpoutlets" label={<Typography sx={styles.tabLabel}>{t('premise.tpoutlets')}</Typography>} />
      </Tabs>
      <PremiseContext.Provider value={premiseContextValue}>
        <Grid container sx={styles.contentContainer}>
          {isLoadingPremise && tabIndex === 0 && <CircularProgress />}
          {!isLoadingPremise && !isErrorPremise && tabIndex === 0 && premise && (
            <PremiseInformations premise={premise} />
          )}

          {isLoadingOutlets && tabIndex === 1 && <CircularProgress />}
          {!isLoadingOutlets && !isErrorOutlets && tabIndex === 1 && outlets && premise && (
            <PremiseOutlets outlets={outlets} premise={premise} />
          )}

          {isLoadingTPOutlets && tabIndex === 2 && <CircularProgress />}
          {!isLoadingTPOutlets && !isErrorTPOutlets && tabIndex === 2 && outlets && premise && (
            <PremiseTPOutlets tpOutlets={tpOutlets} premise={premise} />
          )}
        </Grid>
      </PremiseContext.Provider>
    </Grid>
  );
};
