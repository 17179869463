import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_FIRE_BREACH = gql`
  mutation patchFireBreach($input: BasicObjectInput!) {
    patchFireBreach(input: $input)
  }
`;

export const patchFireBreach = (propertyId: number) => (basicObjectDetails: any) => {
  return requestGQL(PATCH_FIRE_BREACH, { input: { propertyId, basicObjectDetails } });
};
