import { Styles } from 'app/theme/materialTheme';

export const card: Styles = {
  cursor: 'pointer',
  textDecoration: 'unset',
};

export const name: Styles = { fontSize: 20, fontWeight: 700 };

export const description: Styles = (theme) => ({ fontSize: 15, color: theme.palette.text.secondary });

export const tenant: Styles = { fontSize: 18, mt: 0.5, overflow: 'hidden' };

export const accentText: Styles = (theme) => ({ color: theme.palette.primary.main, fontSize: 18, mt: 0.5 });

export const secondaryText: Styles = (theme) => ({ color: theme.palette.text.secondary, fontSize: 18 });

export const tenantContainer: Styles = { alignItems: 'center', columnGap: 0.5 };

export const connectionsContainer: Styles = {
  mt: 2,
  flexDirection: 'column',
};

export const connector: Styles = (theme) => ({
  py: 0.5,
  borderBottom: `1px solid ${theme.palette.line.main}`,
  '&:first-of-type': {
    borderTop: `1px solid ${theme.palette.line.main}`,
  },
});

export const tagsContainer: Styles = {
  mt: 1,
  rowGap: 1,
  columnGap: 0.5,
};

export const tag: Styles = {
  flex: '0',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: 0.5,
};

export const tagText: Styles = (theme) => ({
  color: theme.palette.primary.main,
  whiteSpace: 'nowrap',
});
