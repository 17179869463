import { useTranslation } from 'common/hooks/useTranslation';
import { clientID, logoutRedirectUrl, logoutUrl, publicUrl } from 'config';
import { FC, useMemo, useState } from 'react';

import { G } from '@mobily/ts-belt';

import { AppBar, Box, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';

import { mergeStyles } from 'app/theme/materialTheme';
import { useLocation } from 'react-router-dom';
import { LanguagePicker } from '../../internationalization/components/LanguagePicker';
import * as styles from './AppTopbar.styles';

const appLogoutUrl = `${logoutUrl}?client_id=${clientID}&post_logout_redirect_uri=${logoutRedirectUrl}`;

export const AppTopbar: FC = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const currentLocation = useLocation();

  const borderBottomConditional = useMemo(() => {
    if (currentLocation.pathname.length < 2) {
      return {};
    }
    return currentLocation.pathname.includes('radio') ? styles.topbarRadioNetwork : styles.topbarNetwork;
  }, [currentLocation.pathname]);

  return (
    <AppBar sx={mergeStyles([styles.topbar, borderBottomConditional])}>
      <Grid>
        <Box sx={styles.topbarLogo} component="img" src={`${publicUrl}/img/icons/topbarLogo.svg`} alt="logo" />
      </Grid>
      <Grid sx={styles.topbarRight}>
        <LanguagePicker />
        <IconButton
          data-cy="user-button"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <Box component="img" src={`${publicUrl}/img/icons/user.svg`} alt="user" />
        </IconButton>
        <Menu anchorEl={anchorEl} open={G.isNotNullable(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem dense sx={styles.menuItemError} onClick={() => location.replace(appLogoutUrl)}>
            <ListItemIcon>
              <Box component="img" src={`${publicUrl}/img/icons/logout.svg`} alt="logout" />
            </ListItemIcon>
            <ListItemText>{t('login.logout.menuOption')}</ListItemText>
          </MenuItem>
        </Menu>
      </Grid>
    </AppBar>
  );
};
