import { optionGet } from 'common/helpers';
import { Lambda } from 'common/types';

import { A, G, O, pipe } from '@mobily/ts-belt';

import {
  CFObject as ConnectionNodeCFObject,
  LinkData as ConnectionLinkData,
  NodeData as ConnectionNodeData,
  NodesData as ConnectionNodesData,
  SerializedData as ConnectionSerializedData,
} from '../connection/api';

export const getNodesData = (serializedData: ConnectionSerializedData) => serializedData.nodeDataArray;

export const getLinksData = (serializedData: ConnectionSerializedData) => serializedData.linkDataArray;

export const getDataCFObject = (node: ConnectionLinkData | ConnectionNodeData) => {
  return node.cfObject ?? null;
};

export const getOutletsNodesByPremiseId =
  (premiseId: number) =>
  (connectionNodes: ConnectionNodesData): ConnectionNodesData => {
    return pipe(
      connectionNodes,
      A.find((connection) => {
        return getDataCFObject(connection)?.premisesId === premiseId;
      }),
      optionGet('id'),
      O.flatMap((id) =>
        pipe(
          connectionNodes,
          A.filter((connection) => {
            return connection.model === 'outlet';
          }),
          A.filter((connection) => {
            return connection.group === id || connection.group === `${id}` || `${connection.group}` === `${premiseId}`;
          }),
        ),
      ),
      O.getWithDefault([] as ConnectionNodesData),
    );
  };

export const getTPOutletsNodesByPremiseId =
  (premiseId: number) =>
  (connectionNodes: ConnectionNodesData): ConnectionNodesData => {
    return pipe(
      connectionNodes,
      A.find((connection) => getDataCFObject(connection)?.premisesId === premiseId),
      optionGet('id'),
      O.flatMap((id) =>
        pipe(
          connectionNodes,
          A.filter((connection) => connection.model === 'tpOutlet'),
          A.filter(
            (connection) =>
              connection.group === id || connection.group === `${id}` || `${connection.group}` === `${premiseId}`,
          ),
        ),
      ),
      O.getWithDefault([] as ConnectionNodesData),
    );
  };

export const getRadioOutletsNodesByPremiseId =
  (premiseId: number) =>
  (connectionNodes: ConnectionNodesData): ConnectionNodesData => {
    return pipe(
      connectionNodes,
      A.find((connection) => getDataCFObject(connection)?.premisesId === premiseId),
      optionGet('id'),
      O.flatMap((id) =>
        pipe(
          connectionNodes,
          A.filter(
            (connection) =>
              connection.model === 'coupler' || connection.model === 'dropAntenna' || connection.model === 'splitter',
          ),
          A.filter(
            (connection) =>
              connection.group === id || connection.group === `${id}` || `${connection.group}` === `${premiseId}`,
          ),
        ),
      ),
      O.getWithDefault([] as ConnectionNodesData),
    );
  };

export const getNodePhotos = (connectionNode: ConnectionNodeData) => {
  return getDataCFObject(connectionNode)?.images ?? [];
};

export const getNodeLocation = (connectionNode: ConnectionNodeData) => {
  return getDataCFObject(connectionNode)?.location ?? null;
};

export const getNodeContectType = (connectionNode: ConnectionNodeData) => {
  return getDataCFObject(connectionNode)?.contactType ?? '-';
};

export const getODFNodeByFiber = (serializedData: ConnectionSerializedData, fiberNode: ConnectionLinkData) => {
  return getNodesData(serializedData).find((node) => node.model === 'odf' && node.id === fiberNode.from);
};

export const getAnyNodeByFiber = (serializedData: ConnectionSerializedData, fiberNode: ConnectionLinkData) => {
  return getNodesData(serializedData).find((node) => node.id === fiberNode.from);
};

export const getCrossConnectionPanelNodeByFiber = (
  serializedData: ConnectionSerializedData,
  fiberNode: ConnectionLinkData,
) => {
  return getNodesData(serializedData).find((node) => node.model === 'crossPanel' && node.id === fiberNode.from);
};

export const getFibersNodesByOutlet = (serializedData: ConnectionSerializedData, outletNode: ConnectionNodeData) => {
  return getLinksData(serializedData).filter(({ to }) => to === outletNode.id);
};

export const updateOutlet = (
  serializedData: ConnectionSerializedData,
  outletId: string,
  cfObjectTransformationFn: Lambda<ConnectionNodeCFObject, void>,
) => {
  const outlet = serializedData.nodeDataArray.find((connection) => connection.id === outletId);

  if (G.isNotNullable(outlet)) {
    if (G.isNullable(outlet.cfObject)) {
      outlet.cfObject = {};
    }

    cfObjectTransformationFn(outlet.cfObject);
  }

  return serializedData;
};
