export enum NodeOptions {
  INCOMING_FIBER = 'Incoming Fiber',
  TRUNK = 'Trunk',
  SWITCH = 'Switch',
  ODF = 'ODF',
  TECHNICAL_SWITCH = 'Technical Network',
  TECHNICAL_ODF = 'Technical ODF',
  CROSS_PANEL = 'Cross Connection Panel',
  TP_OUTLET = 'TP Outlet',
  WIFI_DOT = 'WiFi Spot',
  TECHNICAL_EQUIPMENT = 'Technical Equipment',
  FIRE_BREACH = 'Fire Breach',
  RACK = 'Rack',
  CABLE_ROUTE = 'Cable Route',
}
