import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { requestGQL } from 'common/graphQlClient';
import { useTranslation } from 'common/hooks/useTranslation';
import { isTestEnv } from 'config';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useDebounce } from 'usehooks-ts';

import { G } from '@mobily/ts-belt';
import PendingIcon from '@mui/icons-material/Pending';
import { FormControl, Grid, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';

import { DropAntennaWithFibers } from 'app/outlets/DropAntenna';
import { UPDATE_OUTLET_LOCATION } from '../../api';
import { Outlet } from '../../Outlet';
import { styles } from './HowToFindOutlet.styles';
import { TPOutlet } from '../../TPOutlet';

export const HowToFindOutlet: FC<{
  outlet: Outlet | TPOutlet | DropAntennaWithFibers;
  premise: PremiseOnSinglePremise;
}> = ({ outlet, premise }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [outletLocation, setOutletLocation] = useState(outlet.location);
  const debouncedOutletLocation = useDebounce(outletLocation, !isTestEnv ? 1000 : 50);

  const { mutate, isLoading } = useMutation<string, unknown, { location: string }>(
    async ({ location }) =>
      requestGQL(UPDATE_OUTLET_LOCATION, {
        propertyId: premise.propertyId,
        outletId: outlet.id,
        location,
      }),
    {
      retry: 0,
      onSuccess: () => {
        toast.success(t('general.updatedSuccessfully'));
        queryClient.invalidateQueries(['outletsByPropertyId', premise.propertyId]);
      },
    },
  );

  const locationInputValue = outletLocation ?? '';

  const handleSetOutletChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOutletLocation(e.target.value);
  };

  useEffect(() => {
    if (G.isNotNullable(debouncedOutletLocation) && debouncedOutletLocation !== outlet.location) {
      mutate({ location: debouncedOutletLocation });
    }
  }, [debouncedOutletLocation]);

  return (
    <Grid container sx={styles.locationContainer}>
      <FormControl fullWidth>
        <InputLabel htmlFor="howToFind">{t('premise.howToFind')}</InputLabel>
        <OutlinedInput
          id="howToFind"
          multiline
          rows={2}
          value={locationInputValue}
          onChange={handleSetOutletChange}
          endAdornment={
            isLoading ? (
              <InputAdornment position="end" sx={styles.loadingAdornment}>
                <PendingIcon />
              </InputAdornment>
            ) : null
          }
        />
      </FormControl>
    </Grid>
  );
};
