import { O } from '@mobily/ts-belt';
import { Dialog } from '@mui/material';
import { FC, useState } from 'react';
import { NodeOptions } from 'app/areas/NodeOptions';
import { RadioNodeOptions } from 'app/areas/RadioNodeOptions';
import { SelectButton } from '../../ui/SelectButton';
import { CreateCableRoute } from './CreateCableRoute';
import { CreateCrossPanel } from './CreateCrossPanel';
import { CreateFireBreach } from './CreateFireBreach';
import { CreateIncomingFiber } from './CreateIncomingFiber';
import { CreateODF } from './CreateODF';
import { CreateRack } from './CreateRack';
import { CreateSwitch } from './CreateSwitch';
import { CreateTrunk } from './CreateTrunk';
import { CreateTpOutlet } from './CreateTpOutlet';
import { CreateTechnicalEquipment } from './CreateTechnicalEquipment';
import { CreateTechnicalODF } from './CreateTechnicalODF';
import { CreateTechnicalSwitch } from './CreateTechnicalSwitch';
import { CreateWiFiDot } from './CreateWiFiDot';
import { CreateBaseStation } from './CreateBaseStation';
import { CreateDropAntenna } from './CreateDropAntenna';
import { CreateRadioUnit } from './CreateRadioUnit';
import { CreateInputAntenna } from './CreateInputAntenna';
import { CreateCombiner } from './CreateCombiner';
import { CreateCoupler } from './CreateCoupler';
import { CreateRepeater } from './CreateRepeater';
import { CreateSplitter } from './CreateSplitter';

type SelectedObjectModel = O.Option<NodeOptions> | O.Option<RadioNodeOptions>;

export const CreateObject: FC<{ isRadio?: boolean }> = ({ isRadio = false }) => {
  const [createObjectModel, setCreateObjectModel] = useState<SelectedObjectModel>(O.None);

  const closeCreateObjectDialog = () => {
    setCreateObjectModel(O.None);
  };

  return (
    <>
      <SelectButton
        options={Object.values(isRadio ? RadioNodeOptions : NodeOptions)}
        onSelect={(option) => {
          setCreateObjectModel(option);
        }}
      />
      <Dialog
        open={O.isSome(createObjectModel)}
        onClose={closeCreateObjectDialog}
        fullWidth
        PaperProps={{ sx: { margin: 0.5, p: 2, width: 375 } }}
      >
        {isRadio ? (
          <>
            {createObjectModel === RadioNodeOptions.BASE_STATION && (
              <CreateBaseStation onSave={closeCreateObjectDialog} />
            )}
            {createObjectModel === RadioNodeOptions.DROP_ANTENNA && (
              <CreateDropAntenna onSave={closeCreateObjectDialog} />
            )}
            {createObjectModel === RadioNodeOptions.RADIO_UNIT && <CreateRadioUnit onSave={closeCreateObjectDialog} />}
            {createObjectModel === RadioNodeOptions.INPUT_ANTENNA && (
              <CreateInputAntenna onSave={closeCreateObjectDialog} />
            )}
            {createObjectModel === RadioNodeOptions.COMBINER && <CreateCombiner onSave={closeCreateObjectDialog} />}
            {createObjectModel === RadioNodeOptions.COUPLER && <CreateCoupler onSave={closeCreateObjectDialog} />}
            {createObjectModel === RadioNodeOptions.REPEATER && <CreateRepeater onSave={closeCreateObjectDialog} />}
            {createObjectModel === RadioNodeOptions.SPLITTER && <CreateSplitter onSave={closeCreateObjectDialog} />}
          </>
        ) : (
          <>
            {createObjectModel === NodeOptions.INCOMING_FIBER && (
              <CreateIncomingFiber onSave={closeCreateObjectDialog} />
            )}
            {createObjectModel === NodeOptions.TRUNK && <CreateTrunk onSave={closeCreateObjectDialog} />}
            {createObjectModel === NodeOptions.SWITCH && <CreateSwitch onSave={closeCreateObjectDialog} />}
            {createObjectModel === NodeOptions.ODF && <CreateODF onSave={closeCreateObjectDialog} />}
            {createObjectModel === NodeOptions.TECHNICAL_SWITCH && (
              <CreateTechnicalSwitch onSave={closeCreateObjectDialog} />
            )}
            {createObjectModel === NodeOptions.TECHNICAL_ODF && <CreateTechnicalODF onSave={closeCreateObjectDialog} />}
            {createObjectModel === NodeOptions.CROSS_PANEL && <CreateCrossPanel onSave={closeCreateObjectDialog} />}
            {createObjectModel === NodeOptions.TP_OUTLET && <CreateTpOutlet onSave={closeCreateObjectDialog} />}
            {createObjectModel === NodeOptions.WIFI_DOT && <CreateWiFiDot onSave={closeCreateObjectDialog} />}
            {createObjectModel === NodeOptions.TECHNICAL_EQUIPMENT && (
              <CreateTechnicalEquipment onSave={closeCreateObjectDialog} />
            )}
            {createObjectModel === NodeOptions.FIRE_BREACH && <CreateFireBreach onSave={closeCreateObjectDialog} />}
            {createObjectModel === NodeOptions.RACK && <CreateRack onSave={closeCreateObjectDialog} />}
            {createObjectModel === NodeOptions.CABLE_ROUTE && <CreateCableRoute onSave={closeCreateObjectDialog} />}
          </>
        )}
      </Dialog>
    </>
  );
};
