import { RoutesParamsMap } from 'app/routes/Route';
import { optionParseInt } from 'common/helpers';
import { useTranslation } from 'common/hooks/useTranslation';
import { publicUrl } from 'config';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { G } from '@mobily/ts-belt';
import { Box, CircularProgress, Grid, List, ListItem, Typography } from '@mui/material';

import { usePremisesList } from '../../hooks/usePremisesList';
import { PremiseCard } from './components/PremiseCard';
import * as styles from './PremisesList.styles';

export const PremisesList: FC<{ isRadio?: boolean }> = ({ isRadio = false }) => {
  const params = useParams<RoutesParamsMap['property/:id']>();
  const parsedId = useMemo(() => optionParseInt(params.id), [params]);

  const { t } = useTranslation();

  const { data, isLoading, isError } = usePremisesList(parsedId);

  if (isLoading) return <CircularProgress sx={styles.loader} />;

  if (isError || G.isNullable(data))
    return (
      <Grid container sx={styles.warningContainer}>
        <Box component="img" src={`${publicUrl}/img/icons/warning.svg`} alt="warning" />
        <Typography sx={styles.warningInfo}>{t('error.generalMessage')}</Typography>
      </Grid>
    );

  return (
    <Grid container>
      <List disablePadding sx={styles.list}>
        {data.map((premise) => (
          <ListItem data-cy={`premise.name ${premise.name}`} key={premise.id} disablePadding>
            <PremiseCard isRadio={isRadio} premise={premise} />
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};
