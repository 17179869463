import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useTranslation = () => {
  const intl = useIntl();

  const t = useCallback((id: string) => intl.formatMessage({ id }), [intl.formatMessage]);

  return { t, ...intl };
};
