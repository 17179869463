import { requestGQL } from 'common/graphQlClient';
import { useMutation, useQueryClient } from 'react-query';

import { DELETE_FIBER } from '../api';

export const useDeleteFiber = (propertyId: null | number, outletId: null | string) => {
  const queryClient = useQueryClient();

  const { data, mutate, isLoading, isError, isSuccess } = useMutation<string, unknown, { fiberId: string }>(
    async ({ fiberId }) =>
      requestGQL(DELETE_FIBER, {
        propertyId,
        fiberId,
        outletId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['outletsByPropertyId', propertyId]);
        queryClient.invalidateQueries(['propertyConnection', propertyId]);
        queryClient.invalidateQueries(['propertyRadioConnection', propertyId]);
        queryClient.invalidateQueries(['allPremises', propertyId]);
      },
    },
  );

  return { data, mutate, isLoading, isError, isSuccess };
};
