import { requestGQL } from 'common/graphQlClient';
import { useMutation, useQueryClient } from 'react-query';

import { DELETE_TPOUTLET } from '../api';

export const useDeleteTPOutlet = (propertyId: null | number, outletId: null | string) => {
  const queryClient = useQueryClient();

  return useMutation<string>(
    async () =>
      requestGQL(DELETE_TPOUTLET, {
        propertyId,
        tpOutletId: outletId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['outletsByPropertyId', propertyId]);
      },
      retry: false,
    },
  );
};
