import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const DELETE_FIRE_BREACH = gql`
  mutation deleteFireBreach($input: DeleteNodeInput!) {
    deleteFireBreach(input: $input)
  }
`;

export const deleteFireBreach = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_FIRE_BREACH, { input: { propertyId, nodeId } });
};
