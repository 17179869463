import { z } from 'zod';

export const combinerSchema = z.object({
  text: z.string().min(1),
  location: z.string(),
  leftPortCount: z.number().int().min(1),
  rightPortCount: z.number().int().min(1),
  unitType: z.string(),
  serialNumber: z.string(),
});

export type CombinerSchema = z.infer<typeof combinerSchema>;
