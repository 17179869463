import { authenticationState } from 'app/authentication/state';
import axios from 'axios';
import { apiUri } from 'config';
import { gql } from 'graphql-request';

import { SerializedData as ConnectionSerializedData } from '../connection/api';

export type SinglePropertyGetProperiesResponse = {
  __typename: 'Property';
  city: string;
  id: number;
  description: string;
  address: string;
  companyByCompany: {
    name: string;
  };
  premisesByPropertyId: {
    totalCount: number;
  };
};

export type GetPropertiesResponse = {
  allProperties: {
    nodes: SinglePropertyGetProperiesResponse[];
  };
};

export const GET_PROPERTIES = gql`
  query allProperties {
    allProperties(condition: { archived: false }) {
      nodes {
        id
        city
        description
        address
        premisesByPropertyId {
          totalCount
        }
        companyByCompany {
          name
        }
      }
    }
  }
`;

export type SinglePropertyGetConnectionsResponse = {
  id: number;
  connectionByConnectionId?: {
    serialized2?: ConnectionSerializedData;
  };
};

export type GetPropertiesConnectionsResponse = {
  allProperties: {
    nodes: SinglePropertyGetConnectionsResponse[];
  };
};

export const GET_PROPERTIES_CONNECTIONS = gql`
  query allPropertiesConnections {
    allProperties {
      nodes {
        id
        connectionByConnectionId {
          serialized2
        }
      }
    }
  }
`;

export type PropertyFiberChecklist = {
  wire: boolean;
  fire: boolean;
  duct: boolean;
  clean: boolean;
  node: boolean;
};

export type GetPropertyFiberChecklistResponse = {
  fiberChecklistByPropertyId: null | {
    checklist: PropertyFiberChecklist;
    nodeId: string;
    checklistChangedBy: string;
    checklistChangedAt: string;
  };
};

export const GET_PROPERTY_FIBER_CHECKLIST = gql`
  query fiberChecklistByPropertyId($propertyId: Int!) {
    fiberChecklistByPropertyId(propertyId: $propertyId) {
      checklistChangedAt
      checklistChangedBy
      nodeId
      checklist
    }
  }
`;

export const CREATE_FIBER_PROPERTY_CHECKLIST = gql`
  mutation createFiberChecklist($propertyId: Int!, $checklist: JSON!) {
    createFiberChecklist(input: { fiberChecklist: { propertyId: $propertyId, checklist: $checklist } }) {
      clientMutationId
    }
  }
`;

export const UPDATE_FIBER_PROPERTY_CHECKLIST_BY_PROPERTY_ID = gql`
  mutation updateFiberChecklistByPropertyId($propertyId: Int!, $fiberChecklistPatch: JSON!) {
    updateFiberChecklistByPropertyId(
      input: { fiberChecklistPatch: { checklist: $fiberChecklistPatch }, propertyId: $propertyId }
    ) {
      clientMutationId
    }
  }
`;

export type GetPropertyResponse = {
  propertyById: {
    __typename: 'Property';
    id: number;
    description: string;
    address: string;
    city: string;
    premisesByPropertyId: {
      totalCount: number;
    };
    companyByCompany: {
      name: string;
    };
    propertyPeopleByPropertyId: {
      nodes: (null | {
        role: string;
        personByPersonId: {
          displayName: string;
          email: string;
          mobilePhone: string;
          role: string;
        };
      })[];
    };
    propertyAttachmentsByPropertyId: {
      nodes: (null | {
        filename: string;
      })[];
    };
    propertyContactPerson:
      | null
      | {
          name: string;
          phone: string;
          email: string;
          role: string;
          notes: string;
          hideInInstaller?: boolean;
        }
      | {
          name: string;
          phone: string;
          email: string;
          role: string;
          notes: string;
          hideInInstaller?: boolean;
        }[];
    fiberChecklistByPropertyId: null | {
      checklist: PropertyFiberChecklist;
      nodeId: string;
      checklistChangedBy: string;
      checklistChangedAt: string;
    };
  };
};

export const GET_PROPERTY = gql`
  query propertyById($id: Int!) {
    propertyById(id: $id) {
      id
      description
      address
      city
      companyByCompany {
        name
      }
      premisesByPropertyId {
        totalCount
      }
      propertyPeopleByPropertyId {
        nodes {
          role
          personByPersonId {
            displayName
            email
            mobilePhone
          }
        }
      }
      propertyAttachmentsByPropertyId {
        nodes {
          filename
        }
      }
      propertyContactPerson
      fiberChecklistByPropertyId {
        checklist
        nodeId
        checklistChangedBy
        checklistChangedAt
      }
    }
  }
`;

export type GetAzureSignedLinkResponse = {
  container: 'outlet';
  query: string;
  url: string;
};

export const createSignedLinkToDownloadPropertyFile = async (fileName: string) => {
  const { token } = authenticationState();
  return axios
    .post<GetAzureSignedLinkResponse>(
      `${apiUri}/api/v1/azurestorage/download/property`,
      { contentDispositionFilename: fileName },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => response.data);
};
