import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const DELETE_INPUT_ANTENNA = gql`
  mutation deleteInputAntenna($input: DeleteNodeInput!) {
    deleteInputAntenna(input: $input)
  }
`;

export const deleteInputAntenna = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_INPUT_ANTENNA, { input: { propertyId, nodeId } });
};
