import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_TPOUTLET = gql`
  mutation createTpOutlet($input: TpOutletInput!) {
    createTpOutlet(input: $input)
  }
`;

export const createTpOutlet = (propertyId: number) => (tpOutletDetails: any) => {
  return requestGQL(CREATE_TPOUTLET, { input: { propertyId, tpOutletDetails } });
};
