import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_COUPLER = gql`
  mutation patchCoupler($input: CouplerInput!) {
    patchCoupler(input: $input)
  }
`;

export const patchCoupler = (propertyId: number) => (couplerDetails: any) => {
  return requestGQL(PATCH_COUPLER, { input: { propertyId, couplerDetails } });
};
