import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const DELETE_RACK = gql`
  mutation deleteRack($input: DeleteNodeInput!) {
    deleteRack(input: $input)
  }
`;

export const deleteRack = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_RACK, { input: { propertyId, nodeId } });
};
