import { FC } from 'react';

import { G } from '@mobily/ts-belt';
import { Grid, Typography } from '@mui/material';

import { mergeStyles } from '../../../app/theme/materialTheme';
import * as styles from './DetailsInformation.styles';

export const DetailsInformation: FC<{
  label: string;
  value: string;
  url?: string;
}> = ({ label, value, url }) => {
  return (
    <Grid container sx={styles.container}>
      <Typography sx={styles.label}>{label}</Typography>
      <Typography
        sx={mergeStyles([styles.value, url ? styles.linkValue : {}])}
        component={G.isNullable(url) ? 'p' : 'a'}
        href={G.isNullable(url) ? undefined : url}
      >
        {value}
      </Typography>
    </Grid>
  );
};
