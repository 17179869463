import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { O } from '@mobily/ts-belt';
import { useCreateCableRoute } from '../../hooks/useCreate';
import { cableRouteSchema, CableRouteSchema } from '../../types/CableRouteSchema';
import { PhotosField } from '../../ui/PhotosField';

export const CreateCableRoute: FC<{ onSave: Lambda<void, void> }> = ({ onSave }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CableRouteSchema>({ mode: 'onChange', resolver: zodResolver(cableRouteSchema) });
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  const [formValues, setFormValues] = useState({} as O.Option<CableRouteSchema>);
  const [allChecked, setAllChecked] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });
  const [photosURLs, setPhotosURLs] = useState<ReadonlyArray<string>>([]);
  const { mutate, isLoading } = useCreateCableRoute({
    onSuccess: () => {
      onSave();
      toast.success(t('general.createdSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  useEffect(() => {
    setAllChecked(checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3);
  }, [checkboxes]);

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: CableRouteSchema) => {
    setFormValues(values);
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleDialogConfirm = () => {
    mutate({ ...formValues, photosURLs });
    setShowDialog(false);
  };

  const handleCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({ ...checkboxes, [name]: event.target.checked });
  };

  return (
    <>
      <Dialog open={showDialog} onClose={handleDialogClose} PaperProps={{ sx: { margin: 0.5, p: 2 } }}>
        <DialogTitle>{t('checklist.title')}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checkboxes.checkbox1} onChange={handleCheckboxChange('checkbox1')} />}
                label={t('checklist.device.1')}
              />
              <FormControlLabel
                control={<Checkbox checked={checkboxes.checkbox2} onChange={handleCheckboxChange('checkbox2')} />}
                label={t('checklist.device.2')}
              />
              <FormControlLabel
                control={<Checkbox checked={checkboxes.checkbox3} onChange={handleCheckboxChange('checkbox3')} />}
                label={t('checklist.cleaned')}
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t('general.cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleDialogConfirm} disabled={!allChecked}>
            {t('general.create')}
          </Button>
        </DialogActions>
      </Dialog>
      <Stack component="form" gap={0.5} onSubmit={handleSubmit(handleSave)}>
        <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.cableRoute.label')}</Typography>
        <Stack gap={2} width="100%">
          <PhotosField value={photosURLs} onChange={setPhotosURLs} />
          <TextField label={t('areas.fields.name')} {...register('text')} helperText={getErrorMessage('text')} />
          <TextField label={t('areas.fields.location')} {...register('location')} />
        </Stack>
        <Grid container justifyContent="flex-end" mt={2}>
          <Button variant="contained" type="submit" disabled={!isValid || isLoading}>
            {isLoading ? <CircularProgress size={16} /> : t('general.save')}
          </Button>
        </Grid>
      </Stack>
    </>
  );
};
