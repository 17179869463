import { z } from 'zod';

export const wifiDotSchema = z.object({
  text: z.string().min(1),
  portCount: z.number().min(0).int(),
  location: z.string(),
  ip: z.string(),
  mac: z.string(),
  serialNumber: z.string(),
});

export type WifiDotSchema = z.infer<typeof wifiDotSchema>;
