import { z } from 'zod';

export const switchSchema = z.object({
  text: z.string().min(1),
  connectionsCount: z.number().min(0).int(),
  location: z.string(),
  model: z.string(),
  brand: z.string(),
  propertyOf: z.string(),
  installedAt: z.string(),
  contactType: z.string(),
  incomingIP: z.string(),
  switchType: z.string(),
});

export type SwitchSchema = z.infer<typeof switchSchema>;
