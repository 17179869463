import { Styles } from '../../theme/materialTheme';

export const container: Styles = {
  mt: 9,
  flexDirection: 'column',
  alignItems: 'center',
};

export const titleContainer: Styles = {
  alignItems: 'center',
  flexWrap: 'nowrap',
};

export const backIcon: Styles = (theme) => ({
  color: theme.palette.primary.main,
});

export const tabLabel: Styles = {
  fontSize: 'inherit',
};

export const premisesAmount: Styles = (theme) => ({
  color: theme.palette.primary.main,
});

export const tabLabelContainer: Styles = {
  flexWrap: 'nowrap',
  justifyContent: 'center',
  gap: 0.5,
};
