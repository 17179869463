import { G } from '@mobily/ts-belt';
import { Box, List, ListItem } from '@mui/material';
import { Lambda } from 'common/types';
import { FC } from 'react';
import { ImagePicker } from '../components/ImagePicker';

export const PhotosField: FC<{
  value: ReadonlyArray<string>;
  onChange: Lambda<ReadonlyArray<string>, void>;
  onItemClick?: Lambda<string, void>;
}> = ({ value, onChange, onItemClick }) => {
  return (
    <List sx={{ display: 'flex', gap: 1, p: 0, flexWrap: 'wrap' }}>
      {value.map((photoUrl, index) => (
        <ListItem
          key={`incoming-fiber-${photoUrl}}`}
          sx={{ p: 0, flex: '0 0', cursor: G.isNotNullable(onItemClick) ? 'pointer' : 'unset' }}
          onClick={() => {
            if (G.isNotNullable(onItemClick)) {
              onItemClick(photoUrl);
            }
          }}
        >
          <Box component="img" src={photoUrl} alt={`incoming-fiber-image-${index}`} width={96} height={96} />
        </ListItem>
      ))}
      <ListItem sx={{ p: 0, flex: '0 0' }}>
        <ImagePicker
          onChange={(imageUrl) => {
            onChange([...value, imageUrl]);
          }}
        />
      </ListItem>
    </List>
  );
};
