import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { publicUrl } from 'config';
import { FC, useState } from 'react';
import { Box, Button, Dialog, DialogContent, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { Outlet } from '../../../Outlet';
import * as styles from './styles';
import { TPOutlet } from '../../../TPOutlet';

type Props = {
  isOpen: boolean;
  photoUrl: null | string;
  outlet: Outlet | TPOutlet;
  onClose?: Lambda<void, void>;
  onDelete?: Lambda<void, void>;
};

export const EnlargedPhotoDialog: FC<Props> = ({ isOpen, photoUrl, onClose, onDelete, outlet }) => {
  const [isConfirmationWindowOpen, setIsConfirmationWindowOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Dialog open={isConfirmationWindowOpen} PaperProps={{ sx: styles.photoDeleteConfirmationDialog }}>
        <Toolbar sx={styles.dialogTopbar}>
          <Typography>{t('premise.deletePhoto.confirmationText')}</Typography>
          <IconButton size="large" color="inherit" onClick={() => setIsConfirmationWindowOpen(false)}>
            <Box component="img" src={`${publicUrl}/img/icons/x.svg`} alt="add" />
          </IconButton>
        </Toolbar>

        <DialogContent sx={styles.dialogContent}>
          <Grid container sx={styles.deleteFibersButtonsContainer}>
            <Button
              variant="contained"
              onClick={() => setIsConfirmationWindowOpen(false)}
              data-testid="delete-photo-cancel"
            >
              {t('general.cancel')}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                if (onDelete) onDelete();
                setIsConfirmationWindowOpen(false);
              }}
              data-testid="delete-photo-accept"
            >
              {t('general.accept')}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={isOpen} fullScreen>
        <Toolbar sx={styles.dialogTopbar}>
          {onClose && (
            <IconButton size="large" color="inherit" onClick={() => onClose()}>
              <Box component="img" src={`${publicUrl}/img/icons/x.svg`} alt="close" />
            </IconButton>
          )}
        </Toolbar>

        {photoUrl && (
          <DialogContent sx={styles.dialogContent}>
            <Box
              sx={styles.enlargedPhoto}
              component="img"
              src={photoUrl}
              alt={outlet.location ? `outlet in ${outlet.location}` : 'outlet'}
            />
            <Button
              sx={styles.deletePhotoButton}
              variant="outlined"
              color="error"
              onClick={() => setIsConfirmationWindowOpen(true)}
              data-testid="delete-photo"
            >
              {t('premise.deletePhoto')}
            </Button>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};
