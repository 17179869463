import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const CREATE_CABLE_ROUTE = gql`
  mutation createCableRoute($input: BasicObjectInput!) {
    createCableRoute(input: $input)
  }
`;

export const createCableRoute = (propertyId: number) => (basicObjectDetails: any) => {
  return requestGQL(CREATE_CABLE_ROUTE, { input: { propertyId, basicObjectDetails } });
};
