import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_WIFIDOT = gql`
  mutation patchWifiDot($input: WifiDotInput!) {
    patchWifiDot(input: $input)
  }
`;

export const patchWifiDot = (propertyId: number) => (wifiDotDetails: any) => {
  return requestGQL(PATCH_WIFIDOT, { input: { propertyId, wifiDotDetails } });
};
