import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const DELETE_COMBINER = gql`
  mutation deleteCombiner($input: DeleteNodeInput!) {
    deleteCombiner(input: $input)
  }
`;

export const deleteCombiner = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_COMBINER, { input: { propertyId, nodeId } });
};
