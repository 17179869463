import { O, pipe } from '@mobily/ts-belt';
import { usePropertyContext } from 'app/properties/components/SingleProperty';
import { useQuery } from 'react-query';

import { GetPropertyConnectionResponse } from 'app/connection/api';
import * as TE from 'common/TaskEither';
import { getPropertyConnections } from '../api/getPropertyConnections';

export const useGetPropertyConnections = (): TE.ITaskEither<string, GetPropertyConnectionResponse> => {
  const { id: propertyId } = usePropertyContext();

  const query = useQuery(
    ['propertyConnection', propertyId],
    () => pipe(propertyId, O.map(getPropertyConnections), O.getExn),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  switch (query.status) {
    case 'error':
      return TE.Error(JSON.stringify(query.error));
    case 'success':
      return TE.Ok(query.data);
    default:
      return TE.Loading;
  }
};
