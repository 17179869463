import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_REPEATER = gql`
  mutation createRepeater($input: RepeaterInput!) {
    createRepeater(input: $input)
  }
`;

export const createRepeater = (propertyId: number) => (repeaterDetails: any) => {
  return requestGQL(CREATE_REPEATER, { input: { propertyId, repeaterDetails } });
};
