import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { publicUrl } from 'config';
import { FC, PropsWithChildren } from 'react';

import { Box, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material';

import * as styles from './styles';

export const DeleteFiberDialog: FC<PropsWithChildren<{ isOpen: boolean; onClose: Lambda<void, void> }>> = ({
  isOpen,
  onClose,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} PaperProps={{ sx: styles.dialog }}>
      <Toolbar sx={styles.dialogTopbar}>
        <Typography>{t('fiber.delete.confirmationText')}</Typography>
        <IconButton size="large" color="inherit" onClick={() => onClose()}>
          <Box component="img" src={`${publicUrl}/img/icons/x.svg`} alt="add" />
        </IconButton>
      </Toolbar>

      <DialogContent sx={styles.dialogContent}>{children}</DialogContent>
    </Dialog>
  );
};
