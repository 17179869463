import { useTranslation } from 'common/hooks/useTranslation';
import { FC } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Button } from '@mui/material';
import { OutletTypes } from 'app/outlets/components/OutletMenu/OutletMenu';
import { AddFiberDialog } from './components/AddFiberDialog';
import { AddFiberTPOutletFormOutsidePremise } from './components/AddFiberTPOutletFormOutsidePremise';
import { TpOutletDetails } from '../../areas/types/ObjectDetails';

export const AddTPOutletTwistedPair: FC<{
  outletDetails: TpOutletDetails;
}> = ({ outletDetails }) => {
  const { t } = useTranslation();

  const { value: isAddFiberOpen, setValue: setIsAddFiberOpen } = useBoolean(false);

  return (
    <>
      <AddFiberDialog
        isOpen={isAddFiberOpen}
        onClose={() => setIsAddFiberOpen(false)}
        outletType={outletDetails.type as OutletTypes}
      >
        <AddFiberTPOutletFormOutsidePremise outletDetails={outletDetails} onSuccess={() => setIsAddFiberOpen(false)} />
      </AddFiberDialog>
      <Button variant="contained" onClick={() => setIsAddFiberOpen(true)} data-testid="add-fiber">
        {t('tp.add')}
      </Button>
    </>
  );
};
