import { Lambda } from 'common/types';
import { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react';

type TypeOfLogin = 'email' | 'bankid';

export const LoginPageContext = createContext<null | {
  typeOfLogin: TypeOfLogin;
  setTypeOfLogin: Lambda<TypeOfLogin, void>;
}>(null);

export const LoginPageContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [typeOfLogin, setTypeOfLogin] = useState<TypeOfLogin>('bankid');

  const value = useMemo(() => ({ typeOfLogin, setTypeOfLogin }), [typeOfLogin]);

  return <LoginPageContext.Provider value={value}>{children}</LoginPageContext.Provider>;
};

export const useLoginPageContext = () => {
  const context = useContext(LoginPageContext);

  if (context === null) {
    throw new Error('useLoginPageContext must be used within a LoginPageContextProvider');
  }

  return context;
};
