import { useTranslation } from 'common/hooks/useTranslation';
import { FC } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Button } from '@mui/material';
import { AvailableOutletTypes } from 'app/outlets/AvailiableOutletTypes';
import { AddFiberDialog } from './components/AddFiberDialog';
import { ObjectDetails } from '../../areas/types/ObjectDetails';
import { AddFiberTPOutletToNode } from './components/AddFiberTPOutletToNode';

export const LinkNodeToTPOutlet: FC<{
  nodeDetails: ObjectDetails;
}> = ({ nodeDetails }) => {
  const { t } = useTranslation();

  const { value: isAddFiberOpen, setValue: setIsAddFiberOpen } = useBoolean(false);

  return (
    <>
      <AddFiberDialog
        isOpen={isAddFiberOpen}
        onClose={() => setIsAddFiberOpen(false)}
        outletType={AvailableOutletTypes.TPOUTLET}
      >
        <AddFiberTPOutletToNode nodeDetails={nodeDetails} onSuccess={() => setIsAddFiberOpen(false)} />
      </AddFiberDialog>
      <Button variant="contained" onClick={() => setIsAddFiberOpen(true)} data-testid="add-fiber">
        {t('tp.add')}
      </Button>
    </>
  );
};
