import { O, pipe } from '@mobily/ts-belt';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateTechnicalEquipment } from 'app/areas/hooks/useCreate';
import { TechnicalEquipmentDetails } from 'app/areas/types/ObjectDetails';
import { TechnicalEquipmentSchema, technicalEquipmentSchema } from 'app/areas/types/TechnicalEquipmentSchema';
import { PhotosField } from 'app/areas/ui/PhotosField';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { TechnicalEquipment } from '../ObjectDetails/TechnicalEquipment';
import { OptionalSelect } from '../../ui/OptionalSelect';
import { useGetPropertyConnections } from '../../hooks/useGetPropertyConnections';
import { getObject } from '../../getObject';
import { getAreas } from '../../getAreas';
import { EnlargedPhotoDialog } from '../EnlargedPhotoDialog';

export const CreateTechnicalEquipment: FC<{ onSave: Lambda<void, void> }> = ({ onSave }) => {
  const { status, data } = useGetPropertyConnections();
  const [objectId, setObjectId] = useState<O.Option<string>>(O.None);
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  const [formValues, setFormValues] = useState({} as O.Option<TechnicalEquipmentSchema>);
  const [allChecked, setAllChecked] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  const [pickedImage, setPickedImage] = useState<O.Option<string>>(O.None);
  const { mutate: createTechnicalEquipment, isLoading: isLoadingCreateTechnicalEquipment } =
    useCreateTechnicalEquipment({
      onSuccess: () => {
        onSave();
        toast.success(t('general.updatedSuccessfully'));
      },
      onError: () => {
        toast.error(t('error.generalMessage'));
      },
    });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<TechnicalEquipmentSchema>({
    defaultValues: {
      text: '',
      portCount: 1,
      location: '',
      ip: '',
    },
    mode: 'onChange',
    resolver: zodResolver(technicalEquipmentSchema),
  });

  const [photosURLs, setPhotosURLs] = useState<TechnicalEquipmentDetails['photosURLs']>([]);

  const getErrorMessage = useErrorMessage(errors);

  useEffect(() => {
    setAllChecked(checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3);
  }, [checkboxes]);

  if (status === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'error') {
    return <Alert severity="error">{t('areas.properties.error')}</Alert>;
  }

  const { technicalEquipments } = getAreas(data);

  const selectedObjectDetails = pipe(
    objectId,
    O.flatMap((id) => getObject(id)(data)),
  );

  const handleSave = (values: TechnicalEquipmentSchema) => {
    setFormValues(values);
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleDialogConfirm = () => {
    createTechnicalEquipment({ ...formValues, photosURLs });
    setShowDialog(false);
  };

  const handleCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({ ...checkboxes, [name]: event.target.checked });
  };

  return (
    <>
      <Dialog open={showDialog} onClose={handleDialogClose} PaperProps={{ sx: { margin: 0.5, p: 2 } }}>
        <DialogTitle>{t('checklist.title')}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checkboxes.checkbox1} onChange={handleCheckboxChange('checkbox1')} />}
                label={t('checklist.device.1')}
              />
              <FormControlLabel
                control={<Checkbox checked={checkboxes.checkbox2} onChange={handleCheckboxChange('checkbox2')} />}
                label={t('checklist.device.2')}
              />
              <FormControlLabel
                control={<Checkbox checked={checkboxes.checkbox3} onChange={handleCheckboxChange('checkbox3')} />}
                label={t('checklist.cleaned')}
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t('general.cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleDialogConfirm} disabled={!allChecked}>
            {t('general.create')}
          </Button>
        </DialogActions>
      </Dialog>
      <Stack>
        {!objectId && (
          <>
            <Box my={2}>
              <EnlargedPhotoDialog
                photoUrl={pickedImage}
                onClose={() => {
                  setPickedImage(O.None);
                }}
              />
              <Stack component="form" onSubmit={handleSubmit(handleSave)}>
                <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.technicalEquipment.label')}</Typography>
                <Stack gap={2} width="100%">
                  <PhotosField
                    value={photosURLs}
                    onChange={setPhotosURLs}
                    onItemClick={(photoUrl) => {
                      setPickedImage(O.Some(photoUrl));
                    }}
                  />
                  <TextField label={t('areas.fields.name')} {...register('text')} />
                  <TextField label={t('areas.fields.location')} {...register('location')} />
                  <TextField
                    label={t('areas.fields.connectionCount')}
                    type="number"
                    {...register('portCount', {
                      valueAsNumber: true,
                    })}
                    helperText={getErrorMessage('portCount')}
                  />
                  <TextField label={t('areas.fields.ip')} {...register('ip')} />
                </Stack>
                <Grid container justifyContent="space-between" mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isValid || isLoadingCreateTechnicalEquipment}
                  >
                    {isLoadingCreateTechnicalEquipment ? <CircularProgress size={16} /> : t('general.create')}
                  </Button>
                </Grid>
              </Stack>
            </Box>
            <Box mt={2}>
              <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.object.edit')}</Typography>
            </Box>
          </>
        )}
        {technicalEquipments.length === 0 ? (
          <Alert severity="error" sx={{ width: 'unset' }}>
            {t('areas.object.noAvailable')}
          </Alert>
        ) : (
          <>
            <OptionalSelect
              options={technicalEquipments.map(({ id, text }) => ({ id, label: O.getWithDefault(text, id) }))}
              value={objectId}
              onChange={setObjectId}
            />
            <Box mt={2}>
              {O.match(
                selectedObjectDetails,
                (details) => {
                  if (details.type === 'TechnicalEquipment') {
                    return <TechnicalEquipment key={details.id} details={details} onSave={onSave} />;
                  }
                  return null;
                },
                () => (
                  <Alert severity="info" sx={{ width: 'unset' }}>
                    {t('areas.object.noSelected')}
                  </Alert>
                ),
              )}
            </Box>
          </>
        )}
      </Stack>
    </>
  );
};
