import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_SPLITTER = gql`
  mutation patchSplitter($input: SplitterInput!) {
    patchSplitter(input: $input)
  }
`;

export const patchSplitter = (propertyId: number) => (splitterDetails: any) => {
  return requestGQL(PATCH_SPLITTER, { input: { propertyId, splitterDetails } });
};
