import { A, O } from '@mobily/ts-belt';
import { GetPropertyRadioConnectionResponse } from 'app/connection/api';
import { RadioObjectDetails } from './types/ObjectDetails';

export const getRadioObject =
  (objectId: string) =>
  (response: GetPropertyRadioConnectionResponse): O.Option<RadioObjectDetails> => {
    const nodes = response.propertyById.radioConnectionByRadioConnectionId.serialized2.nodeDataArray;

    const node = A.find(nodes, (connectionNode) => connectionNode.id === objectId);

    if (O.isNone(node)) {
      return O.None;
    }

    const { cfObject } = node;

    switch (node.model) {
      case 'baseStation':
        return O.Some({
          id: node.id,
          type: 'BaseStation',
          text: O.fromNullable(node.text),
          photosURLs: cfObject?.images ?? [],
          portCount: O.fromNullable(node.portCount),
          unitType: O.fromNullable(cfObject?.unitType),
          location: O.fromNullable(cfObject?.location),
          operator: O.fromNullable(cfObject?.operator),
          voltage: O.fromNullable(cfObject?.voltage),
          other: O.fromNullable(cfObject?.other),
        });
      case 'radioUnit':
        return O.Some({
          id: node.id,
          type: 'RadioUnit',
          text: O.fromNullable(node.text),
          photosURLs: cfObject?.images ?? [],
          location: O.fromNullable(cfObject?.location),
          portCount: O.fromNullable(node.portCount),
          unitType: O.fromNullable(cfObject?.unitType),
          serialNumber: O.fromNullable(cfObject?.serialNumber),
        });
      case 'inputAntenna':
        return O.Some({
          id: node.id,
          type: 'InputAntenna',
          text: O.fromNullable(node.text),
          photosURLs: cfObject?.images ?? [],
          portCount: O.fromNullable(node.portCount),
          location: O.fromNullable(cfObject?.location),
          unitType: O.fromNullable(cfObject?.unitType),
          serialNumber: O.fromNullable(cfObject?.serialNumber),
        });
      case 'combiner':
        return O.Some({
          id: node.id,
          type: 'Combiner',
          text: O.fromNullable(node.text),
          photosURLs: cfObject?.images ?? [],
          location: O.fromNullable(cfObject?.location),
          unitType: O.fromNullable(cfObject?.unitType),
          leftPortCount: O.fromNullable(node.leftPortCount),
          rightPortCount: O.fromNullable(node.rightPortCount),
          serialNumber: O.fromNullable(cfObject?.serialNumber),
        });
      case 'coupler':
        return O.Some({
          id: node.id,
          type: 'Coupler',
          text: O.fromNullable(node.text),
          portCount: O.fromNullable(node.portCount),
          unitType: O.fromNullable(cfObject?.unitType),
          photosURLs: cfObject?.images ?? [],
          location: O.fromNullable(cfObject?.location),
        });
      case 'dropAntenna':
        return O.Some({
          id: node.id,
          type: 'DropAntenna',
          text: O.fromNullable(node.text),
          photosURLs: cfObject?.images ?? [],
          portCount: O.fromNullable(node.portCount),
          unitType: O.fromNullable(cfObject?.unitType),
          location: O.fromNullable(cfObject?.location),
          serialNumber: O.fromNullable(cfObject?.serialNumber),
        });
      case 'repeater':
        return O.Some({
          id: node.id,
          type: 'Repeater',
          text: O.fromNullable(node.text),
          photosURLs: cfObject?.images ?? [],
          unitType: O.fromNullable(cfObject?.unitType),
          location: O.fromNullable(cfObject?.location),
          serialNumber: O.fromNullable(cfObject?.serialNumber),
        });
      case 'splitter':
        return O.Some({
          id: node.id,
          type: 'Splitter',
          text: O.fromNullable(node.text),
          unitType: O.fromNullable(cfObject?.unitType),
          portCount: O.fromNullable(node.portCount),
          photosURLs: cfObject?.images ?? [],
          location: O.fromNullable(cfObject?.location),
          serialNumber: O.fromNullable(cfObject?.serialNumber),
        });
      default:
        return O.None;
    }
  };
