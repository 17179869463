import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const DELETE_BASE_STATION = gql`
  mutation deleteBaseStation($input: DeleteNodeInput!) {
    deleteBaseStation(input: $input)
  }
`;

export const deleteBaseStation = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_BASE_STATION, { input: { propertyId, nodeId } });
};
