import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_TECHNICAL_ODF = gql`
  mutation createTechnicalODF($input: ODFInput!) {
    createTechnicalODF(input: $input)
  }
`;

export const createTechnicalODF = (propertyId: number) => (odfDetails: any) => {
  return requestGQL(CREATE_TECHNICAL_ODF, { input: { propertyId, odfDetails } });
};
