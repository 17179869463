import { createStylesMap } from 'app/theme/materialTheme';

export const styles = createStylesMap({
  photosContainer: { mt: 1.5, gap: 2 },
  photo: {
    cursor: 'pointer',
    borderRadius: 0.5,
    width: 92,
    height: 92,
  },
  // custom onetime use component styles
  addPhotoButton: {
    border: '1px solid #DBE1E2',
    backgroundColor: '#F4F6F6',
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
  },
  hidden: {
    visibility: 'hidden',
    position: 'absolute',
    height: 0,
  },
  addPhotoText: {
    lineHeight: 1,
    fontSize: 12,
    color: '#707475',
  },
});
