import { RoutesParamsMap } from 'app/routes/Route';
import { mergeStyles } from 'app/theme/materialTheme';
import { optionParseInt } from 'common/helpers';
import { useTranslation } from 'common/hooks/useTranslation';
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { Location, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { G, O } from '@mobily/ts-belt';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { useProperty } from '../hooks/useProperty';
import * as propertiesStyles from './properties.styles';
import * as styles from './SingleProperty.styles';

const getInitialTabIndex = (location: Location) => {
  if (location.pathname.endsWith('/premises')) {
    return 1;
  }

  if (location.pathname.endsWith('/areas')) {
    return 2;
  }

  return 0;
};

export const PropertyContext = createContext<{ id: O.Option<number> }>({ id: O.None });

export const usePropertyContext = () => {
  const context = useContext(PropertyContext);

  if (G.isNullable(context)) {
    throw new Error('No Property Context available.');
  }

  return context;
};

export const SingleProperty: FC = () => {
  const params = useParams<RoutesParamsMap['property/:id']>();
  const parsedId = useMemo(() => optionParseInt(params.id), [params]);
  const location = useLocation();

  const { t } = useTranslation();
  // TODO: redo this with some matchPath
  const [tabIndex, setTabIndex] = useState(getInitialTabIndex(location));

  const { data: property, isLoading: isLoadingProperty, isError: isErrorProperty } = useProperty(parsedId);

  const navigate = useNavigate();

  const propertyContextValue = useMemo(() => ({ id: parsedId }), [parsedId]);

  useEffect(() => {
    if (isErrorProperty) toast.error(t('error.generalMessage'));
  }, [isErrorProperty, t]);

  const handleGoBack = () => {
    navigate(`..?propertyId=${parsedId}`);
  };

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.titleContainer}>
        <IconButton data-cy="go-back" onClick={handleGoBack}>
          <ArrowBackIosIcon titleAccess="back" sx={styles.backIcon} />
        </IconButton>
        {property && (
          <Grid container>
            <Typography sx={propertiesStyles.header}>{property.description}</Typography>
          </Grid>
        )}
      </Grid>

      <Tabs variant="fullWidth" value={tabIndex} onChange={(_, v) => setTabIndex(v)}>
        <Tab
          data-cy="tab-informations"
          onClick={() => navigate('./')}
          label={<Typography sx={styles.tabLabel}>{t('properties.informations')}</Typography>}
        />
        <Tab
          data-cy="tab-premises"
          onClick={() => navigate('./premises')}
          label={
            <Grid container sx={styles.tabLabelContainer}>
              <Typography sx={styles.tabLabel}>{t('properties.premises.label')}</Typography>
              {!isLoadingProperty && property && (
                <Typography sx={mergeStyles([styles.tabLabel, styles.premisesAmount])}>
                  {property.amountOfPremises}
                </Typography>
              )}
            </Grid>
          }
        />

        <Tab
          data-cy="tab-areas"
          onClick={() => navigate('./areas')}
          label={<Typography sx={styles.tabLabel}>{t('areas.tab.label')}</Typography>}
        />
      </Tabs>

      <PropertyContext.Provider value={propertyContextValue}>
        <Outlet />
      </PropertyContext.Provider>
    </Grid>
  );
};
