import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_COUPLER = gql`
  mutation createCoupler($input: CouplerInput!) {
    createCoupler(input: $input)
  }
`;

export const createCoupler = (propertyId: number) => (couplerDetails: any) => {
  return requestGQL(CREATE_COUPLER, { input: { propertyId, couplerDetails } });
};
