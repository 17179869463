import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';
import { BaseStationDetails } from '../types/ObjectDetails';

export const CREATE_BASE_STATION = gql`
  mutation createBaseStation($input: BaseStationInput!) {
    createBaseStation(input: $input)
  }
`;

export const createBaseStation = (propertyId: number) => (baseStationDetails: BaseStationDetails) => {
  return requestGQL(CREATE_BASE_STATION, { input: { propertyId, baseStationDetails } });
};
