import { requestGQL } from 'common/graphQlClient';
import { isTestEnv, oneHourInMs } from 'config';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDebounce } from 'usehooks-ts';

import { G, S } from '@mobily/ts-belt';

import {
  GET_PROPERTIES,
  GET_PROPERTIES_CONNECTIONS,
  GetPropertiesConnectionsResponse,
  GetPropertiesResponse,
} from '../api';
import { parseGetPropertiesResponse, searchProperties } from '../Property';

export const usePropertiesList = () => {
  // TODO: extract that usequery so these options won't be necessary
  const { data, isLoading, isError } = useQuery<GetPropertiesResponse>(
    'allProperties',
    () => requestGQL(GET_PROPERTIES),
    {
      refetchOnWindowFocus: false,
      retry: isTestEnv ? 0 : 3,
      staleTime: oneHourInMs,
    },
  );

  const { data: connectionsData } = useQuery<GetPropertiesConnectionsResponse>(
    'allPropertiesConnections',
    () => requestGQL(GET_PROPERTIES_CONNECTIONS),
    {
      refetchOnWindowFocus: false,
      retry: isTestEnv ? 0 : 3,
      staleTime: oneHourInMs,
    },
  );

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, !isTestEnv ? 300 : 10);

  const parsedProperties = useMemo(() => {
    if (G.isNullable(data)) {
      return [];
    }

    const parsedResponse = parseGetPropertiesResponse(data, connectionsData);

    if (G.isNullable(parsedResponse)) {
      return [];
    }

    return parsedResponse;
  }, [data, connectionsData]);

  const search = (query: string) => setSearchQuery(query);

  const isAvailable = useMemo(() => {
    return parsedProperties.length > 0;
  }, [parsedProperties]);

  const propertiesData = useMemo(() => {
    if (S.isEmpty(debouncedSearchQuery)) {
      return parsedProperties;
    }

    return searchProperties(debouncedSearchQuery)(parsedProperties);
  }, [parsedProperties, debouncedSearchQuery]);

  const isEmpty = useMemo(() => {
    return propertiesData.length === 0;
  }, [propertiesData.length]);

  const isSearching = useMemo(() => {
    return searchQuery !== debouncedSearchQuery;
  }, [searchQuery, debouncedSearchQuery]);

  return {
    data: propertiesData,
    isLoading,
    isError,
    search,
    isSearching,
    isAvailable,
    isEmpty,
  };
};
