import { Alert, CircularProgress, Stack } from '@mui/material';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC } from 'react';
import { getAreasRadio } from '../getAreas';
import { useGetPropertyRadioConnections } from '../hooks/useGetPropertyRadioConnections';
import { PreviewItem } from '../types/PreviewItem';
import { ObjectAccordion } from '../ui/ObjectAccordion';

type Props = {
  onItemClick: Lambda<PreviewItem, void>;
};

export const AreasRadioPreview: FC<Props> = ({ onItemClick }) => {
  const { t } = useTranslation();
  const { status, data } = useGetPropertyRadioConnections();

  if (status === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'error') {
    return <Alert severity="error">{t('areas.properties.error')}</Alert>;
  }

  const { baseStations, radioUnits, inputAntennas, dropAntennas, combiners, couplers, splitters, repeaters } =
    getAreasRadio(data);

  const handleOnItemClick = (item: PreviewItem) => {
    onItemClick(item);
  };

  return (
    <Stack width="100%" mt={2} gap={2}>
      <ObjectAccordion
        label={`${t('areas.baseStation.label')}s`}
        items={baseStations}
        onItemClick={handleOnItemClick}
      />
      <ObjectAccordion label={`${t('areas.radioUnit.label')}s`} items={radioUnits} onItemClick={handleOnItemClick} />
      <ObjectAccordion
        label={`${t('areas.inputAntenna.label')}s`}
        items={inputAntennas}
        onItemClick={handleOnItemClick}
      />
      <ObjectAccordion
        label={`${t('areas.dropAntenna.label')}s`}
        items={dropAntennas}
        onItemClick={handleOnItemClick}
      />
      <ObjectAccordion label={`${t('areas.combiner.label')}s`} items={combiners} onItemClick={handleOnItemClick} />
      <ObjectAccordion label={`${t('areas.coupler.label')}s`} items={couplers} onItemClick={handleOnItemClick} />
      <ObjectAccordion label={`${t('areas.splitter.label')}s`} items={splitters} onItemClick={handleOnItemClick} />
      <ObjectAccordion label={`${t('areas.repeater.label')}s`} items={repeaters} onItemClick={handleOnItemClick} />
    </Stack>
  );
};
