import { z } from 'zod';

export const trunkSchema = z.object({
  text: z.string().min(1),
  connectionsCount: z.number().min(0).int(),
  location: z.string(),
  length: z.number().min(0).multipleOf(0.1),
});

export type TrunkSchema = z.infer<typeof trunkSchema>;
