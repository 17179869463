import axios from 'axios';
import { apiUri } from 'config';
import { authenticationState } from 'app/authentication/state';

export type GetAzureSignedLinkObjectResponse = {
  container: 'outlet';
  query: string;
  url: string;
};

export const getAzureSignedObjectLink = () => {
  const { token } = authenticationState();

  return axios
    .post<GetAzureSignedLinkObjectResponse>(`${apiUri}/api/v1/azurestorage/upload/objects`, null, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((response) => response.data);
};
