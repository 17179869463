import { createFiber, getDataText, getNodeText, getOutletById, isOdfPortAvailable } from 'app/connection/Connection';
import { useConnection } from 'app/connection/hooks/useConnection';
import { Outlet } from 'app/outlets/Outlet';
import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import * as R from 'rambda';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { toast } from 'react-toastify';
import { A, D, G, O, pipe } from '@mobily/ts-belt';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  NativeSelect,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { AddFiberSchema } from '../../addFiberSchema';
import { useCreateFiber } from '../../useCreateFiber';
import { styles } from '../AddFiber.styles';

export const AddFiberForm: FC<{ premise: PremiseOnSinglePremise; outlet: Outlet; onSuccess?: Lambda<void, void> }> = ({
  premise,
  outlet,
  onSuccess,
}) => {
  const { ODFnodes, serialized2, isLoading, isError } = useConnection(premise.propertyId);

  const {
    mutate,
    isLoading: isLoadingUpdateConnection,
    isError: isErrorUpdateConnection,
    isSuccess: isSuccessUpdateConnection,
  } = useCreateFiber(premise.propertyId);

  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  const [formValues, setFormValues] = useState({} as O.Option<AddFiberSchema>);
  const [allChecked, setAllChecked] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  useEffect(() => {
    if (isError || isErrorUpdateConnection) toast.error(t('error.generalMessage'));
  }, [isError, isErrorUpdateConnection]);

  useEffect(() => {
    setAllChecked(checkboxes.checkbox1 && checkboxes.checkbox2 && checkboxes.checkbox3);
  }, [checkboxes]);

  useEffect(() => {
    if (isSuccessUpdateConnection) toast.success(t('general.updatedSuccessfully'));
    if (isSuccessUpdateConnection && G.isNotNullable(onSuccess)) onSuccess();
  }, [isSuccessUpdateConnection]);

  const ODFSelectOptions = useMemo(
    () => pipe(O.fromNullable(ODFnodes), O.map(R.pipe(A.map(getDataText), A.filter(G.isNotNullable))), O.toNullable),
    [ODFnodes],
  );

  const { control, register, handleSubmit, watch, setValue, trigger } = useForm<AddFiberSchema>();

  const { errors } = useFormState({ control });

  const getErrorMessage = useErrorMessage(errors);

  const formODFValue = watch('odf');

  const pickedODFNode = useMemo(
    () => pipe(O.fromNullable(ODFnodes), O.flatMap(A.find((odf) => getNodeText(odf) === formODFValue)), O.toNullable),
    [ODFnodes, formODFValue],
  );

  useEffect(() => {
    pipe(
      O.fromNullable(ODFnodes),
      O.flatMap(A.head),
      O.flatMap((node) => O.fromNullable(getNodeText(node))),
      O.tap((defaultODFValue) => setValue('odf', defaultODFValue)),
    );
  }, [ODFnodes]);

  useEffect(() => {
    trigger();
  }, [formODFValue]);

  const submitFiber = useCallback(
    (formData: AddFiberSchema) => {
      if (G.isNotNullable(serialized2) && G.isNotNullable(ODFnodes) && D.isEmpty(errors)) {
        const pickedOutlet = getOutletById(serialized2, outlet.id);
        if (G.isNotNullable(pickedODFNode) && G.isNotNullable(pickedOutlet)) {
          const parsedFiber = `${formData.fiber}`.padStart(2, '0');
          mutate(
            createFiber(
              pickedOutlet,
              pickedODFNode,
              parsedFiber,
              formData.duct,
              formData.length,
              formData.db1310,
              formData.db1550,
            ),
          );
        }
      }
    },
    [serialized2, pickedODFNode],
  );

  const handleSave = (values: AddFiberSchema) => {
    setFormValues(values);
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleDialogConfirm = () => {
    submitFiber(formValues as AddFiberSchema);
    setShowDialog(false);
  };

  const handleCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxes({ ...checkboxes, [name]: event.target.checked });
  };

  return (
    <>
      <Dialog open={showDialog} onClose={handleDialogClose} PaperProps={{ sx: { margin: 0.5, p: 2 } }}>
        <DialogTitle>{t('checklist.title')}</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={checkboxes.checkbox1} onChange={handleCheckboxChange('checkbox1')} />}
                label={t('checklist.outlett.1')}
              />
              <FormControlLabel
                control={<Checkbox checked={checkboxes.checkbox2} onChange={handleCheckboxChange('checkbox2')} />}
                label={t('checklist.outlett.2')}
              />
              <FormControlLabel
                control={<Checkbox checked={checkboxes.checkbox3} onChange={handleCheckboxChange('checkbox3')} />}
                label={t('checklist.cleaned')}
              />
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t('general.cancel')}
          </Button>
          <Button variant="contained" color="primary" onClick={handleDialogConfirm} disabled={!allChecked}>
            {t('general.create')}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container sx={styles.centerContainer}>
        {isLoading && <CircularProgress />}
        {!isLoading && !isError && serialized2 && (
          <Grid container component="form" onSubmit={handleSubmit(handleSave)}>
            <Grid container columnSpacing={1.5} rowSpacing={4}>
              <Grid item xs={6}>
                {ODFSelectOptions && (
                  <FormControl fullWidth>
                    <InputLabel htmlFor="odf">{t('fiber.odf')}</InputLabel>
                    <NativeSelect
                      {...register('odf', { required: true })}
                      input={<OutlinedInput fullWidth inputProps={{ 'data-testid': 'add-fiber-odf' }} />}
                      id="odf"
                    >
                      {ODFSelectOptions.map((odfToPick) => (
                        <option key={odfToPick} value={odfToPick}>
                          {odfToPick}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...register('fiber', {
                    valueAsNumber: true,
                    required: true,
                    validate: (fiber) => {
                      if (G.isNotNullable(pickedODFNode) && !isOdfPortAvailable(serialized2, pickedODFNode, fiber)) {
                        return t('fiber.add.error.invalidODFport');
                      }
                      return true;
                    },
                  })}
                  inputProps={{
                    'data-testid': 'add-fiber-fiber',
                  }}
                  type="number"
                  fullWidth
                  label={t('fiber.fiber')}
                  variant="outlined"
                  helperText={getErrorMessage('fiber')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...register('duct')}
                  inputProps={{
                    'data-testid': 'add-fiber-duct',
                  }}
                  fullWidth
                  label={t('fiber.duct')}
                  variant="outlined"
                  helperText={getErrorMessage('duct')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...register('length', { valueAsNumber: true })}
                  inputProps={{ type: 'number', step: '0.01', 'data-testid': 'add-fiber-length' }}
                  fullWidth
                  label={t('fiber.length')}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...register('db1310', { valueAsNumber: true })}
                  inputProps={{ type: 'number', step: '0.01', 'data-testid': 'add-fiber-db1310' }}
                  fullWidth
                  label={t('fiber.db1310')}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...register('db1550', { valueAsNumber: true })}
                  inputProps={{ type: 'number', step: '0.01', 'data-testid': 'add-fiber-db1550' }}
                  fullWidth
                  label={t('fiber.db1550')}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container sx={styles.addFiberSubmitContainer}>
              {isLoadingUpdateConnection && <CircularProgress />}
              {!isLoadingUpdateConnection && !isSuccessUpdateConnection && (
                <Button type="submit" variant="contained" data-testid="add-fiber-form-save">
                  {t('general.save')}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
