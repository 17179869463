import { Styles } from '../../../theme/materialTheme';

export const container: Styles = {
  mt: 9,
  flexDirection: 'column',
  alignItems: 'center',
};

export const header: Styles = {
  fontSize: 20,
  fontWeight: 500,
  alignSelf: 'start',
};

export const titleContainer: Styles = {
  alignItems: 'center',
  flexWrap: 'nowrap',
};

export const backIcon: Styles = (theme) => ({
  color: theme.palette.primary.main,
});

export const contentContainer: Styles = {
  mt: 2,
  flexDirection: 'column',
  alignItems: 'center',
};

export const tabLabel: Styles = {
  fontSize: 'inherit',
};
export const tabLabelContainer: Styles = {
  flexWrap: 'nowrap',
  justifyContent: 'center',
  gap: 0.5,
};
