import { requestGQL } from 'common/graphQlClient';
import { isTestEnv, oneHourInMs } from 'config';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { G, O, pipe } from '@mobily/ts-belt';

import { GET_PROPERTY_CONNECTION, GetPropertyConnectionResponse } from '../api';
import { getCrossConnectionPanels, getODFs, parseConnection, getTPOutlets } from '../Connection';

export const useConnection = (propertyId: undefined | null | number) => {
  const { data, isLoading, isError } = useQuery<GetPropertyConnectionResponse>(
    ['propertyConnection', propertyId],
    () => requestGQL(GET_PROPERTY_CONNECTION, { id: propertyId }),
    {
      enabled: G.isNotNullable(propertyId),
      refetchOnWindowFocus: false,
      retry: isTestEnv ? 0 : 3,
      staleTime: oneHourInMs,
    },
  );

  const parsedData = useMemo(() => (G.isNotNullable(data) ? parseConnection(data) : data), [data]);

  const serialized2 = useMemo(() => parsedData?.serialized2, [parsedData]);

  const ODFnodes = useMemo(() => pipe(O.fromNullable(serialized2), O.map(getODFs), O.toNullable), [serialized2]);
  const tpOutletsNodes = useMemo(
    () => pipe(O.fromNullable(serialized2), O.map(getTPOutlets), O.toNullable),
    [serialized2],
  );
  const crossConnectionPanelNodes = useMemo(
    () => pipe(O.fromNullable(serialized2), O.map(getCrossConnectionPanels), O.toNullable),
    [serialized2],
  );

  const id = useMemo(() => parsedData?.id, [parsedData]);

  return {
    data: parsedData,
    id,
    isLoading,
    isError,
    ODFnodes,
    crossConnectionPanelNodes,
    tpOutletsNodes,
    serialized2,
  };
};
