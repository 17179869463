import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const DELETE_TPOUTLET = gql`
  mutation deleteTpOutlet($input: DeleteNodeInput!) {
    deleteTpOutlet(input: $input)
  }
`;

export const deleteTpOutlet = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_TPOUTLET, { input: { propertyId, nodeId } });
};
