import { gql } from 'graphql-request';

import { SerializedData as ConnectionSerializedData } from '../connection/api';

export type SinglePremiseGetPremisesResponse = {
  id: number;
  name: string;
  description: null | string;
  tags: string[];
  premisesTenantsByPremisesId: {
    nodes: { id: number; companyByTenantId: null | { name: string | null } }[];
  };
  premisesTypeId: number;
  propertyByPropertyId: {
    connectionByConnectionId: null | {
      serialized2: ConnectionSerializedData;
    };
    radioConnectionByRadioConnectionId: null | {
      serialized2: ConnectionSerializedData;
    };
  };
};

export type GetPremisesResponse = {
  allPremises: {
    nodes: SinglePremiseGetPremisesResponse[];
  };
};

export const GET_PREMISES = gql`
  query allPremises($propertyId: Int!) {
    allPremises(filter: { propertyId: { equalTo: $propertyId } }, orderBy: NAME_ASC) {
      nodes {
        id
        name
        description
        tags
        premisesTypeId
        premisesTenantsByPremisesId {
          nodes {
            id
            companyByTenantId {
              name
            }
          }
        }
        propertyByPropertyId {
          connectionByConnectionId {
            serialized2
          }
          radioConnectionByRadioConnectionId {
            serialized2
          }
        }
      }
    }
  }
`;

export type PremiseGetPremisResponse = {
  id: number;
  name: string;
  description: null | string;
  premisesTenantsByPremisesId: {
    nodes:
      | {
          contactPersonIT: null | string;
          contactPersonITEmail: null | string;
          contactPersonITMobile: null | string;
          contactPersonTenant: null | string;
          contactPersonTenantEmail: null | string;
          contactPersonTenantMobile: null | string;
          companyByTenantId: {
            name: string;
          };
        }[]
      | null[];
  };
  premiseInstallationBookingsByPremiseId: {
    nodes: PremiseInstallationBooking[];
  };
  reason: null | string;
  isNotAccessible: boolean;
  notes: null | string;
  propertyByPropertyId: {
    id: number;
    name: string;
  };
};

export type GetPremiseResponse = {
  premisesById: PremiseGetPremisResponse;
};

export type PremiseInstallationBooking = {
  id: number;
  premiseId: number;
  propertyId: number;
  bookingDate: string;
  notes: string;
  createdAt: string;
  createdBy: string;
};

export type GetPremiseInstallationBookingsResponse = {
  premiseById: {
    premiseInstallationBookings: PremiseInstallationBooking[];
  };
};

export const GET_PREMISE = gql`
  query premisesById($id: Int!) {
    premisesById(id: $id) {
      id
      name
      description
      premiseInstallationBookingsByPremiseId {
        nodes {
          id
          premiseId
          propertyId
          bookingDate
          notes
          createdAt
          createdBy
        }
      }
      premisesTenantsByPremisesId {
        nodes {
          contactPersonIT
          contactPersonITEmail
          contactPersonITMobile
          contactPersonTenant
          contactPersonTenantEmail
          contactPersonTenantMobile
          companyByTenantId {
            name
          }
        }
      }
      notes
      isNotAccessible
      reason
      propertyByPropertyId {
        id
        name
      }
    }
  }
`;

export const CREATE_PREMISE_INSTALLATION_BOOKING = gql`
  mutation CreatePremiseInstallationBooking(
    $premiseId: Int!
    $propertyId: Int!
    $bookingDate: Datetime!
    $notes: String
  ) {
    createPremiseInstallationBooking(
      input: {
        premiseInstallationBooking: {
          premiseId: $premiseId
          propertyId: $propertyId
          bookingDate: $bookingDate
          notes: $notes
        }
      }
    ) {
      premiseInstallationBooking {
        premiseId
        propertyId
        bookingDate
        notes
      }
    }
  }
`;

export const UPDATE_PREMISE_NOTES = gql`
  mutation updatePremisesNotesById($id: Int!, $premisesPatch: PremisesPatch!) {
    updatePremisesById(input: { id: $id, premisesPatch: $premisesPatch }) {
      premises {
        notes
        isNotAccessible
        reason
      }
    }
  }
`;
