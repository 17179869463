import { createStylesMap } from 'app/theme/materialTheme';

export const styles = createStylesMap({
  pageBackground: (theme) => ({
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    bgcolor: theme.palette.background.paper,
    zIndex: -1,
  }),
  languagePickerContainer: {
    justifyContent: 'end',
    mt: 5.5,
    mr: 2.5,
  },
  logoContainer: { alignItems: 'center', mt: 5.5, flexDirection: 'column' },
  logoText: (theme) => ({
    color: theme.palette.primary.main,
    fontSize: 26,
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: 0.5,
  }),
  welcomeTextContainer: { flexDirection: 'column', mt: 4.5, alignItems: 'center' },
  welcomeText: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 500,
  },
  loginMethodContainer: { mt: 0.5, width: '100%' },
});
