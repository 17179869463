import { O, pipe } from '@mobily/ts-belt';
import { Lambda } from 'common/types';
import { useMutation, useQueryClient } from 'react-query';
import { usePropertyRadioContext } from 'app/properties/components/SingleRadioProperty';
import { usePremiseRadioContext } from 'app/premises/components/SinglePremise/SingleRadioPremise';
import { usePremiseContext } from 'app/premises/components/SinglePremise/SinglePremise';
import { usePropertyContext } from '../../properties/components/SingleProperty';
import { patchBundle } from '../api/patchBundle';
import { patchCableRoute } from '../api/patchCableRoute';
import { patchFireBreach } from '../api/patchFireBreach';
import { patchODF } from '../api/patchODF';
import { patchTechnicalODF } from '../api/patchTechnicalODF';
import { patchRack } from '../api/patchRack';
import { patchSwitch } from '../api/patchSwitch';
import { patchTechnicalSwitch } from '../api/patchTechnicalSwitch';
import { patchWifiDot } from '../api/patchWifiDot';
import { patchTpOutlet } from '../api/patchTpOutlet';
import { patchCrossConnectionPanel } from '../api/patchCrossPanel';
import { patchTechnicalEquipment } from '../api/patchTechnicalEquipment';
import { patchTrunk } from '../api/patchTrunk';
import { patchBaseStation } from '../api/patchBaseStation';
import { patchDropAntenna } from '../api/patchDropAntenna';
import { patchRadioUnit } from '../api/patchRadioUnit';
import { patchInputAntenna } from '../api/patchInputAntenna';
import { patchCoupler } from '../api/patchCoupler';
import { patchCombiner } from '../api/patchCombiner';
import { patchRepeater } from '../api/patchRepeater';
import { patchSplitter } from '../api/patchSplitter';

type SuccessErrorCallbacks = {
  onSuccess: Lambda<void, void>;
  onError: Lambda<void, void>;
};

const usePatchNode = (
  type: string,
  apiCallFn: Lambda<number, Lambda<any, Promise<unknown>>>,
  { onSuccess, onError }: SuccessErrorCallbacks,
) => {
  const { id } = usePropertyContext();
  const { id: premiseId } = usePremiseContext();
  const { id: radioId } = usePropertyRadioContext();
  const { id: propertyID } = usePremiseRadioContext();
  const queryClient = useQueryClient();

  const currentPropertyId = id || premiseId || radioId || propertyID;

  return useMutation<unknown, unknown, unknown>(
    (details: any) =>
      pipe(
        currentPropertyId,
        O.map((propertyId) => apiCallFn(propertyId)({ ...details, type })),
        O.getExn,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['propertyConnection', currentPropertyId]);
        queryClient.invalidateQueries(['propertyRadioConnection', currentPropertyId]);
        onSuccess();
      },
      onError: () => {
        onError();
      },
    },
  );
};

export const usePatchBundle = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('bundle', patchBundle, callbacks);
};

export const usePatchODF = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('odf', patchODF, callbacks);
};

export const usePatchTechnicalODF = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('technicalOdf', patchTechnicalODF, callbacks);
};

export const usePatchSwitch = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('switch', patchSwitch, callbacks);
};

export const usePatchTechnicalSwitch = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('technicalSwitch', patchTechnicalSwitch, callbacks);
};

export const usePatchWifiDot = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('wifiDot', patchWifiDot, callbacks);
};

export const usePatchTpOutlet = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('tpOutlet', patchTpOutlet, callbacks);
};

export const usePatchCrossPanel = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('crossPanel', patchCrossConnectionPanel, callbacks);
};

export const usePatchTechnicalEquipment = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('technicalEquipment', patchTechnicalEquipment, callbacks);
};

export const usePatchTrunk = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('trunk', patchTrunk, callbacks);
};

export const usePatchFireBreach = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('fireBreach', patchFireBreach, callbacks);
};

export const usePatchRack = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('rack', patchRack, callbacks);
};

export const usePatchCableRoute = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('cableRoute', patchCableRoute, callbacks);
};

export const usePatchBaseStation = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('baseStation', patchBaseStation, callbacks);
};

export const usePatchDropAntenna = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('dropAntenna', patchDropAntenna, callbacks);
};

export const usePatchRadioUnit = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('radioUnit', patchRadioUnit, callbacks);
};

export const usePatchInputAntenna = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('inputAntenna', patchInputAntenna, callbacks);
};

export const usePatchCoupler = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('coupler', patchCoupler, callbacks);
};

export const usePatchCombiner = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('combiner', patchCombiner, callbacks);
};

export const usePatchRepeater = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('repeater', patchRepeater, callbacks);
};

export const usePatchSplitter = (callbacks: SuccessErrorCallbacks) => {
  return usePatchNode('splitter', patchSplitter, callbacks);
};
