export type ILoading = { status: 'loading'; data: undefined };

export type IError<A> = { status: 'error'; data: A };

export type IOk<A> = { status: 'ok'; data: A };

export type ITaskEither<A, B> = ILoading | IError<A> | IOk<B>;

export const Error = <T>(v: T): IError<T> => {
  return { status: 'error', data: v };
};

export const Loading: ILoading = { status: 'loading', data: undefined };

export const Ok = <T>(v: T): IOk<T> => {
  return { status: 'ok', data: v };
};
