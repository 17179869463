import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_BUNDLE = gql`
  mutation createBundle($input: BundleInput!) {
    createBundle(input: $input)
  }
`;

export const createBundle = (propertyId: number) => (bundleDetails: any) => {
  return requestGQL(CREATE_BUNDLE, { input: { propertyId, bundleDetails } });
};
