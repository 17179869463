import { zodResolver } from '@hookform/resolvers/zod';
import { O } from '@mobily/ts-belt';
import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import { useErrorMessage } from 'app/ui/validationError/useErrorMessage';
import { useTranslation } from 'common/hooks/useTranslation';
import { Lambda } from 'common/types';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDeleteBaseStation } from '../../hooks/useDelete';
import { usePatchBaseStation } from '../../hooks/usePatch';
import { BaseStationDetails } from '../../types/ObjectDetails';
import { baseStationSchema, BaseStationSchema } from '../../types/BaseStationSchema';
import { PhotosField } from '../../ui/PhotosField';
import { EnlargedPhotoDialog } from '../EnlargedPhotoDialog';

type Props = {
  details: BaseStationDetails;
  onSave: Lambda<void, void>;
  onDelete?: Lambda<void, void>;
};

export const BaseStation: FC<Props> = ({ details, onSave, onDelete }) => {
  const { t } = useTranslation();
  const { location, text, id, photosURLs, operator, voltage, other, portCount, unitType } = details;
  const [pickedImage, setPickedImage] = useState<O.Option<string>>(O.None);
  const { mutate: patchBaseStation, isLoading: isLoadingPatchBaseStation } = usePatchBaseStation({
    onSuccess: () => {
      onSave();
      toast.success(t('general.updatedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });
  const { mutate: deleteBaseStation, isLoading: isLoadingDeleteBaseStation } = useDeleteBaseStation({
    onSuccess: () => {
      if (onDelete) onDelete();
      toast.success(t('general.deletedSuccessfully'));
    },
    onError: () => {
      toast.error(t('error.generalMessage'));
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<any>({
    defaultValues: {
      text: O.getWithDefault(text, ''),
      portCount: O.getWithDefault(portCount, 0),
      unitType: O.getWithDefault(unitType, ''),
      location: O.getWithDefault(location, ''),
      operator: O.getWithDefault(operator, ''),
      voltage: O.getWithDefault(voltage, 0),
      other: O.getWithDefault(other, ''),
    },
    mode: 'onChange',
    resolver: zodResolver(baseStationSchema),
  });

  const [photosUrls, setPhotosUrls] = useState(photosURLs ?? []);

  const getErrorMessage = useErrorMessage(errors);

  const handleSave = (values: BaseStationSchema) => {
    patchBaseStation({ ...values, id, photosURLs: photosUrls });
  };

  const handleDelete = () => {
    deleteBaseStation(details.id);
  };

  return (
    <>
      <EnlargedPhotoDialog
        photoUrl={pickedImage}
        onClose={() => {
          setPickedImage(O.None);
        }}
      />
      <Stack component="form" onSubmit={handleSubmit(handleSave)}>
        <Typography sx={{ fontSize: 18, paddingBottom: 2 }}>{t('areas.baseStation.label')}</Typography>
        <Stack gap={2} width="100%">
          <TextField label={t('areas.fields.name')} {...register('text')} />
          <TextField
            label={t('areas.fields.portCount')}
            type="number"
            {...register('portCount', {
              valueAsNumber: true,
            })}
            helperText={getErrorMessage('portCount')}
          />
          <PhotosField
            value={photosUrls}
            onChange={setPhotosUrls}
            onItemClick={(photoUrl) => {
              setPickedImage(O.Some(photoUrl));
            }}
          />
          <TextField label={t('areas.fields.location')} {...register('location')} />
          <TextField label={t('areas.fields.unitType')} {...register('unitType')} />
          <TextField label={t('areas.fields.operator')} {...register('operator')} />
          <TextField
            label={t('areas.fields.voltage')}
            type="number"
            {...register('voltage', {
              valueAsNumber: true,
            })}
          />
          <TextField label={t('areas.fields.other')} {...register('other')} />
        </Stack>
        <Grid container justifyContent="space-between" mt={2}>
          <Button variant="text" color="error" onClick={handleDelete} disabled={isLoadingDeleteBaseStation}>
            {isLoadingDeleteBaseStation ? <CircularProgress size={16} /> : t('general.delete')}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={!isValid || isLoadingPatchBaseStation}>
            {isLoadingPatchBaseStation ? <CircularProgress size={16} /> : t('general.save')}
          </Button>
        </Grid>
      </Stack>
    </>
  );
};
