import { Stack } from '@mui/material';
import { FC, useState } from 'react';

import { O } from '@mobily/ts-belt';
import { AreasRadioPreview } from './components/AreasRadioPreview';
import { AreasPreview } from './components/AreasPreview';
import { CreateObject } from './components/CreateObject/CreateObject';
import { ObjectDetailsDialog } from './components/ObjectDetailsDialog';

export const Areas: FC<{ isRadio?: boolean }> = ({ isRadio = false }) => {
  const [selectedNodeId, setSelectedNodeId] = useState<O.Option<string>>(O.None);

  const closeDetailsDialog = () => {
    setSelectedNodeId(O.None);
  };

  return (
    <>
      <ObjectDetailsDialog isRadio={isRadio} selectedNodeId={selectedNodeId} onClose={closeDetailsDialog} />
      <Stack gap={0.5} width="100%" mt={2}>
        <CreateObject isRadio={isRadio} />
        {isRadio ? (
          <AreasRadioPreview onItemClick={({ id }) => setSelectedNodeId(O.Some(id))} />
        ) : (
          <AreasPreview onItemClick={({ id }) => setSelectedNodeId(O.Some(id))} />
        )}
      </Stack>
    </>
  );
};
