import { requestGQL } from 'common/graphQlClient';
import { useMutation, useQueryClient } from 'react-query';

import { Option } from '@mobily/ts-belt';
import { CREATE_TWISTER_PAIR, MutateCreateTwistedPair } from '../outlets/api';
import { usePropertyContext } from '../properties/components/SingleProperty';

export const useCreateTwistedPair = (id?: Option<number>) => {
  const queryClient = useQueryClient();
  const { id: contextPropertyId } = usePropertyContext();

  const propertyId = id || contextPropertyId;

  const { data, mutate, isLoading, isError, isSuccess } = useMutation<string, unknown, MutateCreateTwistedPair>(
    async (fiber) =>
      requestGQL(CREATE_TWISTER_PAIR, {
        propertyId,
        twistedPair: fiber,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['outletsByPropertyId', propertyId]);
        queryClient.invalidateQueries(['propertyConnection', propertyId]);
        queryClient.invalidateQueries(['allPremises', propertyId]);
      },
    },
  );

  return { data, mutate, isLoading, isError, isSuccess };
};
