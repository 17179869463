import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const CREATE_TECHNICAL_SWITCH = gql`
  mutation createTechnicalSwitch($input: TechnicalSwitchInput!) {
    createTechnicalSwitch(input: $input)
  }
`;

export const createTechnicalSwitch = (propertyId: number) => (switchDetails: any) => {
  return requestGQL(CREATE_TECHNICAL_SWITCH, { input: { propertyId, switchDetails } });
};
