import { gql } from 'graphql-request';
import { requestGQL } from 'common/graphQlClient';

export const PATCH_DROP_ANTENNA = gql`
  mutation patchDropAntenna($input: DropAntennaInput!) {
    patchDropAntenna(input: $input)
  }
`;

export const patchDropAntenna = (propertyId: number) => (dropAntennaDetails: any) => {
  return requestGQL(PATCH_DROP_ANTENNA, { input: { propertyId, dropAntennaDetails } });
};
