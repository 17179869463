import { requestGQL } from 'common/graphQlClient';
import { gql } from 'graphql-request';

export const DELETE_CROSS_CONNECTION_PANEL = gql`
  mutation deleteCrossConnectionPanel($input: DeleteNodeInput!) {
    deleteCrossConnectionPanel(input: $input)
  }
`;

export const deleteCrossConnectionPanel = (propertyId: number) => (nodeId: string) => {
  return requestGQL(DELETE_CROSS_CONNECTION_PANEL, { input: { propertyId, nodeId } });
};
