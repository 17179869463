import { createStylesMap } from 'app/theme/materialTheme';

export const styles = createStylesMap({
  dialog: {
    width: '100%',
  },
  dialogTopbar: {
    justifyContent: 'end',
  },
  dialogContent: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    p: 1.5,
    flexDirection: 'column',
  },
  dialogActionButtons: {
    mt: 2,
    justifyContent: 'space-evenly',
    flexDirection: { xs: 'column', sm: 'row' },
    gap: { xs: 1, sm: 2 },
  },
});
