import { requestGQL } from 'common/graphQlClient';
import { useMutation, useQueryClient } from 'react-query';

import { CREATE_OUTLET } from '../api';

export const useCreateOutlet = (propertyId: null | number, premiseId: null | number) => {
  const queryClient = useQueryClient();

  return useMutation<string, { message: string }>(
    async () =>
      requestGQL(CREATE_OUTLET, {
        propertyId,
        premiseId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['outletsByPropertyId', propertyId]);
        queryClient.invalidateQueries(['propertyConnection', propertyId]);
      },
    },
  );
};
