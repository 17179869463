import en from './lang/en.json';
import sv from './lang/sv.json';
import { Language } from './Language';

const mergeWithBackup = (lang: Record<string, string>, backup: Record<string, string>) => ({ ...backup, ...lang });

export const mapLang: Record<Language, Record<string, string>> = {
  en,
  sv: mergeWithBackup(sv, en),
};
