import { prependWithSpaceIfNonEmpty } from 'common/helpers';
import FuzzySearch from 'fuzzy-search';

import { A, D, G, O, pipe } from '@mobily/ts-belt';

import {
  GetPropertiesConnectionsResponse,
  GetPropertiesResponse,
  GetPropertyFiberChecklistResponse,
  GetPropertyResponse,
  SinglePropertyGetProperiesResponse,
} from './api';
import { mergePropertiesDataWithConnections } from './PropertyConnections';
import { BasicPropertyOnPropertiesList, ExtendedPropertyOnPropertyList } from './types';

// TODO: use lenses
const mapSinglePropertyResponse = (
  responseProperty: SinglePropertyGetProperiesResponse,
): Readonly<BasicPropertyOnPropertiesList> => {
  return {
    id: responseProperty.id,
    city: responseProperty.city ?? '',
    description: responseProperty.description ?? '',
    owner: responseProperty.companyByCompany?.name ?? '',
    address: responseProperty.address ?? '',
    amountOfPremises: responseProperty.premisesByPropertyId.totalCount,
  };
};

export const parseGetPropertiesResponse = (
  response: GetPropertiesResponse,
  connectionsResponse: undefined | GetPropertiesConnectionsResponse,
): null | ReadonlyArray<ExtendedPropertyOnPropertyList> | ReadonlyArray<BasicPropertyOnPropertiesList> => {
  return pipe(
    response,
    D.get('allProperties'),
    O.flatMap((a) => pipe(a, D.get('nodes'), O.map(A.map(mapSinglePropertyResponse)))),
    O.flatMap((properties) =>
      G.isNullable(connectionsResponse)
        ? properties
        : mergePropertiesDataWithConnections(properties, connectionsResponse),
    ),
    O.toNullable,
  );
};

export type PropertyOnSinglePropertyInformations = BasicPropertyOnPropertiesList & {
  contacts: {
    property: {
      name: null | string;
      email: null | string;
      phone: null | string;
      role: null | string;
      hideInInstaller: null | boolean;
    }[];
    CF: {
      name: null | string;
      email: null | string;
      phone: null | string;
      role: null | string;
    };
  };
  attachments: ReadonlyArray<string>;
  fiberChecklistByPropertyId: GetPropertyFiberChecklistResponse['fiberChecklistByPropertyId'];
};

export const parseGetPropertyResponse = (
  response: GetPropertyResponse,
): null | Readonly<PropertyOnSinglePropertyInformations> => {
  return pipe(
    response,
    D.get('propertyById'),
    O.flatMap((responseProperty) => {
      const { propertyContactPerson } = responseProperty;

      const CFContactPerson = responseProperty.propertyPeopleByPropertyId.nodes.find(
        (person) => person?.role === 'PROPERTY_ROLE_SALES',
      )?.personByPersonId;

      return {
        id: responseProperty.id,
        city: responseProperty.city,
        description: responseProperty.description,
        address: responseProperty.address,
        owner: responseProperty.companyByCompany?.name,
        amountOfPremises: responseProperty.premisesByPropertyId.totalCount,
        contacts: {
          property: G.isArray(propertyContactPerson)
            ? propertyContactPerson.map((person) => ({
                name: person.name ?? null,
                email: person.email ?? null,
                phone: person.phone ?? null,
                role: person.role ?? null,
                hideInInstaller: person.hideInInstaller ?? null,
              }))
            : [
                {
                  name: propertyContactPerson?.name ?? null,
                  email: propertyContactPerson?.email ?? null,
                  phone: propertyContactPerson?.phone ?? null,
                  role: propertyContactPerson?.role ?? null,
                  hideInInstaller: propertyContactPerson?.hideInInstaller ?? null,
                },
              ],
          CF: {
            name: CFContactPerson?.displayName ?? null,
            email: CFContactPerson?.email ?? null,
            phone: CFContactPerson?.mobilePhone ?? null,
            role: CFContactPerson?.role ?? null,
          },
        },
        attachments: responseProperty.propertyAttachmentsByPropertyId.nodes
          .filter(G.isNotNullable)
          .map((node) => node.filename),
        fiberChecklistByPropertyId: responseProperty.fiberChecklistByPropertyId,
      };
    }),
    O.toNullable,
  );
};

export const searchProperties =
  (query: string) =>
  (properties: ReadonlyArray<ExtendedPropertyOnPropertyList> | ReadonlyArray<BasicPropertyOnPropertiesList>) => {
    return new FuzzySearch(properties as ExtendedPropertyOnPropertyList[], [
      'description',
      'address',
      'owner',
      'city',
    ]).search(query); // as Property[] because FuzzySearch has mutable type
  };

export const getFullAddress = (property: BasicPropertyOnPropertiesList) => {
  return `${property.address}${prependWithSpaceIfNonEmpty(property.city)}`;
};
