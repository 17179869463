import { Outlet } from 'app/outlets/Outlet';
import { PremiseOnSinglePremise } from 'app/premises/Premise';
import { useTranslation } from 'common/hooks/useTranslation';
import { FC } from 'react';
import { useBoolean } from 'usehooks-ts';
import { Button } from '@mui/material';
import { TPOutlet } from 'app/outlets/TPOutlet';
import { AvailableOutletTypes } from 'app/outlets/AvailiableOutletTypes';
import { AddFiberDialog } from './components/AddFiberDialog';
import { AddFiberForm } from './components/AddFiberForm';
import { AddFiberTPOutletForm } from './components/AddFiberTPOutletForm';
import { OutletTypes } from '../../outlets/components/OutletMenu/OutletMenu';
import { TpOutletDetails } from '../../areas/types/ObjectDetails';

export const AddFiber: FC<{
  outlet: Outlet | TPOutlet | TpOutletDetails;
  premise: PremiseOnSinglePremise;
  outletType: OutletTypes;
}> = ({ premise, outlet, outletType }) => {
  const { t } = useTranslation();

  const { value: isAddFiberOpen, setValue: setIsAddFiberOpen } = useBoolean(false);

  return (
    <>
      <AddFiberDialog isOpen={isAddFiberOpen} onClose={() => setIsAddFiberOpen(false)} outletType={outletType}>
        {outletType === AvailableOutletTypes.OUTLET ? (
          <AddFiberForm premise={premise} outlet={outlet as Outlet} onSuccess={() => setIsAddFiberOpen(false)} />
        ) : (
          <AddFiberTPOutletForm
            premise={premise}
            outlet={outlet as TpOutletDetails}
            onSuccess={() => setIsAddFiberOpen(false)}
          />
        )}
      </AddFiberDialog>
      <Button variant="contained" onClick={() => setIsAddFiberOpen(true)} data-testid="add-fiber">
        {outletType === AvailableOutletTypes.OUTLET ? t('fiber.add') : t('tp.add')}
      </Button>
    </>
  );
};
