import { requestGQL } from 'common/graphQlClient';
import { useMutation, useQueryClient } from 'react-query';

import { CREATE_FIBER, MutateCreateFiberFiber } from '../outlets/api';

export const useCreateFiber = (propertyId: null | number) => {
  const queryClient = useQueryClient();

  const { data, mutate, isLoading, isError, isSuccess } = useMutation<string, unknown, MutateCreateFiberFiber>(
    async (fiber) =>
      requestGQL(CREATE_FIBER, {
        propertyId,
        fiber,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['outletsByPropertyId', propertyId]);
        queryClient.invalidateQueries(['propertyConnection', propertyId]);
        queryClient.invalidateQueries(['allPremises', propertyId]);
      },
    },
  );

  return { data, mutate, isLoading, isError, isSuccess };
};
