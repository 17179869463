import { Styles } from '../../theme/materialTheme';

export const container: Styles = { flexDirection: 'column', alignItems: 'center' };

export const description: Styles = {
  fontSize: 15,
  fontWeight: 400,
  lineHeight: 1.4,
  alignSelf: 'start',
};
export const changeLoginMethodLink: Styles = {
  mt: 5,
  alignSelf: 'start',
};

export const submitActionContainer: Styles = {
  mt: 4,
};
